import React from 'react';

import { ClockInACircle } from '@ven/shared/components/game/molecules/ClockInACircle';

import styled from '@emotion/styled';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';

export const TimerAndScoresHeader:React.FC<any> = ({values,...props}) => 
{
  const countGuessed = values?.guessed || 0
  const countMissed = values?.missed || 0
  const totalTime =  values?.turnTime;
  return (
    <Container {...props}>
      <WordCount value={ countGuessed } color="#2FB586" style={{ left: `20%` }} text={ "Words\nGuessed" } />
      <WordCount value={ countMissed }  color="#FC3B68" style={{ left: `80%` }} text={ "Words\nMissed" } />
      {/* <TimerCircleProgress time={ values?.time || 0 } /> */}
      <TimerCircleProgress time={values?.time || 0} totalTime={totalTime} radius={55} displayTime={true} stroke={8} interpolate={true} />
         
    </Container>
  )
}

const Container = styled.div`
  top: 0px;
  left: 50%; 
  width: 90%;
  height: 114px;
  transform: translate(-50%, 50%); 
  position: absolute;

  .circle-clock{
    margin-top: -50px;
  }
`

const WordCount:React.FC<any> = ({ value, color, text, ...props }) => 
{
  return (
    <WordCountWrapper {...props} >
      <WordCountLabel >
        { text }
      </WordCountLabel>
      <WordCountNumber style={{ color }}>
        { value }
      </WordCountNumber>
    </WordCountWrapper>
  )
}

const WordCountWrapper = styled.div`
  top: 0px;
  transform: translate(-50%, -50%); 
  position: absolute;
`

const WordCountLabel = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  width: 256px;

  text-align: center;
  white-space: pre;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #FFFFFF;
`

const WordCountNumber = styled.div`
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 47px;

  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #FFFFFF;
`
