import React from 'react';

import type { TabooCardData } from '../../gameplay/GameStateController';

import styled from '@emotion/styled';

export const TabooCard:React.FC<{ card:TabooCardData }&any> = ({ card, ...props }) => 
{
  return (
    <Card {...props} className={ `taboo-card ` + ( !! card.word ? 'full' : 'empty' ) }>
      <Header> { card.word }</Header>
      <TabooList>
        {
          ( card.taboo || [] ).map( (tw,i) => <p key={ i }> { tw } </p> )
        }
      </TabooList>
    </Card>
  )
}

const Card = styled.div`
  width: 384px;
  height: 441px;
  background: white;
  border-radius: 12px;
  overflow: hidden;
  
  font-size: 30px;
  line-height: 55px;
  
  &.full {
    /* animation: slide-left-sprite 200ms ease-out; */
    /* animation: fade-in 200ms ease-out; */
  }
  &.empty {
    /* animation: fade-in 300ms; */
    /* opacity: 0; */
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  width: 100%;
  height: 93px;
  background: #001db0c9;
  text-transform: uppercase;
  font-family: Oswald;
  font-size: 40px;
  line-height: 93px;
  text-align: center;
  color: #FFFFFF;
`

const TabooList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1 1;

  p {
    text-transform: uppercase;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: inherit;
    line-height: inherit;
    text-align: center;
    color: #303744;
    margin: 0;
    padding: 0;
  }
`
