import React, { FormEvent, useState } from 'react';
import ChooseCard from './ChooseCard';
import { Link, useHistory } from 'react-router-dom';

import corner1 from '../../../assets/images/choose_corner_1.svg';
import wiggleLine from '../../../assets/images/choose_wiggle_line.svg';
import closeWhite from '../../../assets/images/close_white.svg';


import emailIcon from '../../../assets/images/email_icon.svg';
import passwordIcon from '../../../assets/images/password_icon.svg';
import greenCheck from '../../../assets/images/green_check.svg';
import usernameIcon from '../../../assets/images/user_icon.svg';
// import facebookButton from '../../../assets/images/facebook_button.png';
import googleButton from '../../../assets/images/google_button.png';
import line from '../../../assets/images/line.svg';
import { CreateAnonId, SignInWithFacebook, SignInWithGoogle, SignUpWithEmailAndPassword } from '../../../services/AuthenticationService'

import './styles.scss';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { GameFunctionsService } from '@ven/shared/core/services/GameFunctionsService';

const ChooseHowToPlay = ({ chooseCloseCallback, modalView, loginCallback, signupCallback }) => {
    const [view, setView] = useState(modalView);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [userPassword, setPassword] = useState('');
    const [keepInformed, setKeepInformed] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);

    const [isLoading, setLoading] = useState(false);

    const [emailError, setEmailError] = useState(false);
    const [emailTextError, setEmailTextError] = useState('This is not a valid email.');
    const [passwordError, setPasswordError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [somethingChanged, setSomethingChanged] = useState(false);

    const [teamsVerify, setTeamsVerify] = useState(localStorage.getItem('team') === 'true' ? true : false);

    const closeModal = () => {
        chooseCloseCallback()
    }

    const history = useHistory();

    const goToGameNightBundle = () => {
        history.push('/gamenight');
    };

    const goToGameAsAnonUser = async () => {
        setLoading(true);
        // const anonUser = await CreateAnonId();
        // const uid = UserDataService.get('uid') || '';
        // if (anonUser) {
        const gameId = UserDataService.get('choosenGame');
        if (!gameId) {
            return;
            //clicked play games
        }
        // clicked play in a game
        // history.push(`/${gameId}`);
        const uid = UserDataService.get('uid');
        GameFunctionsService.createRoom(gameId, uid!)
            .then((data) => data && history.push(`/play/${data.code}`));
        // } else {
        //     // Something went wront on creating the anon user
        //     closeModal();
        // }
    }

    const signup = async (e: FormEvent, type: string) => {
        e.preventDefault();

        let logged = false;
        if (type === 'g') {
            logged = await googleInBundle();
        } else if (type === 'f') {
            logged = await facebookInBundle();
        } else if (type === 'ep') {
            checkEmailError(email);
            checkPasswordError(userPassword);
            checkUsernameError(userName);

            if (somethingChanged) {
                if (!usernameError && !emailError && !passwordError) {
                    logged = await SignUpWithEmailAndPassword(userName, email, userPassword, keepInformed);
                    if (!logged) {
                        setEmailTextError('Email already registered.');
                        setEmailError(true);
                    }
                }
            }
        }
        if (logged) closeModal();

    }

    const goToGameOrCloseModal = () => {
        // User resgitered, go to next step
        setDisableButtons(false);
        const uid = UserDataService.get('uid') || '';
        const gameId = UserDataService.get('choosenGame');
        if (gameId) {
            // clicked play in a game
            // history.push(`/${gameId}`);
            GameFunctionsService.createRoom(gameId, uid)
                .then((data) => data && history.push(`/play/${data.code}`));
                return;
        } 
        //clicked play games
        closeModal();
    }


    const googleInBundle = async () => {
        setDisableButtons(true);

        const authenticated = await SignInWithGoogle(keepInformed);

        if (authenticated) {
            // User resgitered, go to next step
            setDisableButtons(false);
            goToGameOrCloseModal();
            return true;
        }
        // Error on authenticating user, show error message
        return false;
    }

    const facebookInBundle = async () => {
        setDisableButtons(true);

        const authenticated = await SignInWithFacebook();

        if (authenticated) {
            // User resgitered, go to next step
            setDisableButtons(false);
            goToGameOrCloseModal();
            return true;
        }
        // Error on authenticating user, show error message
        return false;
    }

    const checkUsernameError = (value: string) => {
        setSomethingChanged(true);
        setUserName(value);
        if (value.length === 0 || value === '') {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
    }

    const checkPasswordError = (passwd: string) => {
        setSomethingChanged(true);
        setPassword(passwd);
        const passwordArray = passwd.split('');
        let condition1 = false;
        let condition2 = false;
        let condition3 = false;
        let condition4 = passwd.length > 5;
        for (const password of passwordArray) {
            // Have upper case letter
            if ((password === password.toUpperCase()) &&
                (isNaN(parseInt(password, 10) * 1))) {
                condition1 = true;
            }
            // Have lower case letter
            if ((password === password.toLowerCase()) &&
                (isNaN(parseInt(password, 10) * 1))) {
                condition2 = true;
            }
            // Have one number
            if (!isNaN(parseInt(password, 10) * 1)) {
                condition3 = true;
            }
        }

        if (!condition1 || !condition2 || !condition3 || !condition4) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
    }

    const checkEmailError = (emailValue: string) => {
        setSomethingChanged(true);
        setEmail(emailValue);
        if (!email) {
            setEmailTextError('This is not a valid email.');
            setEmailError(true);
        }

        if (typeof email !== 'undefined') {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                setEmailTextError('This is not a valid email.');
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        }
    }

    return (
        <div className="choose-modal-landing">
            <div className="choose-modal-content">
                <header className="header">
                    <img src={corner1} alt="" />
                    <div></div>
                    <img src={closeWhite} onClick={closeModal} alt="" />
                </header>
                <div className={`first-view ${view === 0 ? '' : 'hidden-state'}`}>
                    <div className="title">
                        <img src={wiggleLine} alt="" />
                        <p>Choose how you play</p>
                    </div>
                    <div className="cards-container">
                        {/* <ChooseCard
                            bannerText="2 - 4 PLAYERS"
                            title="FREE FOREVER"
                            playerText="2 - 4 Players"
                            descriptionMobile="No login Required"
                            descriptionDesktop="Enjoy VEN games with no registration required."
                            showImages="false"
                            buttonText={!isLoading ? "Continue Without Login" : "Redirecting..."}
                            buttonAction={goToGameAsAnonUser}
                            footerText="Free. No account creation required."
                        /> */}
                        <ChooseCard
                            bannerText="UP TO 4 PLAYERS"
                            title={"\"FREE FOREVER\""}
                            playerText="Up to 4 Players"
                            text1="Just create an account to play with up to 6 people."
                            descriptionMobile=" "
                            descriptionDesktop={" "}
                            showImages="false"
                            buttonText="Join for Free"
                            buttonAction={signupCallback}
                            footerText="Free with account creation."
                        />
                        <ChooseCard
                            bannerText="UNLIMITED PLAYERS"
                            title={teamsVerify ? "TEAM BUNDLE" : "GAME NIGHT BUNDLE"}
                            subtitle="2 FOR 1 SPECIAL"
                            playerText="Unlimited Players"
                            text1={teamsVerify ? "2 for 1 - give a Team Bundle to a friend." : "2 for 1 - give a Game Night Bundle to a friend."}
                            text2="Unlock all premium extensions and word packs… and more!"
                            descriptionMobile="2 for 1 - give a Game Night Bundle to a friend. Unlock all premium extensions!"
                            showImages="true"
                            buttonText="Buy the Bundle"
                            buttonAction={goToGameNightBundle}
                            footerText="One time payment of $12. Start when you’re ready!"
                        />
                    </div>
                </div>
                <div className={`second-view ${view === 1 ? '' : 'hidden-state'}`}>
                    <div className="card-title">
                        <p className="banner">UP TO 4 PLAYERS</p>
                    </div>
                    <div className="freebie-text">
                        <p>FREE FOREVER</p>
                        <p>
                        Just create an account to play with up to 4 people
                        </p>
                    </div>
                    <form>
                        <div className={`input-box ${usernameError ? 'error-input' : ''}`}>
                            <img className="input-icon" src={usernameIcon} alt="" />
                            <input
                                maxLength={30}
                                type="text"
                                name="username"
                                placeholder="Username"
                                id="username"
                                autoComplete="off"
                                onBlur={(e) => checkUsernameError(e.target.value)}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                            <div></div>
                        </div>
                        {usernameError &&
                            <p className="error">You must have a username.</p>
                        }
                        <div className={`input-box ${emailError ? 'error-input' : ''}`}>
                            <img className="input-icon" src={emailIcon} alt="" />
                            <input
                                type="email"
                                name="userEmail"
                                placeholder="E-mail"
                                id="userEmail"
                                autoComplete="off"
                                onBlur={(e) => checkEmailError(e.target.value)}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div></div>
                        </div>
                        {emailError &&
                            <p className="error">{emailTextError}</p>
                        }
                        <div className={`input-box ${passwordError ? 'error-input' : ''}`}>
                            <img className="input-icon" src={passwordIcon} alt="" />
                            <input
                                type="password"
                                name="userPassword"
                                placeholder="Password"
                                id="userPassword"
                                autoComplete="off"
                                onBlur={(e) => checkPasswordError(e.target.value)}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <div></div>
                        </div>
                        {passwordError &&
                            <p className="error">Password needs to be at least 6 characters in length, must include at least one lower case letter, one upper case letter and one number.</p>
                        }
                        <div className="check-box">
                            <div>
                                <input
                                    type="checkbox"
                                    name="sendUpdates"
                                    id="sendUpdates"
                                    onChange={(e) => setKeepInformed(Boolean(e.target.value))}
                                />
                                <div className="one-space">{' '}</div>
                            </div>
                            <p>Send me updates when you have new games and content!</p>
                        </div>
                    </form>
                    <div className="submit-container">
                        <button
                            onClick={(e) => signup(e, 'ep')}
                            disabled={disableButtons}
                        >
                            Continue
                        </button>
                    </div>
                    <div className="or-container">
                        <div>
                            <img className="line" src={line} alt="" />
                        </div>
                        <div>
                            <p className="or-text">OR</p>
                        </div>
                        <div>
                            <img className="line" src={line} alt="" />
                        </div>
                    </div>
                    <div className="button-container">
                        {/* <button onClick={(e) => signup(e, 'f')} ><img src={facebookButton} alt="Continue with Facebook" /></button> */}
                        <button onClick={(e) => signup(e, 'g')}><img src={googleButton} alt="Sign in with Google" /></button>
                    </div>
                </div>
                <footer>
                    <p>
                        Already have an account?{" "}
                        <button className="link-text" onClick={() => loginCallback()}>Log In</button>
                    </p>
                </footer>
                <div>
                </div>
            </div>
        </div>
    )
};

export default ChooseHowToPlay;
