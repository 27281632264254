import type { GameSessionData, GameStateController, TabooCardData } from './GameStateController';
import { GamePseudoServer as GamePseudoServerBase, GameServerAction } from '@ven/shared/core/gameplay/GamePseudoServer';

import { delay } from '@ven/shared/core/utils/promise';
import { getRandomFrom } from '@ven/shared/core/utils/arrays';
import firebase from '@ven/shared/core/data/firebase';
import { analyticsService } from '@ven/platform/main/services/AnalyticsService';

// import cardsDataRaw from "@ven/core/data/taboo.json"

// const allCardsData = [ ...cardsDataRaw["default"], ...cardsDataRaw["bonus"] ]
const allCardsData = new Array;

////TODO: unused for now
type Action = GameServerAction & (
  | {
    type : "callFoul",
    forTurnNumber: number
  }
  | {
    type : "winCard",
    forTurnNumber: number
  }
  | {
    type : "passCard",
    forTurnNumber: number
  }
)

/**
 * The intention is for this class to eventually become the de-facto server entity, 
 * which will handle all the common gameplay logic and only take requests from other
 * players in the form of 'actions', then resolve them in the correct sequence.
 */
export class GamePseudoServer extends GamePseudoServerBase<Action,GameStateController,GameStateController['data']>
{
  protected async tick() {
    if ( this.data?.state?.over ) return;
    if ( !this.data?.state?.started ) return;
    // if ( !this.data?.state?.turn?.started ) return;
    if ( !this.game.amHost() ) return;
    if ( this.game.amHost() && this.game.amSpectator() ) return;

    const turn = this.data.state.turn;

    console.log( turn?.timeout)
    if(!this.data?.state?.turn?.started) {
      const currentPlayerOnline = this.data?.presences?.[ this.data.state.turn.player! ]?.online;
      const timeout = ( turn?.timeout ?? 0 ) - (currentPlayerOnline ? 1 : 5);
      if(timeout > 0){
        await this.update.turn({ timeout })
        return;
      }

      console.log("skipped");
      await this.endTurn( turn.number, true );
      return;
    }

    const time = ( turn?.time ?? 0 ) - 1 // // ?? because 0 is falsy too

    await this.update.turn({ time })

    if ( time <= 0 ) 
    {
      await this.endTurn( turn.number )
    }
  }

  private async endTurn( turnNumber:number, skip = false )
  {
    try {
      if ( this.data?.state?.turn?.number != turnNumber ) {
        throw new Error(`Tried to end turn #${ turnNumber } but current turn is #${ turnNumber }` );
      }

      this.log.trace("Ending Turn N" + turnNumber)
  
      await this.ref.state().child("prevTurnResults").update({
        player: this.data.state.turn.player,
        team: this.data.players[this.data.state.turn.player!].team,
        score: ( this.data.state.turn.guessed || 0 ) - ( this.data.state.turn.missed || 0 ),
        guessed: this.data.state.turn.guessed,
        missed: this.data.state.turn.missed,
        skip,
        time: 0,
      })
      await delay( 1.000 )
  
      let roundNumber = this.data.state?.round?.number ?? 0
      let playersWaitingForTurn = this.data.state?.round?.playersWaitingForTurn ?? []
  
      if ( roundNumber < this.game.getConfig().rounds || playersWaitingForTurn.length > 0 )
      {
        await this.giveNextTurn()
      }
      else
      {
        await this.end()
      }
    } catch ( e ) {
      this.log.error( e );
    } 
  }

  async giveNextTurn()
  {
    try {
      const state = this.data.state as Readonly<GameSessionData['state']>
  
      let playersWaitingForTurn = state.round?.playersWaitingForTurn ?? []
      let roundNumber = state.round?.number ?? 0
  
      //// If there are no players waiting for turn, increment the round and refill queue
      if ( ! playersWaitingForTurn.length )
      {
        roundNumber++
  
        const teamPlayers = {} as Record<string,string[]>
  
        for ( const [ id, data ] of Object.entries( this.data.players ) )
        {
          if ( ! teamPlayers[ data.team ] )
          {
            teamPlayers[ data.team ] = [ id ]
          }
          else
          {
            teamPlayers[ data.team ].push( id )
          }
        }
  
        const teamSize = Math.max( ...Object.values( teamPlayers ).map( a => a.length ) )
  
        //// Make the teams turn an equal number, by adding more turns of available players
        for ( const list of Object.values( teamPlayers ) )
        {
          while ( list.length < teamSize )
          {
            list.push( getRandomFrom( list ) )
          }
        }
  
        for ( let i = 0 ; i < teamSize ; i++ )
        {
          for ( const list of Object.values( teamPlayers ) )
          {
            playersWaitingForTurn.push( list.shift()! )
          }
        }
      }

      //// Pop the next turn player from the waiting queue
      const turnPlayer = playersWaitingForTurn.shift()!

      await Promise.all([
        //// Update round data
        this.update.round({ playersWaitingForTurn, number : roundNumber }),
        //// Reset turn data
        this.update.turn({
          started : false,
          player : turnPlayer,
          team : this.data.players[ turnPlayer ].team,
          time : this.game.getConfig()?.turnTime ?? 60,
          timeout: this.game.getConfig()?.timeout,
          guessed : 0,
          missed : 0,
          fouls : [],
          number : ( this.data.state?.turn?.number || 0 ) + 1,
          // chat : {},
        }) 
      ])

      this.log.trace("Game Turn N" + (( this.data.state?.turn?.number || 0 ) + 1) )
    } catch ( e ) {
      this.log.error( e );
    } 
    
    await this.drawNextCard()
  }

  async drawNextCard()
  {
    try {
      const forbiddenWords = this.data.state.forbiddenWords || []
      const getRandomCard = async () =>
      {
        if(!allCardsData.length) {
          const docData = await firebase
          .firestore()
          .collection("packs")
          .doc(this.game.getConfig().pack)
          .get()
          const words = (docData.data() as any).words;
          words.map(w => {
            const key = Object.keys(w)[0];
            allCardsData.push({ word: Object.keys(w)[0], taboo: w[key] })
          })
        }
       
        const cards = allCardsData.filter(card => !forbiddenWords.includes(card.word))
        if (cards.length > 10) {
          return getRandomFrom( cards ) as TabooCardData
        }

        forbiddenWords.length = 0;
        this.log.error(`Forbidden words list was reset!!`);
        console.warn(`Forbidden words list was reset!!`)
        return getRandomFrom( allCardsData ) as TabooCardData
      }
  
      const card = await getRandomCard()
      this.log.trace(`card word: ${card.word}`);
      await Promise.all([
        this.update.state({ forbiddenWords : [ ...forbiddenWords, card.word ] }),
        this.update.turn({ card })
      ])
    } catch ( e ) {
      this.log.error( e );
    }
  }
  
  async end()
  {
    await Promise.all([
      this.update.turn({ started : false, }), ////TODO test without this
      this.update.state({ over : true, }),
    ])
    
    analyticsService.GameCompleted(this.game.gameId, this.game.roomId!);
    this.log.trace("Game Ended")
  }

  //// //// /// // /

  ////TODO: unused for now
  async handleAction( action: Action )
  {
    try {
      ////TODO: use and resolve "callFoul" action
    } catch ( e ) {
      this.log.error( e );
    }
  }
}
