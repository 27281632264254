import { loadStripe } from '@stripe/stripe-js';
import { analyticsService } from './AnalyticsService';
import { LogService } from './log/LogService';

const stripePromise = loadStripe(process.env.STRIPE_KEY || '');

/**
 * Function to provide payments
 * @param code 
 * @param type 'gnb' || 'team' || 'pack'
 * @param price 
 */
export const GetSessionAndRedirectToCheckout = async (code: string | boolean, type: string, price: number, productId?:string, name?:string) => {
    try {
        const url = process.env.FUNCTION_URL;
        // const url = 'http://localhost:5001/ven-games---staging/us-central1/app'; // ;
        
        const response = await fetch(`${url}/create-session/${code}/${type}/${price}`, {
            method: 'POST',
            body: JSON.stringify({id: productId, name, price}),
            headers: {
                'Accept': 'application/json',
            },
        });
        const session = await response.json();
        // When the customer clicks on the button, redirect them to Checkout.
        const stripe = await stripePromise;
        const result = await stripe!.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.log(result.error.message);
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }

        analyticsService.beginCheckout({
            currency: 'USD',
            items: [{
                item_id: code,
                item_name: name || type,
                item_category: type,
                price,
                currency: 'USD',
                quantity: 1
                // coupon: 'SUMMER_FUN',
                // discount: 2.22,
                // affiliation: 'Google Store',
                // item_brand: 'Gucci',
                // item_variant: 'black',
            }],
            value: price
        });
        return true;
    } catch (err) {
        // Error on Stripe payment, show error message
        console.log(err);
        await LogService.log(err, 'Error: Could not finish Stripe payment.')
        return false;
    }
}

