import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import styled from '@emotion/styled';
import { PlayerAvatar } from '../game/molecules/PlayerAvatar';
import { COLOR_DARK } from '../styling/colors';


type playersModalContext = {
  show: any
  setShow: Function,
}
export const PlayersModalContext = createContext<playersModalContext>({
  setShow: () => { },
  show: () => { }
});

interface PlayersModalProps {
  players?: Array<any>;
  game?: any;
  sort?: Function;
}

const PlayersModal: React.FC<PlayersModalProps> = ({...data}) => {
  const {show, setShow} = useContext(PlayersModalContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const players = 
  // data?.players;
  // players ? players.map(([id,  player]) => ({
  //     id,
  //     name: player.username,
  //     // scoreDelta : game?.data?.state?.prevTurnResults?.scores?.[id] || 0,
  //     ...player,
  //   })) : [];
  
  const players = data?.players ? data.players
    .map(([id,  player]) => ({
      id,
      name: player.username,
      // scoreDelta : game?.data?.state?.prevTurnResults?.scores?.[id] || 0,
      ...player,
    })) : [];
    
    players.sort( (a,b) => b.team - a.team );

  useEffect(() => {

  });

  return (
    <Wrapper>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>Players</Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <PlayerTableContainer>
            <PlayerTable>
              {
                players?.map(p => (
                  <PlayerListItem key={p.id}>
                    <PlayerAvatar size={32} index={p.avatar} transparent={data?.game.presences?.[ p.id ]?.online} />
                    <PlayerName> {p.name} </PlayerName>
                    <PlayerTeam team={p.team}>{data.game.getTeamName(p.team)}</PlayerTeam>
                    {/* <ScoresWrapper>
                      <Score>
                        {p.scoreDelta > 0 && '+'}{p.scoreDelta || 0}
                      </Score>
                      <Score className="total-mobile">
                        {`(${p.score || p.scoreDelta})`}
                      </Score>
                    </ScoresWrapper> */}
                  </PlayerListItem>
                ))
              }
            </PlayerTable>
          </PlayerTableContainer>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
margin-top: 25px;
.nav-item {
  outline: 1px solid rgba(0,0,0,0.1);
  background: #fff;
}
.nav-col {
  /* background: #ffffff83; */
}
.nav-label {
  display: flex;
  align-self: center;
  position: absolute;
  right: 0;
  color: gray;
}
`;

const PlayerTableContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  min-width: 292px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const PlayerTable = styled.div`
  width: 100%;
  min-width: 200px;
  border-spacing: 0 20px; 
  border-collapse:separate;
`

const PlayerListItem = styled.div`
 display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "avatar playerName playerName score";

  align-items: center;
  justify-content: space-between; 
  margin-bottom: 20px;
  width: 100%;
  gap: 5px;
  overflow: hidden;
  `

const PlayerName = styled.span`
  margin-left: 5px;
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: ${COLOR_DARK};
  text-align: left;
  max-width: 180px;
  grid-area: playerName;
`

const PlayerTeam = styled.span<{ team:number }>`
  margin-left: 5px;
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color:  ${ props => props.team == 2 ? "#e87714" : '#7014e8' };
  max-width: 180px;
`

export default PlayersModal;
