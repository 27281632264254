import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import * as ReactSuperSelect from 'react-super-select';
import classNames from 'classnames';
import firebase from 'firebase';

import defaultImage from '@ven/platform/main/assets/images/default-pack.png';
import { PacksService } from '@ven/shared/core/services/PacksService';
import { SyncLoader } from 'react-spinners';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { database } from '@ven/shared/core/data/firebase';
import { CouponService } from '@ven/shared/core/services/CouponService';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';

type Props = {
    gameId: string,
    userId: string,
    onChange?: Function
    initialPackId?: string,
    customClass?: string
}
export const PackDeckDropdown: React.FC<Props> = ({ customClass, gameId, userId, onChange, initialPackId }) => {
    const [packs, setPacks] = React.useState(new Array);
    const [options, setOptions] = React.useState(new Array);
    const [imageList, setImageList] = React.useState(new Object);

    const storageRef = firebase.app().storage().ref();
    const listRef = storageRef.child('pack-images');

    const hasPacks = (packs && packs.length);

    const userData = React.useContext( UserDataContext );
    
    const [watchPack, setWatchPack] = React.useState(false);
    React.useEffect(() => {
        getUserPacks();
    // }, [watchPack]);
    }, []);

    React.useEffect(() => {
        setOptions([...packs].map((pack: Object) => {
            return {
                ...pack
            }
        }))
        requestImages();
    }, [packs]);

    React.useEffect(() => {
        const off = CouponService.watchWordsPack(userId, () => {
            getUserPacks();
        })
        return off;
    }, []);

    async function getUserPacks() {
        const userPacks = await PacksService.getUserPacks(gameId, userId);

        const uid = UserDataService.get('uid');
        // const uid = userData.uid;
        const activeCodeIsValid = await CouponService.validateActiveCode(uid) || userData.isVip;

        if(activeCodeIsValid) {
            userPacks.forEach(pack => (pack as any).price = 0)
            setPacks(userPacks);
            
            return;
        }
        
        const userOwnPacks = await CouponService.getCurrentUserWordsPacks();
       
        userPacks.forEach(pack => {
            if(userOwnPacks.find(coupon => coupon == pack.id))
                (pack as any).price = 0
        })

        setPacks(userPacks);
        setWatchPack(false)
    }

    const _getHighlightedSearchLabel = function (item, search, searchRegex) {
        const labelMarkup = (item.name || item.id).replace(searchRegex, '<span style="background-color: #f90;">' + search + '</span>');

        return (React as any).DOM.span({ dangerouslySetInnerHTML: { __html: labelMarkup } });
    }

    const requestImages = () => {
        listRef.listAll()
            .then((res) => {
                res.items.forEach((itemRef) => {
                    const id = itemRef.name.replace('.png', '');

                    if (!packs.find(pack => pack.id == id)){
                        return;
                    }

                    imageList[id] = "loading";
                    const packImageRef = storageRef.child('pack-images/' + itemRef.name);
                    packImageRef.getDownloadURL().then((url) => {
                        imageList[id] = url;
                        setImageList(imageList)
                        updatePackItems(id);
                    }).catch(function (error) {
                        // images[id] = defaultImage;
                        const loaders = document.querySelectorAll(`#pack-image-${id}-loader`);
                        loaders.forEach(loader => {
                            setTimeout(() => (loader as any).style.display = "none", 500)
                        })
                        console.error(error);
                    });
                });

                // setImageList(images);
            }).catch((error) => {
                console.error(error)
            });
    }

    const updatePackItems = (id?) => {
        const update = (id) => {
            const elements = document.querySelectorAll(`#pack-image-${id}`);
            elements.forEach(element => {
                element ? (element as any).src = imageList[id] : null;
            })
           
            const loaders = document.querySelectorAll(`#pack-image-${id}-loader`);
            loaders.forEach(loader => {
                if(!imageList[id] || imageList[id] && imageList[id] != 'loading') {
                    (loader as any).style.display = "none";
                    return;
                }
                setTimeout(() => (loader as any).style.display = "none", 500)
            })
        }
        if(id) {
            update(id);
            return;
        }
        packs.forEach(pack => {
            update(pack.id);
        })
    }

    const packCartItemTemplate = (item, search) => {
        const itemName = (item.name || item.id);
        const itemClasses = classNames('pack-item',
            itemName.toLowerCase()),
            iconClasses = classNames('pack-image',
                'rss-pack'),
            labelMarkup = search ? _getHighlightedSearchLabel(item, search, new RegExp(search, 'i')) : itemName;
        
            const uid = UserDataService.get('uid');
            const showPrice = item.price && item.owner != uid;
        
            return (
            <div className={itemClasses}>
                <div className="pack-image-container">
                <img id={`pack-image-${item.id}`} className={iconClasses}
                    height={32} width={32} 
                    src={imageList[item.id] && imageList[item.id] != 'loading'
                        ? imageList[item.id] : defaultImage}></img>
                    
                    {/* { !imageList[item.id] || imageList[item.id] != 'loading' ? null : ( */}
                    <div id={`pack-image-${item.id}-loader`} className="loader image-loader">
                    <SyncLoader size={2} color="#303744"></SyncLoader>
                    </div>
                    {/* )} */}
                </div>
                <p className={
                    `${item.description ? "pack-name" : "pack-full-name"} pack-title`
                }>{labelMarkup}</p>
                {item.description ? <p className="pack-description">{item.description}</p> : null}
                {showPrice ? <p id={`pack-price-${item.id}`}className="pack-price">{'$' + item.price.toFixed(2)}</p> : ''}
            </div>);
    };

    const getPack = id => {
        if (!hasPacks)
            return undefined;

        const defaultPack = packs.find(pack => pack.name == 'Starter');
        const result = packs.find(pack => pack.id == id);
        if(result && result.price) {
            return defaultPack;
        }
        return result ?? (defaultPack || packs[0]);
    };

    const _onChange = async option => {
        if(!option.price) {
            if(onChange) onChange(option);
            return;
        }
      
        if(onChange) onChange(option);
    }

    const _onOpenDropdown = async () => {
        updatePackItems();
    }
    // const customFilterExample = function (option, index, collection, searchTerm) {
    //     return (option.name || option.id).toLowerCase().indexOf(searchTerm) > -1;
    // };

    return (
        <Wrapper className="packs-dropdown">
            {/* {JSON.stringify(images)} */}
            <ReactSuperSelect
                customClass={customClass}
                initialValue={getPack(initialPackId)}
                dataSource={options}
                deselectOnSelectedOptionClick={false}
                placeholder={hasPacks ? "Pick a pack" : ""}
                searchPlaceholder="filter pack by name"
                onChange={_onChange}
                onOpenDropdown={_onOpenDropdown}
                customOptionTemplateFunction={packCartItemTemplate}
                clearable={false}
            // customFilterFunction={customFilterExample}
            // searchable={true}
            />
            {
                !hasPacks &&
                (<div className="loader">
                    <SyncLoader size={6} color="#303744"></SyncLoader>
                </div>)
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: grid;
    --pack-item-size: 55px;

    .r-ss-wrap {
        grid-area: 1 / 1;
    }
    .r-ss-dropdown {
        border-radius: 0px 0px 5px 5px;
    }
    .r-ss-dropdown .r-ss-dropdown-option {
        padding-right: 0;
    }
    .r-ss-selected{
        display: none;
    }
    .r-ss-trigger .carat {
        border-color: #999 transparent transparent;
        border-style: solid;
        border-width: 5px 5px 0;
        content: ' ';
        display: block;
        height: 0;
        position: absolute;
        right: 10px;
        top: 14px;
        width: 0;
    }
    .r-ss-trigger .carat.up {
        border-color: transparent transparent #999;
        border-width: 5px 5px;
    }
    .pack-item {
        height: var(--pack-item-size);
        display: grid;
        grid-template-columns: 40px 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        gap: 0px 10px;
        align-items: center;
        justify-items: baseline;
    }
    .pack-image-container { grid-area: 1 / 1 / 5 / 2; display: grid;
        justify-self: center;
        align-self: center;
    }
    .pack-name { grid-area: 1 / 2 / 3 / 5; }
    .pack-full-name { grid-area: 1 / 2 / 5 / 5; }
    .pack-description { grid-area: 3 / 2 / 5 / 5; }
    .pack-price { grid-area: 2 / 4 / 4 / 5; justify-self: flex-end; }
    .loader { 
        width: 100%;
        height: 100%;
        grid-area: 1 / 1;
        background-color: lightgray;
        justify-self: center;
        align-self: center;
        align-content: center;
        opacity: 50%;
        padding-top: 25px;

        &.image-loader {
            padding-top: 0;
        }
     }
     .pack-image{
        grid-area: 1 / 1;
        justify-self: center;
        align-self: center;
     }
    .pack-title {
        font-family: 'Oswald';
        color: #3148BF;
        font-size: 1.8rem;
    }
    .pack-description {
        font-family: 'Bitter';
        color: #303744;
        font-size: 1.5rem;
    }
    .pack-price {
        background: #2FB586;
        color: white;
        border-radius: 10px 0px 0px 10px;
        align-self: center;
        float: right;
        font-family: 'Oswald';
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
    }


    .r-ss-dropdown .r-ss-dropdown-option:focus, .r-ss-dropdown .r-ss-dropdown-option::selection {
        /* outline: 1px solid #000; */
        /* background-color: rgba(0, 136, 204, 0.1); */
        border: 0px;
    }

    .r-ss-trigger {
        height: var(--pack-item-size);
        padding: .25rem 2.5rem .25rem .25rem;

        &.r-ss-placeholder {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .pack-price, .r-ss-selection-clear {
            padding: 0.0rem 0.5rem;
            border-radius: 10px;
        }

        &:focus {
            /* outline: 1px solid black;
            border: 0px; */
        }

    }
`