import React from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';

import Notepad from '../notepad/Notepad';
import { DrawingToolbox } from '../DrawingToolbox';
import { ReadonlyCanvas } from '../canvas/ReadonlyCanvas';
import { DrawingCanvas } from '../canvas/DrawingCanvas';
import { DrawingSettingsProvider } from '../canvas/DrawingSettings';
import type { CanvasAction } from '../canvas/CanvasAction';

import styled from '@emotion/styled';

// @ts-ignore
import CanvasImage from "../assets/canvas.svg";
import throttle from 'lodash.throttle';
import { TurnType } from '../../gameplay/GameStateController';
import { PacmanLoader } from 'react-spinners';
import { configuration } from '../canvas/configuration';

export const CanvasView:React.FC = () => 
{
  const { game, data } = React.useContext( GameStateContext )

  const myCurrentSubject = game.getMyState()?.currentSubject;

  const [ phrase, setPhrase ] = React.useState( '' )
  const [ drawing, setDrawing ] = React.useState<CanvasAction[]>( data?.players[game.myUserId!]?.tempCanvas || [] )

  const [ forcedRefreshes, setForcedRefreshes ] = React.useState(0);
  const forceUpdate = () => setForcedRefreshes(forcedRefreshes+1)

  const roundNumber = game.data?.state.turn?.rotation;
  const roundTotal = game.getPlayersCount() - 1;
  const timeLeft = game.data?.state.turn?.time
  const submittedForThisTurn = game.getMyState()?.submittedForThisTurn;

  const submit = () => {
    game.submit( 
      data?.state.turn.type == TurnType.Guessing
      ? phrase || `${ game.getMyState().username } wrote no description...` 
      : drawing )

    if(data?.state.turn.type == TurnType.Guessing) // to avoid clean user drawing during pacman
      setDrawing([]);
    setPhrase('');
  }

  const handleDrawingUpdate = throttle( (data:CanvasAction[]) => {
    setDrawing(data);
    game.updateTempCanvas(data)
  }, 500 )

  React.useEffect( () => {
    if ( timeLeft! <= 0 && game.getMyState()?.submittedForThisTurn === false ) {
      submit();
    }
  }, [timeLeft] )

  const drawinngToolbar = () => 
    myCurrentSubject?.type === "sentence" 
    ? <DrawingToolbox optionChoices={ configuration.tools } callbacks={{ 
        clear : async () => { 
          drawing.length = 0;
          setDrawing(drawing);
          forceUpdate();

          // await game.ref.me().child('tempCanvas').remove();
          await game.updateTempCanvas(drawing);
        },
        undo : async () => {
          let i = drawing.length - 2;
          while ( i > 0 && drawing[i][0] !== 2 ) {
            i--;
          }
          drawing.length = Math.max( i+1, 0 );
          setDrawing(drawing);
          forceUpdate();
          
          await game.updateTempCanvas(drawing);
        },
      }} />
    : null;

  const clock = () => {
    const text = myCurrentSubject?.type === "sentence" 
    ? "DRAW THIS"
    : "GUESS WHAT THIS IS"
    

    const progress = !submittedForThisTurn ? 
          (<div className="clock-wrapper">
              <TimerCircleProgress time={ timeLeft || '' } />
            </div>)
          : <div className="pacman-wrapper">
              <PacmanLoader
                size={35}
                color={"white"}
                loading={true}
              />
          </div>

  return (
      <div >
        <div className={`top-tip ${submittedForThisTurn ? 'submitted' : null}`}>
         { progress }
          <Spacer size={ 20 } />
          <span>{ text }</span>
        </div>
      </div>
      )
  }

  return ! data ? null : (
    <Wrapper>
      { clock() }
      <Spacer size={ 15 } />
      {
        React.useMemo( () =>
          <>
            <DrawingSettingsProvider>
              <Notepad header={ submittedForThisTurn ? ' ' : myCurrentSubject?.type === "sentence" ? myCurrentSubject.sentence : setPhrase }>
                {
                  myCurrentSubject?.type === "drawing"
                  ? <ReadonlyCanvas 
                      config={{ width : 373 , height: 373 }} 
                      data={ myCurrentSubject.canvas || [] } 
                      fillSpeed={ 4 }
                    />
                  : <DrawingCanvas 
                      data={ drawing || [] }
                      update={ handleDrawingUpdate }
                      config={{ width : 373 , height: 373, minLineLength: 10 }} 
                      readonly={ game.getMyState()?.submittedForThisTurn }
                    />
                }
              </Notepad>
              <Spacer size={ 10 } />
              <div className="top-tip">
                Round {roundNumber}/{roundTotal}
              </div>
              <Spacer size={ 65 } />
              { drawinngToolbar() }
            </DrawingSettingsProvider>
          </>
        , [ myCurrentSubject?.rotation, forcedRefreshes, game.getMyState()?.submittedForThisTurn ]
      )
    }
    </Wrapper>

  )
}

const Wrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;

.top-tip {
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  
  width: 373px;

  span {
    opacity: 1;
    transition: opacity 100ms;
  }

  &.submitted {
    span {
      opacity: 0;
    }
  }
}

.clock-wrapper {
    height: 8.5rem;
    margin-top: 5px;
}

.pacman-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
    height: 72px;
    margin-top: 16px;
}

.drawing-canvas {
  width: 373px !important;
  height: 373px !important;
}
`
