import React from 'react';

import type { GameSessionData } from '../../gameplay/GameStateController';
import { TinyPenIcon, TinyTickIcon } from '../assets/icons';
import { PlayerAvatar } from '../../../../shared/components/game/molecules/PlayerAvatar';

import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { COLOR_DARK, COLOR_GRAY, COLOR_PRIMARY, COLOR_LIGHT as COLOR_LIGHT } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';

type Props = //React.PropsWithChildren<GameSessionData['players'][0]>
{
  id? : string,
  name : string,
  avatar? : string|number,
  score : number,
  placement : number,
  drawing : boolean,
  guessed : boolean,
  transparent?: boolean,
  label?: string
}
export const PlayerCard:React.FC<Props> = data => 
{
  const playerAvatar = data.avatar

  const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    min-height: 66px;
    margin-bottom: 12px;
    border-radius: 12px 0px 0px 12px;
    border: 2px solid white;
    background: ${ COLOR_LIGHT };
    transition: color 400ms;

    &.ready {
      border: 2px solid white;
      background: ${ COLOR_PRIMARY };
      color: ${ COLOR_LIGHT };
    }
  `

  const PlacementBit = styled.div`
    flex: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    text-align: center;

    color: ${ COLOR_DARK };
    
    .ready & {
      color: ${ COLOR_LIGHT };
    }
    
    @media (max-width: ${ MOBILE_BREAK_PX }px) {
      display: none;
    }
  `

  const AvatarBit = styled.div`
    flex: 0 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const MainBit = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `
  const PlayerName = styled.div`
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    padding-right: 35px;
    color: ${ COLOR_DARK };
    
    .ready & {
      color: ${ COLOR_LIGHT };
    }
  `
  const Score = styled.div`
    font-family: Bitter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${ COLOR_GRAY };
    
    .ready & {
      color: ${ COLOR_LIGHT };
    }
  `

  const ScoreLabel = styled.span`
    @media (max-width: ${ MOBILE_BREAK_PX }px) {
      display: none;
    }
  `

  const IconBit = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
  `

  return (
    <Wrapper className={ 'dropping-the-shadow ' + ( data.guessed ? "ready" : "not-ready" ) } >
      <PlacementBit>
        #{ data.placement }
      </PlacementBit>
      <AvatarBit>
        <PlayerAvatar size={34} index={ playerAvatar } transparent={data.transparent} label={data.label} />
      </AvatarBit>
      <MainBit>
        <PlayerName> { data.name } </PlayerName>
        <Score> <ScoreLabel> Score: </ScoreLabel> { data.score } </Score>
      </MainBit>
      <IconBit>
        { data.drawing ? <TinyPenIcon /> : null }
        { data.guessed ? <TinyTickIcon /> : null }
      </IconBit>
    </Wrapper>
  )
}
