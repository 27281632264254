import { MAIN_HEADER_VIRTUAL_HEIGHT_PX } from "@ven/shared/components/styling/breakpoints";

export const Dimensions = {
  mobile : {
    width: 414,
    height: 898,
    insetY : MAIN_HEADER_VIRTUAL_HEIGHT_PX,
  },
  desktop : {
    width: 1012,
    height: 850,
    insetY : MAIN_HEADER_VIRTUAL_HEIGHT_PX,
  },
}
