import type { GameSessionData as GameSessionDataBase, PlayerID } from '@ven/shared/core/gameplay/GameSessionData';
import type { CanvasAction } from '../components/canvas/CanvasAction';
import { GameStateController as GameStateControllerBase } from '@ven/shared/core/gameplay/GameStateController';
import { LoggingFeature, WithLogging } from '@ven/shared/core/gameplay/plugins/Logging';
import { GamePseudoServer } from './GamePseudoServer';

import { delay } from '@ven/shared/core/utils/promise';
import { analyticsService } from '@ven/platform/main/services/AnalyticsService';

////

const SUBMISSION_TIMEOUT = 5;

export class GameStateController extends GameStateControllerBase<GameSessionData & WithLogging>
{
  public readonly log = new LoggingFeature(this);

  public readonly server = new GamePseudoServer(this);

  private startingTurn:boolean = false;

  public getConfig():GameConfigurationData {
    return {
      ...DEFAULT_CONFIG,
      ...this.data.config,
    }
  }

  public setConfig(config) {
    this.ref.config().update(config)
  }

  public canStartGame = () => 
  {
    return ! this.data.state.started &&
      Object.keys( this.data.players || {} ).length >= DEFAULT_CONFIG.minPlayersCount;
  }

  //// GAMEPLAY

  async initialize() {
    super.initialize();
    requestAnimationFrame( () => this.server.initialize() );
  }

  async destroy() {
    this.server.destroy();
    super.destroy();
  }

  async start(userConfig) 
  {
    const config = this.getConfig();
    await super.start({
      phase: GamePhase.FirstSentence,
      turn: {
        started: false,
        rotation: 0,
        type: TurnType.FirstSentence,
      }
    }, { ...config, ...userConfig} )

    this.log.trace("Game Started");

    await this.startTurnTimer( this.getConfig().firstSentenceTime )
  }

  public haveAllPlayersSubmittedForThisTurn() {
    return Object.values( this.data.players ).every( player => player.submittedForThisTurn )
  }

  public getPlayerIdAtRotation(from:PlayerID, rotation:number) {
    const keys = Object.keys( this.data.players );
    const fromIndex = keys.indexOf( from );
    return keys[ ( fromIndex + keys.length - rotation ) % keys.length ]
  }

  public async startTurnTimer( time?:number ) {
    this.log.trace(`startTurnTImer(${ time })`);
    this.update.state({ timeout : SUBMISSION_TIMEOUT })
    await this.update.turn({ started : true, time })
  }

  public async startBreakingTheMessageRotation() {
    const nextRotationNumber = ( this.data.state.turn.rotation || 0 ) + 1;
    await this.update.state({ phase: GamePhase.BreakingTheMessage })
    await this.startNextTurn( nextRotationNumber );
  }

  public async startNextTurn(nextRotationNumber:number) {
    try {
      if ( this.startingTurn ) {
        throw new Error( `Tried starting turn while a turn is already starting` )
      }

      this.startingTurn = true;

      // const prevRotationNumber = this.data.state.turn.rotation || 0
      const prevRotationNumber = (await this.get.turn()).rotation

      if ( nextRotationNumber != prevRotationNumber + 1 ) {
        throw new Error(`Tried to end rotation #${ prevRotationNumber } but current rotation is #${ prevRotationNumber }` );
      }

      this.log.trace(`begun startNextTurn(${ nextRotationNumber })`);

      const turnType = prevRotationNumber % 2 ? TurnType.Guessing : TurnType.Drawing;

      await delay(1.0);

      for ( const uid in this.data.players ) {
        const playerName = this.data.players[uid]?.username;
        if ( ! this.data.players[uid]?.story[prevRotationNumber] ) {
          this.log.error(`Player ${ playerName } has no story entry from prev rotation (${ prevRotationNumber })`)
        }
      }

      function makeBlankSubject(author:PlayerID):Submission {
        return {
          originator: author,
          author: author,
          rotation: prevRotationNumber,
          ...(
            turnType != TurnType.Drawing ? {
              type: "drawing",
              canvas: [],
            } : {
              type: "sentence",
              sentence: '',
            }
          )
        }
      }

      await Promise.all([
        this.update.state({ timeout : SUBMISSION_TIMEOUT }),
        this.update.turn({ 
          rotation : nextRotationNumber,
          started : true,
          time : turnType === TurnType.Drawing
            ? this.getConfig().drawingTime
            : this.getConfig().sentenceTime,
          type : turnType,
        }),
        ...Object.keys( this.data.players )
          .map( p => {
            const prevP = this.getPlayerIdAtRotation( p, nextRotationNumber )
            const currentSubject = this.data.players[prevP]?.story[prevRotationNumber];
            if ( ! currentSubject ) {
              this.log.error(`currentSubject is ${ currentSubject }!! Will use a blank subject!`)
            }
            return this.update.player( p, {
              submittedForThisTurn : false,
              currentSubject : currentSubject || makeBlankSubject(prevP),
            })
          })
      ])

      this.log.trace(`finished startNextTurn(${ nextRotationNumber })`);
    } catch( e ) {
      this.log.error( e );
    } finally {
      this.startingTurn = false;
    }
  }

  async updateTempCanvas( data:CanvasAction[] )
  {
    this.update.me({ tempCanvas : data })
  }

  async submit( canvasOrSentence:string|CanvasData ) {
    this.server.sendAction( 'submit', { canvasOrSentence, forRotation : this.data.state.turn.rotation } )
  }

  async end()
  {
    await this.update.state({ over : true, })
    
    analyticsService.GameCompleted(this.gameId, this.roomId!);
  }
}

export const DEFAULT_CONFIG = {
  drawingTime : 35,
  sentenceTime : 20,
  firstSentenceTime : 45,
  minPlayersCount: 4,
  maxPlayersCount: 16
}
export type GameConfigurationData = typeof DEFAULT_CONFIG;

export enum GamePhase {
  FirstSentence = 1,
  BreakingTheMessage = 2,
  FinalAnimation = 3,
}

export enum TurnType {
  FirstSentence = "first",
  Drawing = "drawing",
  Guessing = "guessing",
}

export type CanvasData = CanvasAction[]

export type PlayerData = {
  firstSentence:string
  story:Record<number,Submission>
  currentSubject:Submission
  submittedForThisTurn:boolean
  tempCanvas:CanvasData
} & GameSessionDataBase['players'][0]

export type Submission_Drawing = {
  /** Author of the first sentence of the chain */ 
  originator: PlayerID 
  author: PlayerID
  rotation: number
  type: "drawing"
  canvas:CanvasData
}
export type Submission_Sentence = {
  /** Author of the first sentence of the chain */ 
  originator: PlayerID
  author: PlayerID
  rotation: number
  type: "sentence"
  sentence: string
}
export type Submission = Submission_Drawing | Submission_Sentence;

type GameStateTurnData = {
  started : boolean
  time? : number
  rotation: number
  type : TurnType
}
type GameStateData = {
  started : boolean
  over : boolean
  turn : GameStateTurnData

  delay : number
  timeout : number

  phase : GamePhase
  
  /** Unused, only here to not break GameSessionData inheritance */
  round: any 
}
export type GameSessionData = GameSessionDataBase<GameStateTurnData> &
{
  players : Record<PlayerID, PlayerData>
  config : GameConfigurationData
  state : GameStateData
  host? : PlayerID
}
