import React, { useState } from 'react';

import styled from '@emotion/styled';
import { Spacer } from '@ven/shared/components/common/atoms/Separator';
import { VolumeOnIcon, VolumeOffIcon, DrawToolIcon } from '../../assets/icons';
import { CircularButton } from './CircularButton';
import { audioService } from '@ven/core/services/SoundService';
import { EventHostPowers } from '../../game/molecules/EventHostPowers';
import type { GameStateController } from '@ven/shared/core/gameplay/GameStateController';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';

type Props = {
  game?: GameStateController<any>,
  gameName?: string,
  callbacks: {
    showHowToPlay : any
  }
}
export const BottomRibbon:React.FC<Props> = ({ gameName, game, callbacks }) => 
{
  ////TODO refactor this to use useEffect() and events out of the audioService
  //// so that it reacts to changes, not triggered inside this component
  //// (in case there is ever added a way to do that in the future)
  const [ muted, setMuted ] = React.useState(audioService.muted);
  const [showEventHostPowers, setEventHostPowers] = useState(false);
  const myUserData = React.useContext( UserDataContext );

  
  const showGameName = () => 
    !gameName ? null :
    <div className="title">
      { gameName }
    </div>

  return (
    <Ribbon className="ribbon">

      <EventHostPowers game={game} show={showEventHostPowers} setShow={setEventHostPowers}></EventHostPowers>
   
      { showGameName() }

      <div className="filler"/>

      { !!callbacks.showHowToPlay && <div className="how-to" onClick={ callbacks.showHowToPlay }>
        How to Play
      </div>
      }

      <Spacer size={ 20 } />

      {/* <CircularButton onClick={ () => setMuted( audioService.toggleMute() ) }>
        { ! muted ? <VolumeOnIcon /> : <VolumeOffIcon /> }
      </CircularButton> */}

      {  myUserData.eventHost && <CircularButton onClick={() => setEventHostPowers(true) }>
        <DrawToolIcon />
      </CircularButton>}

    </Ribbon>
  )
}

const Ribbon = styled.div`
  height: 90px;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;

  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none;

  padding-left: 60px;
  padding-right: 30px;

  &>* {
    pointer-events: auto;
  }

  /* .title:before {
    content: '';
    height: 1px;
    position: absolute;
    left: 43px;
    right: 43px;
    top: -10px;
    background: white;
  } */

  .filler {
    flex: 1;
  }

  .title {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 100px;
    letter-spacing: 1.5px;
    color: #FFE499;
    text-transform: capitalize;
  }

  .how-to {
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    border-top: 2px solid #0000;
    border-bottom: 2px solid #0000;
    transition: border-color 200ms;

    &:hover {
      border-top: 2px solid #0000;
      border-bottom: 2px solid white;
      cursor: pointer;
    }
  }

  .central-hint {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    > p {
      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #FFFFFF;
    }
  }

`
