import React, { useState } from 'react';

import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { MoonLoader } from 'react-spinners';
import { Spinner } from 'react-bootstrap';

export const RequestRolePanel:React.FC<any> = ({ role }) =>
{
  const userData = React.useContext( UserDataContext );

  const openLoginModal = () => {
    const loginButton = document.getElementsByClassName('login')[0];
    (loginButton as any).click();
  }

  const [permissionRequesting, setPermissionRequesting] = useState(false);
  const [permissionRequested, setPermissionRequested] = useState(false);
  const [requestError, setRequestedError] = useState("");

  const requestPermission = async () => {
    if (permissionRequesting)
      return;

    // const url = 'http://localhost:5001/ven-games---staging/us-central1/app';
    // const url = 'http://localhost:5001/ven-games/us-central1/app';
    const url = process.env.FUNCTION_URL; //'

    setPermissionRequesting(true);
    const response = await fetch(`${url}/send-email/role`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST', body: JSON.stringify({
        username: userData.username,
        userId: userData.uid,
        role,
      })
    });

    if (!response.ok)
      setRequestedError(await response.text())
      
    setPermissionRequesting(false);
    setPermissionRequested(true);
  }

  return (
    <div>
    {
      !userData.uid 
      ? <div className="spinner-wrapper">
          <MoonLoader
            size={50}
            color={"#303744"}
            loading={true}
          />
        </div>
      : userData.providerId == 'Anonymous'
        ?
        <div className="not-logged">
          <p>Login to access the page.</p>
          <button onClick={openLoginModal}>Log In</button>
        </div>
        : !userData[role] &&
          <div className="not-logged">
            <p>{ requestError ? requestError : `You are not a "${role}".`}</p>
              { permissionRequested && !requestError
                ? <p>Ok, wait for the approval.</p>
                :<button onClick={requestPermission}> {
                  permissionRequesting ?
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    : <span>Request Permission</span>
                }</button>
              }
          </div>
    }
    </div>
  )
}
