import React from 'react';

import { ToolBar } from "@ven/shared/components/game/drawing/ToolBar";
import { DrawingSettingsContext, DrawingTool } from './canvas/DrawingSettings';
import { BrushSizeIcon, BucketIcon, DrawToolIcon, EraserIcon, TrashIcon, UndoIcon } from '../../../shared/components/assets/icons';

import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import styled from '@emotion/styled'
import type { GameStateController } from '../gameplay/GameStateController';

const ToolBarGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  
  .toolbar:nth-of-type(1) {
    border-radius: 12px 12px 0 0;
  }
  
  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    width: 150%;
    position: absolute;
    bottom: 0;
    .toolbar {
      width: 150%;
      justify-content: center;
      gap: 25px;
    }

    .toolbar:nth-of-type(1) {
      border-radius: 0 0;
    }

    
    .toolbar:last-of-type {
      display: none;
    }

  }
`

const Separator = styled.div`
  flex: 0 0 12px;
  width: 12px;
  height: 12px;

  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    display: none;
  }
`

interface Props 
{
  optionChoices : {
    brushSizes : number[],
  }
  callbacks : {
    undo : () => any
    clear : () => any
  }
}
export const DrawingToolbox:React.FC<Props> = ({ optionChoices, callbacks }) => 
{
  const { settings, updateSettings } = React.useContext( DrawingSettingsContext )

  return (
    <ToolBarGroup>

      <ToolBar buttons={
        [
          { Icon : DrawToolIcon, onClick : () => updateSettings({ tool : DrawingTool.Draw }), selected : settings.tool === DrawingTool.Draw },
          { Icon : EraserIcon, onClick : () => updateSettings({ tool : DrawingTool.Erase }), selected : settings.tool === DrawingTool.Erase },
          { Icon : UndoIcon, onClick : () => callbacks.undo() },
          { Icon : TrashIcon, onClick : () => callbacks.clear() },
        ]
      }/>

      <Separator/>
      
      <ToolBar buttons={
        optionChoices.brushSizes.map( (size,i) => ({
            Icon : BrushSizeIcon,
            iconProps: { thickness: [1,3,5,8][ i ] },
            onClick: () => updateSettings({ brushSize : size }),
            selected: size === settings.brushSize,
        }) )
      }/>

    </ToolBarGroup>
  )
}
