import styled from "@emotion/styled";

export const SmallButton = styled.button`
  background: none;
  border: none;
  transform: translate(-50%,-50%);
  transition: transform 200ms;
  cursor: pointer;

  &:hover {
    transform: translate(-50%,-50%) scale(1.2);
  }
  &:active, &:focus {
    outline: none;
  }
  &:active {
    transform: translate(-50%,-50%) scale(.9);
    transition: transform 60ms;
  }
`
