import React from 'react';

import corner2 from '../../../../../assets/images/choose_bundle_corner_2.svg';

import './styles.scss'

interface GameNightProps {
    bannerText: string,
    title: string,
    subtitle?: string,
    text1?: string,
    text2?: string,
    text3?: string,
    text4?: string,
    text5?: string,
    buttonText: string,
    buttonAction: Function,
};

const GameNightCard: React.FC<GameNightProps> = ({
    bannerText,
    title,
    subtitle,
    text1,
    text2,
    text3,
    text4,
    text5,
    buttonText,
    buttonAction,
}) => {

    const runButtonAction = () => {
        buttonAction();
    }

    return (
        <div className="game-night-card">
            <div className="card-title">
                <p className="spacer">{" "}</p>
                <p className="banner">{bannerText}</p>
                <img src={corner2} alt="" />
            </div>
            <div className="card-content">
                <div className="title-box">
                    <p className="title">{title}</p>
                    <p className="subtitle">{subtitle}</p>
                </div>
                {text1 &&
                    <p className="text-card">{text1}</p>
                }
                {text2 &&
                    <p className="text-card">{text2}</p>
                }
                {text3 &&
                    <p className="text-card">{text3}</p>
                }
                {text4 &&
                    <p className="text-card">{text4}</p>
                }
                {text5 &&
                    <p className="text-card">{text5}</p>
                }
            </div>
            <div className="card-footer">
                <button className="desktop-btn" onClick={() => runButtonAction() }>{buttonText}</button>
                <div>
                    <img src={corner2} alt="" />
                </div>
            </div>
        </div>
    );
};

export default GameNightCard;
