import React from 'react';

import { GameStateContext } from '@ven/games/wordplay/gameplay/GameStateContext';
import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { MessageInput } from '../../organizms/MessageInput';

import { COLOR_DARK, COLOR_PRIMARY } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';

interface Props {
  title: string
}
export const ChatPanel: React.FC<Props> = ({ title }) => {
  const { game } = React.useContext(GameStateContext)
  const data = game.data!

  const myUserId = game.myUserId!
  const myTurn = data?.state.turn.player === myUserId && !game.amSpectator();

  const history = Object.entries(data.state.turn?.chat || {})
  const sendChatMessage = m => game.sendChatMessage(m)

  return (
    <Wrapper>
      <TitleBit>
        {title}
      </TitleBit>
      <MessagesContainer className="chat-messages-container">
        <MessageList>
          {
            history.reverse().map(([id, m]) => (
              !!m.type
                ?
                <Message key={id} className={`system ${m.type}`}>
                  {
                    data.players[m.sender] &&
                    <PlayerAvatar index={data.players[m.sender]?.avatar} size={26}
                      label={game.getTeamName(data.players[m.sender]?.team).split(" ")[0] + ""}
                    />
                  }
                  <span className="text"> {m.text} </span>
                </Message>
                :
                <Message key={id}>
                  {
                    data.players[m.sender] &&
                    <><PlayerAvatar index={data.players[m.sender]?.avatar} size={26}
                      label={game.getTeamName(data.players[m.sender]?.team).split(" ")[0] + ""} />
                      <span className="sender"> {data.players[m.sender]?.username}: </span>
                    </>
                  }
                  <span className="text"> {m.text} </span>
                </Message>
            ))
          }
        </MessageList>
        {!game.amSpectator() && !myTurn && <MessageInput handleSend={sendChatMessage} />}
      </MessagesContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  top: 130px; 
  right: 22px; 
  position: absolute;
  width: 267px;
  height: 430px;
`

const TitleBit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 58px;
  border-radius: 0 12px 0 0;

  background: var(--outlaw-headers);
  color: #FFFFFF;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
  height: 387px;
  border-radius: 0 0 12px 0;

  box-shadow: -6px 7px 28px rgba(0, 0, 0, 0.0819766);
  background: #F9F9FF;
  color: #303744;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 31px;

  z-index: 1;
`
const MessageList = styled.ul`
  margin-top: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-bottom: 52px;
  
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: overlay;
`
const Message = styled.li`
  list-style-type: none;
  padding: 0;
  margin: 0;
  
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; */
  text-align: start;
  padding: 8px 20px;

  animation: fade-in 500ms;

  & div {
    float: left;
    padding-right: 5px;
  }
  
  & span {
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: ${COLOR_DARK};
    user-select: text;
  }

  & span.sender {
    font-weight: bold;
  }

  &.system span {
    font-weight: bold;
  }

  &.system.foul {
    background: rgba(252, 59, 104, 0.291576);
    width: 100%;
  }

  &.system.win {
    background: rgba(88, 204, 11, 0.292);
    width: 100%;
  }

  &.system.warning {
    background: rgba(250, 213, 1, 0.292);
    width: 100%;
  }

  & .avatar {
    width: 26px;
    height: 26px;
  }

  & .badge {
    padding-right: 25px;
    background-color: transparent;
  }
`
