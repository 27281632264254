import React from 'react';

import { HowToPlay } from '@ven/shared/components/common/templates/HowToPlay';

//@ts-ignore
import HowToImage from "@ven/components/assets/wordplay/how-to.jpg";

const howToData = {
  title : "How to Play Outlaw",
  image : HowToImage,
  text : [
    {
      heading : "OBJECTIVE",
      content : 
`Give your team verbal clues to have them guess the word at the top of the card. The words below it are words that are outlawed (forbidden). Teams take turns giving clues and guessing words without saying any “forbidden” words on the card. If you hear the other team use an outlawed word, buzz them in! The team with the most points after all rounds wins!`,
    },
    {
      heading : "QUICK RULES",
      content : 
`• IMPORTANT: Outlaw requires a voice chatting app (ie Zoom, Discord, etc.)

• Give your team verbal clues to have them guess the word at the top of the card. The words below it are words that are outlawed (forbidden).

• The more words you can get your team to guess within your turn, the more points you receive. When your team says the word out loud, hit the “correct” button or swipe right if you’re on mobile. If you pass a word, you lose a point! 

• When it’s not your team’s turn to give/guess words, you’re on police duty! If the speaker says any of the forbidden words on the card, press the “Outlaw” button and they’ll lose a point. Careful though — this applies to your team too!

Good luck and have fun!
`
    },
  ]
}

export const HowToPlay_Outlaw:React.FC<any> = ({ ...props }) =>
  <HowToPlay { ...props } howToData={ howToData } />

