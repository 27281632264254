import React from 'react';
import * as ReactRouter from "react-router-dom";

import { GameStateContext } from '../../gameplay/GameStateContext';
import { COLOR_HIGHLIGHTER, COLOR_TOOL_IDLE } from '@ven/shared/components/styling/colors';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';

import styled from '@emotion/styled';

// @ts-ignore
import CanvasImage from "../assets/canvas.svg";
import Notepad from '../notepad/Notepad';
import SpeechBubble from '../speechBubble/SpeechBubble';
import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { DownloadIcon, ShareIcon } from '../../../../shared/components/assets/icons';
import CardScrollLayout from '../CardScrollLayout';
import { ReadonlyCanvas } from '../canvas/ReadonlyCanvas';
import { SentenceCanvas } from '../canvas/SentenceCanvas';
import GifService from '../services/GifService';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';
import { PlayAgainButton } from '@ven/shared/components/game/molecules/PlayAgainButton';

export const GameOverView: React.FC = () => {
  
  const { game, data } = React.useContext(GameStateContext)
  const history = ReactRouter.useHistory()

  const canvasConfig = { width: 373, height: 373 };
  const totalTime = 4000;

  const [currentNotepad, setCurrentNotepad] = React.useState(Object)
  const [displayDetails, setDisplayDetails] = React.useState(false)
  const [timer, setCurrentTimer] = React.useState(totalTime)

  const [redirecting, setRedirecting] = React.useState(false)
  const nextGameUrl = data?.state.nextGameUrl;

  const currentTimer = React.useRef<number>(totalTime);
  const isPaused = React.useRef<boolean>(false);

  /** I call 'stories' the sequences of submissions in response to each player's first sentence. */
  const stories = Object.values(data?.players || {}).map(p => Object.values(p.story)).reduce((p, c) => c.concat(p))
  /** All player's submissions in response to one player's first sentence (for testing) */
  // const submissions = stories[0];

  const ticks = 10;
  const interval = React.useRef<any>();
  React.useEffect(() => {
    interval.current = setInterval(() => {
      // console.log(currentTimer.current + " " + isPaused.current)

      if (currentTimer.current <= 0.0) {
        currentTimer.current = 0;
        setCurrentTimer(currentTimer.current)
      }
      if (isPaused.current) {
        currentTimer.current = totalTime;
        setCurrentTimer(currentTimer.current)
        // clearInterval(interval.current);
        return;
      }
      currentTimer.current = currentTimer.current - ticks
      setCurrentTimer(currentTimer.current)
    }, ticks)
    // return clearInterval(interval.current);
  }, [stories.length])


  const getUsername = (id) => {
    return data?.players[id]?.username;
  }

  const getAvatar = (id) => {
    return data?.players[id]?.avatar;
  }

  const getPlayerFirstSentence = (id) => {
    return data?.players[id]?.firstSentence;
  }

  const topAvatar = () => {
    return (
      <div>
        <div className="tip top-tip">
          <TimerCircleProgress time={timer} totalTime={totalTime} radius={60} displayTime={false} stroke={4} interpolate={isPaused.current!} />
          <PlayerAvatar size={100} index={getAvatar(currentNotepad.rotation == 0 ? currentNotepad?.originator : currentNotepad?.author)} />
          <Spacer size={10} />
          <span>{`${getUsername(currentNotepad.rotation == 0 ? currentNotepad?.originator : currentNotepad?.author)} ${currentNotepad?.type === "sentence" ? 'wrote' : 'drew'}:`}</span>
        </div>
      </div>
    )
  }

  const bottomAvatar = () => {
    return (
      <div>
        <Spacer size={21} />
        <div className={`tip bottom-tip ${currentNotepad.rotation == 0 ? "hide-bubble" : null}`}>
          <span>{`${getUsername(currentNotepad?.originator)} wrote:`}</span>
          <Spacer size={10} />
          <SpeechBubble text={getPlayerFirstSentence(currentNotepad?.originator)} />
          <PlayerAvatar size={100} index={getAvatar(currentNotepad?.originator)} />
        </div>

        <Spacer size={10} />
      </div>
    )
  }


  const onIndexChange = (index) => {
    if (!stories[index]) {
      setDisplayDetails(false)
      return;
    }
    setCurrentTimer(2);
    currentTimer.current = totalTime + 0.1;
    setCurrentNotepad(stories[index]);
    setDisplayDetails(stories[index] != null)
  }

  const onPause = () => {
    isPaused.current = true;
  }
  const onResume = () => {
    isPaused.current = false;
  }

  const downloadAction = (originator?) => {
    const canvases: any = [];
    const canvasNodes = document.querySelectorAll('canvas');

    if (!originator) {
      canvasNodes.forEach(canvasNode => {
        canvases.push(canvasNode)
      })

      GifService().generateGif(canvases);
      return;
    }

    const coverIndex = stories.findIndex(s => s.originator == originator && s.rotation == 0);
    canvases.push(canvasNodes[coverIndex]);
    for (let index = coverIndex + 1; index < canvasNodes.length; index++) {
      if (stories[index].rotation == 0) {
        break;
      }
      canvases.push(canvasNodes[index]);
    }

    GifService().generateGif(canvases);
  }

  const floatButtons = () => {
    return (
      <div className="float-buttons-menu">
        <div className="float-button share-button">
          <ShareIcon color={COLOR_TOOL_IDLE} />
        </div>
        <div className="float-button-notepad current-button" onClick={() => { downloadAction(currentNotepad?.originator) }}>
          <Notepad cover={true}>
            <div className="float-notepad-body">
              <DownloadIcon color={COLOR_TOOL_IDLE} />
            </div>
          </Notepad>
        </div>
      </div>
    )
  }

  const BackToLobby = () => {
    return (
      <div className="body game-over">
        <span>Thanks for playing</span>
        <h1>BrokenMsg</h1>
        <PlayAgainButton gameName='brokenmsg'
          nextGameUrl={nextGameUrl} 
          isEventHost={game.data.eventHost}
          isHost={game.amHost()}
          isSpectator={game.amSpectator()}
          updateState={game.update.state} 
          setRedirecting={setRedirecting} isRedirecting={redirecting}
        />
        <GreenButton onClick={() => history.push("/")}>
          Back to Games!
        </GreenButton>
        {/* <div className="float-button all-button" onClick={() => { downloadAction() }}>
          <DownloadIcon color={COLOR_TOOL_IDLE} />
        </div> */}
      </div>
    )
  }

  return !data ? null : (

    <Wrapper className={`${displayDetails ? null : "hide-detail"}`}>
      <Spacer size={5} />
      { topAvatar()}
      <Spacer size={10} />
      <CardScrollLayout onIndexChange={onIndexChange} onPause={onPause} onResume={onResume} autoscrollTime={totalTime}>
        {
          [...stories, null].map((submission, i) => {
            return React.useMemo(() =>
              <Notepad key={i} cover={submission?.rotation == 0}>
                {
                  submission === null
                    ? BackToLobby()
                    : submission.type === "drawing"
                      ? <ReadonlyCanvas
                        config={canvasConfig}
                        data={submission.canvas || []}
                        fillSpeed={100}
                        backgroundColor={'#FFFFFF'}
                      />
                      : <SentenceCanvas
                        config={canvasConfig}
                        sentence={submission.sentence}
                        backgroundColor={submission?.rotation == 0 ? '#FFE499' : '#FFFFFF'}
                      />
                }

              </Notepad>, [submission, nextGameUrl, redirecting]
            )
          })
        }
      </CardScrollLayout>
      { bottomAvatar() }
      { floatButtons() }
    </Wrapper>
  )
}

const Wrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;

@media (max-width: ${MOBILE_BREAK_PX}px) {
  .item {
    margin: 0 8px !important;
  }

  .Notepad {
    width: 350px;
    .drawing-canvas, .sentence-canvas, canvas {
      width: 350px !important;
    }
  }
}

&:not(.hide-detail) {
    .top-tip, .bottom-tip:not(.hide-bubble){
      transition: all 1s;
      visibility: visible;
      opacity: 1;
    }
  }

  &.hide-detail .current-button {
    opacity: 0;
    transition: all 1s;
    visibility: hidden;
  }
/* 
  &.hide-detail .float-buttons-menu {
    bottom: 50%;
  } */

.tip {
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 373px;
  justify-content: flex-end;
  opacity: 0;
  transition: all 1s;
  visibility: hidden;
}
.tip-bottom.hide-bubble {
  opacity: 0;
  visibility: hidden;
}

.top-tip {
  .circle-clock {
    position: absolute;
    top: -5px;
    z-index: -1;
  }
}
.dropping-the-shadow.drawing-canvas {
  box-shadow: none;
  align-self: center;
}

.sentence-canvas canvas{
  margin-top: -5rem;
}

.bottom-tip {
  @media (max-width: ${MOBILE_BREAK_PX}px) {
    	height: 100%;
  }
}

.Notepad {
   .drawing-canvas, .sentence-canvas, canvas {
      align-self: center;
    }
  }

.float-buttons-menu {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
  align-content: space-around;
  gap: 0.9rem;
  right: 20%;
  /* bottom: 50%; */
  bottom: 1.2rem;
  font-size: 3.5rem;

  @media (max-width: ${MOBILE_BREAK_PX}px) {
    bottom: 1.2rem;
  }

  .share-button {
    display: none;
  }
}

.float-button-notepad {
  height: 56px;
  width: 56px;
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;

  .Notepad {
    height: 200px;
    flex-grow: 0;
    transform: scale(0.25);
    transition: transform 200ms, background-color 200ms;
    cursor: pointer;

    .float-notepad-body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    svg {
      transform: scale(3.0);
      display: flex;
      align-self: center;
      margin-top: 20px;
      stroke-width: 2px;
      transition: stroke 200ms;
    }

    &:hover {
      /* background: ${COLOR_TOOL_IDLE};
      stroke: ${COLOR_HIGHLIGHTER}; */
      transform: scale(0.35);
      
      .top {
        /* background: ${COLOR_TOOL_IDLE}; */
      }

      svg {
        /* stroke: white; */
      }
    }

    &:hover * {
      /* stroke: white; */
    }

    /* &:active {
      transform: scale(0.40);
      outline: none;
    } */

    &:focus {
      outline: none;
    }
  }
}


.float-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${COLOR_HIGHLIGHTER};
  color: ${COLOR_TOOL_IDLE};
  height: 56px;
  width: 56px;
  border: none;
  transition: transform 200ms, background-color 200ms;

  svg {
    stroke-width: 2px;
    transition: stroke 200ms;
  }

  &:hover {
    background: ${COLOR_TOOL_IDLE};
    stroke: ${COLOR_HIGHLIGHTER};
    transform: scale(1.2);
    svg {
      stroke: white;
    }
  }

  &:hover * {
    stroke: white;
  }

  &:active {
    transform: scale(.8);
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.game-over {
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  justify-content: space-around;
  text-align: center;
  gap: 3rem;
  margin: 0;
  color: #303744;
 
  h1 {
    font-size: 5rem;
    font-family: Oswald;
    color: #0F4D37;
  }

  span {
    font-size: 2rem;
  }
}

`
