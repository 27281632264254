/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from '@emotion/styled';
import { PORTRAIT_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

import React from 'react';
import { GameStateContext } from '../../gameplay/GameStateContext';
import type { PlayerID } from '@ven/shared/core/gameplay/GameSessionData';

import { WordOrHint } from '../atoms/WordOrHint';
import { EnableFullScreenIcon, VolumeOffIcon, VolumeOnIcon } from "../assets/icons";

import { toggleFullscreen } from "@ven/core/utils/toggleFullscreen"
import { audioService } from "@ven/core/services/SoundService";

interface Props 
{ 
  myUserId : PlayerID
}
export const GameHeader:React.FC<Props> = ({ myUserId }) =>
{
  const { game, data } = React.useContext( GameStateContext )
  
  const [ muted, setMuted ] = React.useState(audioService.muted);

  const myTurn = data?.state.turn.player === myUserId && !game.amSpectator()
  const showWholeWord = myTurn || !! data?.players?.[ myUserId ]?.guessed
  const currentWord = data?.state?.turn.word && 
  ( 
    showWholeWord ? 
    data?.state?.turn?.word : 
    data?.state?.turn?.wordHint 
  )

  const roundLabelText = !! data?.state?.round?.number ? `Round: ${ data?.state?.round?.number } / ${ data.config.rounds }` : `...`

  return (
    <Container className="game-header">
      <Title> Scribbler </Title>
      <RoundLabel> { roundLabelText } </RoundLabel>
      {
        !! currentWord &&
        <div css={ css`
          display: flex;
          flex-direction: row;
          align-items: center;
        ` }>
          <Tip> { myTurn ? "Draw" : "Guess" } this: </Tip>
          <WordOrHint word={ currentWord } whole={ showWholeWord } />
        </div>
      }
      <div className="button-bar">
        <button onClick={ () => toggleFullscreen() }> <EnableFullScreenIcon color="#3148BF" /> </button>
        <button onClick={ () => setMuted( audioService.toggleMute() ) }> 
          { muted ? <VolumeOffIcon color="#3148BF" /> : <VolumeOnIcon color="#3148BF" /> } 
        </button>
      </div>
    </Container>
  )
}

const Container = styled.div`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 28px;
  box-sizing: border-box;
  height: 75px !important;
  min-height: 75px !important;
  max-height: 75px !important;

  background: white;
  color: gray;
  margin: 0 0 10px 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  transition: height 300ms, margin-top 300ms;
  @media (max-width: ${ PORTRAIT_BREAK_PX }px) {
    margin-top: 12px;
    margin-top: 0px;
    padding: 0px;
    height: 0px;
    /* display: none; */
  }

  .button-bar {
    position: absolute;
    top:0;
    bottom:0;
    right: 15px;
    
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      background: none;
      border: none;
      width: 30px;
      height: 30px;
      margin: 16px;
      cursor: pointer;
      transition: transform 200ms;
      &:hover {
        transform: scale(1.1);
      }
      &:active, &:focus {
        outline: none;
      }
      &:active {
        transform: scale(.95);
        transition: transform 60ms;
      }
    }
  }
`

const Title = styled.div`
  font-family: "Fira Sans";
  font-weight: black;
  font-size: 27px;
  color: #303744;
  position: absolute;
  left: 28px;
  top: 8px;
`

const RoundLabel = styled.div`
  font-family: "Bitter";
  font-weight: 900;
  font-size: 20px;
  color: #A19AC6;
  position: absolute;
  left: 28px;
  bottom: 8px;
`

const Tip = styled.div`
  font-family: "Bitter";
  font-weight: bold;
  font-size: 15px;
  color: #A19AC6;
  margin: 12px;
`
