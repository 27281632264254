import React, { useEffect, useRef } from 'react';

import debounce from "lodash.debounce";

import styled from "@emotion/styled";
import { LogService } from '@ven/platform/main/services/log/LogService';
import { VipCodeService } from '@ven/shared/core/services/VipCodeService';

export const VipCodeInput:React.FC<{data : any}> = (props) => {
  const data = props.data;
  const [vipcode, setVipCode] = React.useState( props.data.vipCode );

  const vipcodeData = vipcode;//props..session?.games[props.data?.index-1]?.vipcode;


  useEffect(()=> {
    if ( vipcodeData !== undefined ) {
      setVipCode( vipcodeData );
    }
    if( props.data.vipCode )
      handleChange(props.data.vipCode)
  }, [  ])

  const vipInput = useRef<any>(null);

  useEffect(()=>{
    setTimeout(() => {
      if(vipInput.current)
      vipInput.current.focus();
    }, 0)
  },[vipInput])

  // useEffect(()=> {
  //   if ( vipcodeData !== undefined ) {
  //     setVipCode( vipcodeData );
  //   }
  // }, [ props.data.session ])


  const updateDebounce = debounce(
    async name => {
      data.callback(await Get( name ))
    }, 
    250
  );
  const update = React.useCallback(updateDebounce, []);

  const handleChange = name => {
    setVipCode(name)
    update(name) 
  }

  const Get = async (vipcode: string) => {
    try {
      vipcode = vipcode.trim();
      if(vipcode.toLowerCase().includes("vipcode=")){
        vipcode = vipcode.split("vipcode=")[1];
      }

      if(!/^[a-zA-Z0-9_-]*$/.test(vipcode)) {
        return {error : "Valid characters: [a-zA-Z0-9_-]"};
      }

      data.callbackSpinner(true);
      const vip = await VipCodeService.token(vipcode, data.uid);
      data.callbackSpinner(false);
      // return vip.length;
      return vip;
    } catch (err) {
      data.callbackSpinner(false);
      await LogService.log(err, 'Error: Could not update vipcode.')
      console.log(err);
      return false;
    };
  };

  return (
    <Input
    type="text"
    className="vip-input"
    ref={vipInput}
    maxLength={20}
    onBlur={ () => !vipcode }
    onChange={ e => handleChange(e.target.value) }
    onKeyPress={ e => e.key === "Enter" && e.target['blur']() }
    value={ vipcode || '' }
    />
  )
}

const Input = styled.input`
/* margin-top: 10px; */

/* padding-top: 10px; */
/* display: inline-block; */
width: 100%;

transition: none;
/* height: 67px; */
/* border-radius: 7px; */
padding: 0;

border: 1px solid;
height: 35px;
max-width: 385px;
/* width: 100%; */

font-family: Bitter;
font-style: normal;
font-weight: bold;
font-size: 17px; /* To avoid zooms bugs on IOS, never do an input font-size less than 17px */
line-height: 67px;
text-align: center;

color: #303744;

&:focus {
  outline: none;
}
`

