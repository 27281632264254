import React from 'react';

import { MainHeader } from '@ven/platform/main/components/organisms/MainHeader';
import { InGameTemplate } from '../templates/InGameContent';
import { PreGameContent } from '../templates/pregame/PreGameContent';
import { MainContent } from '@ven/platform/main/components/organisms/MainContent';

import { GameStateContext } from '../../gameplay/GameStateContext';

import { PORTRAIT_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { DEVELOPMENT_MODE } from '@ven/core/debug/consts';
import { useDebugKeys } from '@ven/core/debug/useDebugKeys';

import styled from '@emotion/styled';

//@ts-ignore
import DesktopBackground from "@ven/shared/components/assets/scribbler/bg.jpg";
//@ts-ignore
import HolidayBackground from "@ven/shared/components/assets/scribbler/bg-holiday.jpg";
//@ts-ignore
import MonstersImage from "@ven/shared/components/assets/scribbler/Monsters.png";

import "./PlayerView.scss"
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';

interface Props 
{ 
  gameId : string,
  roomId : string,
  userId : string,
}
export const PlayerViewPage:React.FC<Props> = ({ gameId, roomId, userId: myUserId }) => 
{
  const { game, data } = React.useContext( GameStateContext )

  const started = data?.state.started && data?.state.turn

  if ( ! data )
  {
    return null
  }

  DEVELOPMENT_MODE && useDebugKeys(game)

  const isHoliday = UserDataService.get('holiday') ? 'holiday' : '';

  return (
    <Wrapper>
      <MainContent className={`${game.gameId} ${( started ? 'ingame' : 'pregame' )} ${isHoliday}`}>
        {
          ! started
          ? <PreGameContent /> 
          : <InGameTemplate { ...{ myUserId } } />
        }
      </MainContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #524EEB;
  background-image: url(${ DesktopBackground });
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
 
  transition: background-image 200ms;
  
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:before {
    content: '';
    background-image: url(${ MonstersImage });
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: auto;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    transition: opacity 400ms;
    pointer-events: none;
    /* z-index: -1; */
  }

  @media (max-width: ${ PORTRAIT_BREAK_PX }px) {
    &:before {
      opacity: 0;
    }
  }

  .main-content.holiday {
    background-image: url(${ HolidayBackground }); 
    background-position: top;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .main-content.pregame {
    height: auto;
    /* width: 100vw; */
    overflow: hidden;
  }

  .main-content.ingame {
    /* height: 100vh;
    width: 100vw; */
    overflow: hidden;
  }
`
