import React from 'react';

import styled from '@emotion/styled';

export const ClockInACircle:React.FC<{time:number|string}> = ({ time }) => 
{
  const timeText = ( time || 0 ).toString().padStart( 2, '0' )

  return (
    <Wrapper className="circle-clock">
      { timeText }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 50px;
  
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #FFFFFF;

  border: 6.48px solid #FFFFFF;
  border-radius: 50%;
  width: 75px;
  height: 75px;

  top: 0px;
  left: 50%; 
  transform: translate(-50%, -50%); 
  position: absolute;
`
