import React from 'react';

import type { GameStateController } from '@ven/games/scribbler/gameplay/GameStateController';
import type { CanvasAction, CanvasAction_BrushStart, CanvasActoin_BrushMove } from '@ven/games/scribbler/drawing/CanvasAction';
import { DrawingSettingsContext, DrawingTool } from '@ven/games/scribbler/drawing/DrawingSettings';
import { useAnimationFrame } from '@ven/shared/components/hooks/useAnimationFrame';
import { colorIntegerToHashTag } from '@ven/shared/core/utils/colors';

import styled from '@emotion/styled';

type Coordinates = {
  x: number;
  y: number;
};

interface Props 
{
  config : {
    width: number,
    height: number,
    minLineLength: number
  }
  game : GameStateController,
  watchOnly : boolean
}
export const DrawingCanvas:React.FC<Props> = ({ config, game, watchOnly, children }) => 
{
  const broadcastCanvasAction = ( action:any ) => 
  {
    if((game?.data?.state.turn?.time! > 0)) {
      game.ref.canvas().push( action )
      return;
    }
  }

  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const canvasTempRef = React.useRef<HTMLCanvasElement>(null);
  const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(null);
  const [contextTemp, setContextTemp] = React.useState<CanvasRenderingContext2D | null>(null);

  const { width, height } = config

  ////TODO this is redrawing the entire canvas, must fix ! ! ! ! !
  const { settings } = React.useContext( DrawingSettingsContext )

  console.log( `Canvas rerendered///` )

  ////TODO rename this, or better split all this stuff
  //// into someone to handle in/out drawing data
  //// and someone to handle actual local input 
  let prevPointActive = {x: 0, y: 0}; 

  const prevPoint = {x: 0, y: 0};
  const points:Coordinates[] = [];

  
  const BRUSH_START_ACTION = 0;
  const BRUSH_MOVE_ACTION = 1;
  const BRUSH_END_ACTION = 2;

  const clearCanvasContext = ( context:CanvasRenderingContext2D ) =>
  {
    context.clearRect( 0, 0, width, height );
  }

  const handleAction = ( action:CanvasAction ) =>
  {
    if ( context && contextTemp )
    {
      const [ actionType ] = action
      
      const onPaint = () => {
        points.push({ ...prevPoint });
        
        if (points.length < 3) {
          const b = points[0];
          contextTemp.beginPath();
          contextTemp.arc(b.x, b.y, contextTemp.lineWidth / 2, 0, Math.PI * 2, !0);
          contextTemp.fill();
          contextTemp.closePath();
          return;
        }
        
        clearCanvasContext( contextTemp )
        contextTemp.beginPath();
        contextTemp.moveTo(points[0].x, points[0].y);
        
        for (var i = 1; i < points.length - 2; i++) {
          const c = (points[i].x + points[i + 1].x) / 2;
          const d = (points[i].y + points[i + 1].y) / 2;
          contextTemp!.quadraticCurveTo(points[i].x, points[i].y, c, d);
        }
        
        contextTemp!.quadraticCurveTo(
          points[i].x,
          points[i].y,
          points[i + 1].x,
          points[i + 1].y
        );

        contextTemp.stroke();
      };

      if ( actionType === BRUSH_START_ACTION )
      {
        const [ , x, y, color, size ] = action as CanvasAction_BrushStart
        contextTemp.strokeStyle = colorIntegerToHashTag( color < 0 ? 0xFFFFFF : color )
        contextTemp.fillStyle = colorIntegerToHashTag( color < 0 ? 0xFFFFFF : color )
        contextTemp.lineWidth = size;
        contextTemp.lineCap = "round";
      
        prevPoint.x = x
        prevPoint.y = y
        onPaint()
        
        context.globalCompositeOperation = color < 0 ? "destination-out" : "source-over"
      }
      if ( actionType === BRUSH_MOVE_ACTION )
      {
        const [ , x, y ] = action as CanvasActoin_BrushMove

        prevPoint.x = x
        prevPoint.y = y
        onPaint()
      }
      if ( actionType === BRUSH_END_ACTION )
      {
        context.drawImage( canvasTempRef.current!, 0, 0 );
        clearCanvasContext( contextTemp )
        points.length = 0;
      }
      
    }
  }

  //// Whenever canvas children are removed (via 'undo' or 'clear'), if we react to each child
  //// the call stack can easily become too large. So we only flag 'redraw' as true, and check 
  //// that value every frame, then check it again only after we're done with the previous redraw
  const frameLoopRef = React.useRef<{ redraw:boolean, busy:boolean }>({ redraw:false, busy:false });
  useAnimationFrame( async () => {
    const ensureToFinishTheDrawWhenTurnIsOver = () => {
      if(!game?.data?.state.turn.over || watchOnly)
        return;
      game.ref.canvas().push([BRUSH_END_ACTION])
      points.length = 0;
      clearCanvasContext(context!)
      clearCanvasContext(contextTemp!)
    }

    if ( context && contextTemp ) {
      ensureToFinishTheDrawWhenTurnIsOver()
      if ( frameLoopRef.current.redraw && ! frameLoopRef.current.busy ) {
        try {
          frameLoopRef.current.busy = true
          const ref = game.ref.canvas()
          const canvas:Record<string,any> = await (await ref.once( "value" )).val()
          clearCanvasContext( context )
          if(game?.data?.state.turn.started)
            Object.values( canvas || {} ).forEach( a => handleAction( a ) )
        }
        finally {
          frameLoopRef.current.redraw = false
          frameLoopRef.current.busy = false
        }
      }
    }
  }, [ context && contextTemp ] )

  React.useEffect( () => 
  {
    if ( context && contextTemp )
    {
      const ref = game.ref.canvas()
      const onNewAction = op => handleAction( op.val() )
      const onRemove = async () => frameLoopRef.current.redraw = true
      ref.on( "child_added", onNewAction )
      ref.on( "child_removed", onRemove )
      return () => 
      {
        ref.off( "child_added", onNewAction )
        ref.off( "child_removed", onRemove )
      }
    }
    return
  }, [ context && contextTemp ] )

  React.useEffect(() => 
  {
    if ( ! canvasRef.current || ! canvasTempRef.current )
    {
      return
    }

    if ( ! context ) {
      const ctx = canvasRef.current.getContext('2d');
      if( ctx )
      {
        setContext(ctx);
      }
    }

    if ( ! contextTemp ) {
      const ctx = canvasTempRef.current.getContext('2d');
      if (ctx) {
        setContextTemp(ctx);
      }
    }

    const getXY = (e:MouseEvent|Touch) =>
    {
      const el = canvasRef.current!
      const rect = el.getBoundingClientRect()
      const { clientX, clientY } = e as any

      const result = {
        x: width * ( clientX - rect.x ) / rect.width,
        y: height * ( clientY - rect.y ) / rect.height,
      }

      return result
    }

    const isInRange = ( a:Coordinates, b:Coordinates, range:number ) =>
    {
      return ( a.x - b.x ) ** 2 + ( a.y - b.y ) ** 2 < range ** 2
    }

    const isInCanvasBounds = ( { x, y }:Coordinates ) => ( x > 0 && x < width && y > 0 && y < height )

    let mouseDown: boolean = false;

   const handleMouseDown = (evt: MouseEvent) =>
    {
      evt.preventDefault();
      mouseDown = true;

      const { x, y } = getXY( evt )
      const color = settings.tool === DrawingTool.Draw ? settings.brushColor : -1
      broadcastCanvasAction( [ BRUSH_START_ACTION, x, y, color, settings.brushSize ] )
      prevPointActive = { x, y }
    }

    const handleMouseUp = (evt: MouseEvent) =>
    {
      if (mouseDown)
      {
        evt.preventDefault();
        mouseDown = false;
        broadcastCanvasAction( [BRUSH_END_ACTION] )
      }
    }

    const handleMouseMove = (evt: MouseEvent) =>
    {
      if (mouseDown) {
        evt.preventDefault();
        const { x, y } = getXY( evt )
        if ( isInCanvasBounds({ x, y }) || isInCanvasBounds( prevPointActive ) )
        {
          if ( ! isInRange( prevPointActive, { x, y }, config.minLineLength ) )
          {
            broadcastCanvasAction( [ BRUSH_MOVE_ACTION, x, y ] )
            prevPointActive = { x, y }
          }
        }
      }
    }

    const onTouchStart = (evt: TouchEvent) =>
    {
      // document.body.requestFullscreen();
      evt.preventDefault();
      mouseDown = true;

      const { x, y } = getXY( evt.touches.item(0)! )
      const color = settings.tool === DrawingTool.Draw ? settings.brushColor : -1
      broadcastCanvasAction( [ BRUSH_START_ACTION, x, y, color, settings.brushSize ] )
    }

    const onTouchEnd = (evt: TouchEvent) =>
    {
      if (mouseDown && context) {
        evt.preventDefault();
        mouseDown = false;
        broadcastCanvasAction( [BRUSH_END_ACTION] )
      }
    }

    const onTouchMove = (evt: TouchEvent) => 
    {
      if (mouseDown && context) {
        evt.preventDefault();
        const { x, y } = getXY( evt.touches.item(0)! )
        broadcastCanvasAction( [ BRUSH_MOVE_ACTION, x, y ] )
      }
    }

    if ( canvasRef.current && ! watchOnly ) {
      canvasRef.current.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      canvasRef.current.addEventListener("touchstart", onTouchStart);
      canvasRef.current.addEventListener('touchmove', onTouchMove);
      document.addEventListener('touchend', onTouchEnd);

      return () => {
        if (canvasRef.current) {
          canvasRef.current.removeEventListener('mousedown', handleMouseDown);
          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
          
          canvasRef.current.removeEventListener("touchstart", onTouchStart);
          canvasRef.current.removeEventListener('touchmove', onTouchMove);
          document.removeEventListener('touchend', onTouchEnd);
        }
      }
    }

    return
	
  }, [ !! canvasRef.current && !! canvasTempRef.current, watchOnly, settings ]);

  return (
    <Wrapper
    className="dropping-the-shadow drawing-canvas"
    width={ width }
    style={{ 
      width: width + "px",
      height: height + "px",
    }}>
      <RealCanvas
        id="canvas"
        ref={canvasRef}
        width={width}
        height={height}
      />
      <TempCanvas
        id="canvas-temp"
        ref={canvasTempRef}
        width={width}
        height={height}
      />
      { children }
    </Wrapper>
  );
}

const Wrapper = styled.div<{ width:number }>`
  position: relative;
  overflow: hidden;
  @media (min-width: ${ props => props.width }px) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  canvas {
    cursor: grabbing;
  }
`

const RealCanvas = styled.canvas<{ width:number }>`
  width: 100%;
  height: 100%;
  background: #FFFD;
  box-shadow: #FFF 0 0px 240px inset;
`

const TempCanvas = styled.canvas<{ width:number }>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  cursor: crosshair;
  opacity: 0.6;
  touch-action: none;
  pointer-events: none;
`
