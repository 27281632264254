import * as React from "react";
import "./SpeechBubble.scss";

interface SpeechBubbleProps {
  text?: String;
  avatar?: String;
}

const SpeechBubble: React.FC<SpeechBubbleProps> = (props) => {
  return (
    <div className="SpeechBubble">
      <div className="body">
        <h2>"{props.text}"</h2>
      </div>
      <div className="arrow"></div>
    </div>
  );
};

export default SpeechBubble;
