import React, { FormEvent, useEffect, useState } from 'react';
import {
    SignInWithGoogle,
    SignInWithFacebook,
    SignUpWithEmailAndPassword,
} from '../../../services/AuthenticationService'
import { database } from '@ven/shared/core/data/firebase';

import peopleImg from '../../../assets/images/choose_bundle_img.svg';
import corner1Img from '../../../assets/images/choose_corner_1.svg';
import corner2Img from '../../../assets/images/choose_corner_3.svg';
import closeBlack from '../../../assets/images/close_black.svg';
import closeWhite from '../../../assets/images/close_white.svg';
import usernameIcon from '../../../assets/images/user_icon.svg';
import emailIcon from '../../../assets/images/email_icon.svg';
import passwordIcon from '../../../assets/images/password_icon.svg';
import confirmationImg from '../../../assets/images/confirmation_image.svg';
import facebookButton from '../../../assets/images/facebook_button.png';
import googleButton from '../../../assets/images/google_button.png';
import line from '../../../assets/images/line.svg';

import './styles.scss'
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { useHistory } from 'react-router-dom';
import { LogService } from '@ven/platform/main/services/log/LogService';
import { MainHeader } from '../../organisms/MainHeader';
import { GetSessionAndRedirectToCheckout } from '@ven/platform/main/services/PaymentService';
import { CouponService } from '@ven/shared/core/services/CouponService';
import { Helmet } from 'react-helmet';
import { analyticsService } from '@ven/platform/main/services/AnalyticsService';

const GameNightSteps = () => {
    const history = useHistory();

    const createItemInfo = (code, name) => { return {
            currency: 'USD',
            items: [{
                item_id: code,
                item_name: name,
                item_category: 'Bundle',
                price: 12.00,
                currency: 'USD',
                quantity: 1
            }],
            value: 12.00
    }}
    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const code = query.get('success');
        const backUrl = query.get('back_url');
        if (!code) {
            return
        }
        setStep(4);
        setLoading(true);
        CouponService.IsUnclaimedCode(code)
            .then(async (unclaimed) => {
                if (!unclaimed) {
                    setLoading(false);
                    setError("Already claimed code");
                    console.log("Already claimed code")
                    return;
                }

                const isAnon = UserDataService.get('isAnon');

                if (isAnon === 'true') {
                    setStep(1);
                    return;
                }

                const packageName = teamsVerify ? 'Team Bundle' : 'Game Night Bundle';
                const done = await CouponService.PutCodeOnUserInfo(packageName, code, 1200, 'UNUSED');
                if (!done) {
                    setLoading(false);
                    setError("Unable to add data");
                    console.error("Error");
                    return;
                }

                const claimedResult = await CouponService.SetCodeAsClaimed(code);
                if (!claimedResult) {
                    setLoading(false);
                    setError("Unable to claim code");
                    console.error("unable to claim")
                    return;
                }

                setLoading(false);
                const uid = UserDataService.get('uid');
                const dbRef = database.ref(`/users/${uid}`);
                const data = await dbRef.once('value');
                const userValue = data.val();
                analyticsService.purchase(createItemInfo(code, packageName));
                
                if(userValue.luckyFriend && userValue.luckyFriend.emailFriend){
                    const bundle = `${teamsVerify ? 'team' : 'gnb'}`;
                    const userParams = `name=${userValue.username}&dest=${userValue.email}&code=${code}`
                    const friendCode = await CouponService.GenerateUnclaimedCode();
                    const friendParameters = `friendname=${userValue.luckyFriend.userNameFriend}&friendemail=${userValue.luckyFriend.emailFriend}&friendcode=${friendCode}`;
                    const url = `${process.env.FUNCTION_URL}/send-email/new-gnb/${bundle}?${userParams}&${friendParameters}`
                    const response = await fetch(
                        url, {
                        method: 'GET',
                    });
                    await response.json();
                }
            })
    }, [UserDataService.get('uid')]);

    const checkState = () => {
        const query = new URLSearchParams(window.location.search);
        if (query.get('success')) {
            return 4;
        } else if (query.get('canceled')) {
            // Something went wrong with payment. Send it back to step and show error message.
            return 3;
        } else {
            return 0;
        }
    }


    const [hasError, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState(checkState());
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [userPassword, setPassword] = useState('');
    const [keepInformed, setKeepInformed] = useState(false);

    const [emailError, setEmailError] = useState(false);
    const [emailTextError, setEmailTextError] = useState('This is not a valid email.');
    const [passwordError, setPasswordError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [somethingChanged, setSomethingChanged] = useState(false);

    const [userNameFriend, setUserNameFriend] = useState('');
    const [emailFriend, setEmailFriend] = useState('');

    const [emailFriendError, setEmailFriendError] = useState(false);
    const [usernameFriendError, setUsernameFriendError] = useState(false);
    const [somethingChanged2, setSomethingChanged2] = useState(false);

    const query = new URLSearchParams(window.location.search);
    const isTeam = query.get('teams') || query.get('team');

    const [teamsVerify, setTeamsVerify] = useState(isTeam);

    const checkIfRegistered = () => {
        const isAnon = UserDataService.get('isAnon');
        const uid = UserDataService.get('uid');

        if (uid && uid !== '' && isAnon && isAnon === 'false') {
            setStep(2);
        } else {
            setStep(1);
        }
    }

    const goToHome = () => {
        history.push('/account');
    }

    const goToTerms = (e: FormEvent) => {
        e.preventDefault();
        history.push('/termsandconditions');
    }

    const registerNewUser = async (e) => {
        e.preventDefault();

        checkEmailError(email);
        checkPasswordError(userPassword);
        checkUsernameError(userName);

        if (somethingChanged) {
            if (!usernameError && !emailError && !passwordError) {
                const authenticated = await SignUpWithEmailAndPassword(userName, email, userPassword, keepInformed);

                if (authenticated) {
                    // User resgitered, go to next step
                    setStep(2);
                } else {
                    // Error on authenticating user, show error message
                    setEmailTextError('Email already registered.');
                    setEmailError(true);
                }
            }
        }
    }

    const googleInBundle = async () => {
        const authenticated = await SignInWithGoogle(keepInformed);

        if (authenticated) {
            // User resgitered, go to next step
            setStep(2);
        } else {
            // Error on authenticating user, show error message
            setEmailTextError('Account already registered.');
            setEmailError(true);
        }
    }

    const facebookInBundle = async () => {
        const authenticated = await SignInWithFacebook(keepInformed);

        if (authenticated) {
            // User resgitered, go to next step
            setStep(2);
        } else {
            // Error on authenticating user, show error message
            setEmailTextError('Account already registered.');
            setEmailError(true);
        }
    }

    const saveFriendsInfo = async (e) => {
        if(e == null) {
            const uid = UserDataService.get('uid');
            const dbRef = database.ref(`/users/${uid}`);
            await dbRef.update({
                luckyFriend: null
            });
            return;
        }
        e.preventDefault();
        checkFriendsEmail(emailFriend);
        checkFriendsName(userNameFriend);

        if (somethingChanged2) {
            if (!usernameFriendError && !emailFriendError) {
                try {
                    const uid = UserDataService.get('uid');

                    // Save Lucky Friend info
                    const dbRef = database.ref(`/users/${uid}`);
                    await dbRef.update({
                        luckyFriend: {
                            userNameFriend,
                            emailFriend
                        }
                    });

                    setStep(3);
                    analyticsService.viewCart(
                        createItemInfo(null, (teamsVerify ? "Team" : "Game Night") + 'Bundle')
                    );

                } catch (err) {
                    // Error on adding the Lucky Friend, show error message
                    await LogService.log(e, 'Error: Cannot add Lucky Friend.')
                    return;
                }
            }
        }
    }

    const handlePayment = async (e) => {
        e.preventDefault();
        const code = await CouponService.GenerateUnclaimedCode();

        if (code) {
            const type = teamsVerify ? 'team' : 'gnb';
            await GetSessionAndRedirectToCheckout(code || '', type, 1200);
        }
    };

    const checkUsernameError = (value: string) => {
        setSomethingChanged(true);
        setUserName(value);
        if (value.length === 0 || value === '') {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
    }

    const checkFriendsName = (value: string) => {
        setSomethingChanged2(true);
        setUserNameFriend(value);
        if (value.length === 0 || value === '') {
            setUsernameFriendError(true);
        } else {
            setUsernameFriendError(false);
        }
    }

    const checkEmailError = (emailValue: string) => {
        setSomethingChanged(true);
        setEmail(emailValue);
        if (!email) {
            setEmailTextError('This is not a valid email.');
            setEmailError(true);
        }

        if (typeof email !== 'undefined') {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                setEmailTextError('This is not a valid email.');
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        }
    }

    const checkFriendsEmail = (value: string) => {
        setSomethingChanged2(true);
        setEmailFriend(value);
        if (!emailFriend) {
            setEmailFriendError(true);
        }

        if (typeof emailFriend !== 'undefined') {
            let lastAtPos = emailFriend.lastIndexOf('@');
            let lastDotPos = emailFriend.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && emailFriend.indexOf('@@') == -1 && lastDotPos > 2 && (emailFriend.length - lastDotPos) > 2)) {
                setEmailFriendError(true);
            } else {
                setEmailFriendError(false);
            }
        }
    }

    const checkPasswordError = (passwd: string) => {
        setSomethingChanged(true);
        setPassword(passwd);
        const passwordArray = passwd.split('');
        let condition1 = false;
        let condition2 = false;
        let condition3 = false;
        let condition4 = passwd.length > 5;
        for (const password of passwordArray) {
            // Have upper case letter
            if ((password === password.toUpperCase()) &&
                (isNaN(parseInt(password, 10) * 1))) {
                condition1 = true;
            }
            // Have lower case letter
            if ((password === password.toLowerCase()) &&
                (isNaN(parseInt(password, 10) * 1))) {
                condition2 = true;
            }
            // Have one number
            if (!isNaN(parseInt(password, 10) * 1)) {
                condition3 = true;
            }
        }

        if (!condition1 || !condition2 || !condition3 || !condition4) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
    }

    return (
        <div className="game-night-steps game-night-steps-modal-landing">
            <Helmet>
                <title> {teamsVerify ? "TEAM BUNDLE" : "GAME NIGHT BUNDLE"} | VEN Games </title>
            </Helmet>
            <div className="main-header-container">
                <MainHeader />
            </div>
            <div className="game-night-steps-modal-content">
                <div className="close-mobile close-button" onClick={() => history.goBack()}>
                    <img src={closeWhite} alt="close" />
                </div>
                <div className="game-night-steps-modal-content2">
                    <div className={`title-banner ${step === 4 ? 'hide-titles' : ''}`}>
                        <header>
                            <img src={corner1Img} alt="" />
                            <div className="banner">
                                <p className={`${step === 0 ? '' : 'hide-step'}`}>UNLIMITED PLAYERS</p>
                                <p className={`${step > 0 ? '' : 'hide-step'}`}>{teamsVerify ? "TEAM BUNDLE" : "GAME NIGHT"}</p>
                            </div>
                            <img src={corner2Img} alt="Close" />
                        </header>
                        <div className={`${step > 0 ? 'hide-benefits' : ''} `}>
                            <div className="title">
                                <p className="price">
                                    $12 USD <br />
                                SPECIAL LAUNCH PRICE <br />
                                    <strong>2 FOR 1!</strong>
                                </p>
                                <p className="main-title">
                                    {teamsVerify ? "TEAM" : "GAME"}<br />
                                    {teamsVerify ? "" : "NIGHT"}{!teamsVerify && <br />}
                                BUNDLE
                            </p>
                                <img src={peopleImg} alt="Yay!" />
                            </div>
                        </div>
                    </div>
                    <div className={`step-container ${step === 4 ? 'step-container-four' : ''}`}>
                        <div className="close-button close-desktop" onClick={() => history.goBack()}>
                            <img src={closeBlack} alt="close" />
                        </div>
                        <div className={`step-zero ${step === 0 ? '' : 'hide-step'} step`}>
                            <div className="step-zero-content step-content">
                                <div className="description">
                                    <p>
                                        What you get...
                                </p>
                                    <p>
                                        Play Games with an <strong>unlimited number of {teamsVerify ? "teammates" : "friends"}!</strong> <br />
                                        <br />
                                    {/* Get early, <strong>exclusive access</strong> to new games. <br /> */}
                                    {/* Play them weeks before anyone else. <br /> */}
                                        {/* <br /> */}
                                    Unlock <strong>all premium extensions</strong> and word packs. <br />
                                        <br />
                                    2 for 1 — <strong>Give a {teamsVerify ? "Team Bundle" : "Games Night"} as a gift for FREE!</strong> <br />
                                        <br />
                                    Have a say in what games we create!
                                </p>
                                </div>
                                <footer>
                                    <button onClick={checkIfRegistered}>
                                        Get the 2 for 1 Bundle!
                                    </button>
                                    <img src={peopleImg} alt="Yay!" />
                                </footer>
                            </div>
                        </div>
                        <div className={`step-one ${step === 1 ? '' : 'hide-step'} step`}>
                            <div className="step-one-content step-content">
                                <div className="description">
                                    <p>
                                        STEP 1 OF 3
                                </p>
                                    <p>
                                        Just a few steps to go! Let's get you set up.
                                </p>
                                    <p>
                                        We’ll email you an activation link. Once you activate it, you’ll have 48 hours to play!
                                </p>
                                </div>
                                <form>
                                    <div className={`input-box ${usernameError ? 'error-input' : ''}`}>
                                        <img className="input-icon" src={usernameIcon} alt="" />
                                        <input
                                            type="text"
                                            name="username"
                                            maxLength={30}
                                            placeholder="Username"
                                            id="username"
                                            autoComplete="off"
                                            onBlur={(e) => checkUsernameError(e.target.value)}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                        <div></div>
                                    </div>
                                    {usernameError &&
                                        <p className="error">You must have a username.</p>
                                    }
                                    <div className={`input-box ${emailError ? 'error-input' : ''}`}>
                                        <img className="input-icon" src={emailIcon} alt="" />
                                        <input
                                            type="email"
                                            name="userEmail"
                                            placeholder="E-mail"
                                            id="userEmail"
                                            autoComplete="off"
                                            onBlur={(e) => checkEmailError(e.target.value)}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <div></div>
                                    </div>
                                    {emailError &&
                                        <p className="error">{emailTextError}</p>
                                    }
                                    <div className={`input-box ${passwordError ? 'error-input' : ''}`}>
                                        <img className="input-icon" src={passwordIcon} alt="" />
                                        <input
                                            type="password"
                                            name="userPassword"
                                            placeholder="Password"
                                            id="userPassword"
                                            autoComplete="off"
                                            onBlur={(e) => checkPasswordError(e.target.value)}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div></div>
                                    </div>
                                    {passwordError &&
                                        <p className="error">Password needs to be at least 6 characters in length, must include at least one lower case letter, one upper case letter and one number.</p>
                                    }
                                    <div className="check-box">
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="sendUpdates"
                                                id="sendUpdates"
                                                onChange={(e) => setKeepInformed(Boolean(e.target.value))}
                                            />
                                            <div className="one-space">{' '}</div>
                                        </div>
                                        <p>Send me updates when you have new games and content!</p>
                                    </div>
                                    <p className="terms">By signing up, I agree to the {" "}
                                        <span onClick={(e) => goToTerms(e)}>
                                            Terms of Service and Privacy Policy
                                        </span>
                                        , and I affirm I am at least 13 years old.</p>
                                </form>
                                <footer>
                                    <button onClick={(e) => registerNewUser(e)}>
                                        Continue
                                    </button>
                                </footer>
                                <div className="or-container">
                                    <div>
                                        <img className="line" src={line} alt="" />
                                    </div>
                                    <div>
                                        <p className="or-text">OR</p>
                                    </div>
                                    <div>
                                        <img className="line" src={line} alt="" />
                                    </div>
                                </div>
                                <div className="button-container">
                                    {/* <button onClick={facebookInBundle}><img src={facebookButton} alt="Continue with Facebook" /></button> */}
                                    <button onClick={googleInBundle}><img src={googleButton} alt="Sign in with Google" /></button>
                                </div>
                            </div>
                        </div>
                        <div className={`step-two ${step === 2 ? '' : 'hide-step'} step`}>
                            <div className="step-two-content step-content">
                                <div className="description">
                                    <p>
                                        STEP 2 OF 3
                                </p>
                                    <p>
                                        Who’s the lucky person getting your second {teamsVerify ? "Team Bundle" : "Game Night"} Pass?
                                </p>
                                    <p>
                                        We’ll email them their activation link so they can throw a virtual party too!
                                </p>
                                </div>
                                <form>
                                    <div className={`input-box ${usernameFriendError ? 'error-input' : ''}`}>
                                        <img className="input-icon" src={usernameIcon} alt="" />
                                        <input
                                            type="text"
                                            name="usernameFriend"
                                            placeholder="Name"
                                            id="usernameFriend"
                                            autoComplete="off"
                                            onBlur={(e) => checkFriendsName(e.target.value)}
                                            onChange={(e) => setUserNameFriend(e.target.value)}
                                        />
                                        <div></div>
                                    </div>
                                    {usernameFriendError &&
                                        <p className="error">Friend's name cannot be empty.</p>
                                    }
                                    <div className={`input-box ${emailFriendError ? 'error-input' : ''}`}>
                                        <img className="input-icon" src={emailIcon} alt="" />
                                        <input
                                            type="emailFriend"
                                            name="userEmailFriend"
                                            placeholder="Friend's E-mail"
                                            id="userEmailFriend"
                                            autoComplete="off"
                                            onBlur={(e) => checkFriendsEmail(e.target.value)}
                                            onChange={(e) => setEmailFriend(e.target.value)}
                                        />
                                        <div></div>
                                    </div>
                                    {emailFriendError &&
                                        <p className="error">This is not a valid email.</p>
                                    }
                                </form>
                                <footer>
                                    <button onClick={(e) => saveFriendsInfo(e)}>
                                        Continue
                                    </button>
                                    <a onClick={() => { saveFriendsInfo(null); setStep(step+1); }}>skip</a>
                                </footer>
                            </div>
                        </div>
                        <div className={`step-three ${step === 3 ? '' : 'hide-step'} step`}>
                            <div className="step-three-content step-content">
                                <div className="description">
                                    <p>
                                        STEP 3 OF 3
                                </p>
                                    <p>
                                        Confirm payment.
                                </p>
                                    <p>
                                        You’ll be billed a one time fee of $12 USD and sent an activation link via email.
                                </p>
                                </div>
                                <footer>
                                    <div className="price-info">
                                        {emailFriend ? <div className="price">
                                            <p>
                                            FriendEmail:
                                        </p>
                                            <p>
                                            {emailFriend}
                                        </p>
                                        </div> : null }
                                        <div className="price">
                                            <p>
                                                Special 2FOR1 {teamsVerify ? "Team" : "Game Night"} Bundle
                                        </p>
                                            <p>
                                                $12
                                        </p>
                                        </div>
                                        <div className="total">
                                            <p>
                                                Total:
                                        </p>
                                            <p>
                                                $12 USD
                                        </p>
                                        </div>
                                    </div>
                                    <button onClick={(e) => handlePayment(e)}>
                                        Checkout
                                    </button>
                                    <a onClick={() => setStep(step-1)}>back</a>
                                </footer>
                            </div>
                        </div>
                        <div className={`step-four ${step === 4 ? '' : 'hide-step'} step`}>
                            <div className="step-four-content step-content">
                                {loading ? <>
                                    <h1>All right! Almost there!</h1>
                                    <span>Wrapping up your purchase...</span>
                                </>
                                    :
                                    hasError ? <>
                                        <h1>Oh no! Something went wrong!</h1>
                                        <h2>{hasError}</h2>
                                        <span>Try again or contact VEN Games about the issue</span>
                                    </> :
                                        <>
                                            <div className="description">
                                                <img src={confirmationImg} alt="" />
                                                <p>
                                                    {' '}
                                                </p>
                                                <p>
                                                    Congratulations on getting the {teamsVerify ? "Team" : "Game Night"} Bundle. Enjoy!
                                                </p>
                                                <p className="text-one">
                                                    Thank you for supporting VEN. Have fun and enjoy!
                                                </p>
                                                <p className="text-two">
                                                    We’ve sent you an email with your receipt and activation link.
                                                </p>
                                            </div>
                                            <footer>
                                                <button onClick={goToHome}>
                                                    Go to Account page
                                                </button>
                                            </footer>
                                        </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default GameNightSteps;
