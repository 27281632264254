import firebase, { auth } from '../../../shared/core/data/firebase';

export const analyticsService = new class AnalyticsService {

    analytics;

    constructor() {
        this.analytics = firebase.analytics();
    }
    public GameCreated(
        // uid: string,
        gameName: string,
        gameRoom: string
    ) {
        const data = {
            // uid,
            gameName,
            gameRoom,
        };

        this.logEvent('game_created', data);
    }

    public GameStarted(
        // uid: string,
        gameName: string,
        gameRoom: string,
        players: number,
    ) {
        const data = {
            // uid,
            gameName,
            gameRoom,
            players,
        };

        this.logEvent('game_started', data);
    }

    public GameCompleted(
        // uid: string,
        gameName: string,
        gameRoom: string
    ) {
        const data = {
            // uid,
            gameName,
            gameRoom,
        };

        this.logEvent('game_completed', data);
    }

    public PlayAgainPressed(
        // uid: string,
        // gameName: string,
        // gameRoom: string
    ) {
        const data = {
            // uid,
            // gameName,
            // gameRoom,
        };
        this.logEvent('play_again_pressed', data);
    }

    public beginCheckout(data) {
        this.logEvent('begin_checkout', data);
    }

    public purchase(data) {
        this.logEvent('purchase', data);
    }

    public viewCart(data) {
        this.logEvent('view_cart', data);
    }

    public Login(
        uid: string,
        method: string,
        username?: string,
    ) {
        const data = {
            uid,
            username,
            method,
            screen,
        };

        try {
            this.analytics.setUserId(uid);
            this.analytics.setUserProperties(data);
            this.logEvent('login', {
                method: method
            });

        } catch (err) {
            console.log(err);

        }
    }

    public Signup(
        uid: string,
        method: string,
        username?: string,
    ) {
        const data = {
            uid,
            username,
            method,
            screen,
        };

        console.log('Signup into analytics.');

        console.log(data);

        try {
            this.analytics.setUserId(uid);
            this.analytics.setUserProperties(data);
            this.logEvent('sign_up', {
                method
            });
            console.log('Done.');

        } catch (err) {
            console.log(err);

        }
    }

    public logEvent(
        event: string,
        data?: object,
        uid?: string,
        screen?: string
    ) {
        this.analytics.setUserId(uid ? uid : auth.currentUser?.uid);
        this.setCurrentScreen(screen)
        this.analytics.logEvent(event, data);
    }

    public setCurrentScreen(
        screen?: string
    ) {
        const title = document.title.replace(/ ?[-|] ?VEN Games ?[-|] ?/, '');
        if(!screen)
            screen = title;

        if(screen == ' VEN Games - Play awesome games with friends, family and teams. Anytime, anywhere. ')
            screen = 'Landing Page'
        
        this.analytics.setCurrentScreen(screen.replace(/ [-|] VEN Games/, ''));
    }
}