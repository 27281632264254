import { database } from "@ven/core/data/firebase";
import { UserDataService } from '../../services/user/UserDataService'

export const LogService = {
    async log(err: any, message: string, endpoint: string = "/logGlobal") {
        // Update user in Firebase errorLog collection
        // The format of the record is:
        // ISODate: {
        //     message: string,
        //     error: string (stack trace),
        //     user: uid
        // }
        const id = new Date().toISOString();
        const dbRef = database.ref(endpoint);
        const newUserRef = dbRef.child(id.split('.')[0]);

        const errorInfo:any = {};
        errorInfo.error = err as any
        errorInfo.userAgent = navigator.userAgent;
        errorInfo.screenInfo = {
            availHeight: screen.availHeight,
            availWidth: screen.availWidth,
            availTop: (screen as any).availTop,
            availLeft: (screen as any).availLeft,
            // colorDepth : screen.colorDepth,
            height: screen.height,
            width: screen.width,
            isExtended: (screen as any).isExtended,
            orientation: screen.orientation.type,
            orientationAngle: screen.orientation.angle,
            pixelDepth : screen.pixelDepth
        }

        const newData = {
            message,
            error: JSON.stringify(err),
            user: UserDataService.get('uid') || null,
        };

        await newUserRef.set(newData);
    },

    async info(message: string) {
        // Update user in Firebase errorLog collection
        // The format of the record is:
        // ISODate: {
        //     message: string,
        //     error: string (stack trace),
        //     user: uid
        // }
        const id = new Date().toISOString();
        const dbRef = database.ref('/logGlobal');
        const newUserRef = dbRef.child(id.split('.')[0]);

        const newData = {
            message,
            error: "",
            user: UserDataService.get('uid') || null,
        };

        await newUserRef.set(newData);
    },

    async RegisterGlobalErrorListener() {
        window.addEventListener("error", e => {
            if(e?.error?.code?.includes('auth/'))
                return;

            if(e?.error?.message?.startsWith('Error: Could not create anonymous user.'))
                return;

            const error = e as any
            LogService.log(error, "RegisterGlobalErrorListener", "errorLogGlobal")
          });
    },

    // export class LoggingFeature<T extends GameStateController<GameSessionData&WithLogging>> {
    //     constructor ( private readonly game:T ) {
    //       window.addEventListener("error", e => {
    //         this.error(e as any);
    //       });
    //     }
      
    //     public trace(msg:string) {
    //       // console.trace(`TRACE: ${ msg }`, )
    //       console.log(`TRACE: ${ msg }`, )
    //       this.addLogEntry( `[TRACE] ` + msg, 'info' );
    //     }
      
    //     public error(error:string|Error) {
    //       console.error(`ERROR: ${ error }`)
    //       this.addLogEntry( 
    //         `[ERROR] ` + error, 'error', 
    //         error instanceof Error && error.stack
    //       );
    //     }
      
    //     private addLogEntry(msg:string, severity:LogEntry['severity'], stack?:any ) {
    //       try {
    //         const data:LogEntry = {
    //           msg: msg,
    //           author: this?.game?.getMyState()?.username || 'unnamed',
    //           hostLastTick: this.game.data.players[this.game.data.host!].lastOnline ?? 'unknown',
    //           time: firebase.database.ServerValue.TIMESTAMP,
    //           severity,
    //           ...stack && { stack },
    //         }
    //         this.game.ref.room().child(`debug/${ this.game.myUserId }/logs`).push( data );
    //       } catch( e ) {
    //         console.error( e )
    //       }
    //     }
}
LogService.RegisterGlobalErrorListener();
