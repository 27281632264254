import React from 'react';

import type { UserProperties } from './UserProperties';
import * as AppAuth from '@ven/platform/main/services/AuthenticationService';
import { UserDataService } from './UserDataService';

import { auth, database } from '@ven/core/data/firebase';
import { GameFunctionsService } from '@ven/shared/core/services/GameFunctionsService';
import { VipCodeService } from '@ven/shared/core/services/VipCodeService';
import { useUserOnlineSiteStatus } from '@ven/shared/components/hooks/useUserOnlineSiteStatus';
import { LoadingView } from '@ven/shared/components/common/organizms/Loading';

//// Context

type ContextProps = Partial<UserProperties> & { setUid: (v: string) => any, dataLoaded: boolean, isVip: boolean }
export const UserDataContext = React.createContext<ContextProps>({ setUid: console.warn, dataLoaded: false, isVip: false });

//// Provider

export const UserDataProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  
  // if for some reason the user has outdate localstorage info
  // ensure delete it and signout
  if(localStorage.getItem('uid')) {
    auth.signOut().then(() => {
      UserDataService.clear('uid');
      window.location.replace('/')
    });
    return (<LoadingView/>)
  }
  UserDataService.clear('username');
  UserDataService.clear('isAnon');
  UserDataService.clear('uid');
  UserDataService.clear('userEmail');
  UserDataService.clear('provider');

  const [uid, setUid] = React.useState<string>(auth.currentUser?.uid || '');
  const [data, setData] = React.useState<UserProperties>();
  const [lastUid, setLastUid] = React.useState<string | undefined>(undefined);
  
  const [vipToken, setVipToken] = React.useState<string | null | undefined>(UserDataService.get("vip"));
  const [vipTokenChecked, setVipTokenChecked] = React.useState(false);
  const [isVip, setIsVip] = React.useState(false);

  const [alreadyRequestAnon, setAlreadyRequestAnon] = React.useState(false);
  React.useEffect(() => {
    const unsub = auth.onAuthStateChanged(async user => {
      const newUid = user?.uid;
      if (newUid && newUid != lastUid)
        setLastUid(newUid);

      // setUid(newUid);
      console.log(`onAuthStateChanged: uid = ${newUid} isAno = ${user?.isAnonymous}`)
      checkUser(user);
    })
    return unsub;
  }, [])

  React.useEffect(() => {
    if (!!uid) {
      const onDataChange = (a: any) => setData({ ...a.val() })
      const ref = database.ref(`/users/${uid}`)
      ref.on("value", onDataChange)
      return () => ref.off("value", onDataChange)
    }
    return
  }, [uid])//, !! data?.username ] )

  React.useEffect(() => {
    if (!uid) {
      return;
    }

    const previousUid = UserDataService.get('previousUid');
    const oldId = previousUid || lastUid;
    UserDataService.set('previousUid', '')

    if (oldId && uid && uid != oldId) {
      GameFunctionsService.updateUserId(uid, oldId || '')
    }
  }, [data])//, !! data?.username ] )

  React.useEffect(() => {
    if(vipToken)
      checkVip(vipToken)
  }, [vipToken])//, !! data?.username ] )

  const checkUser = async (user) => {
    if (user) {
      setAlreadyRequestAnon(false);
      setUid(user.uid)
      return;
    }

    if (!alreadyRequestAnon) {
      console.log(`No uid, will auth anonymously`)
      await AppAuth.CreateAnonId();
      setAlreadyRequestAnon(true);
      return;
    }

    console.log(`waiting for anon data`)
    return;
  }

  const checkVip = async (token) => {
    try {
      const valid = await VipCodeService.check(token);
      setIsVip(valid);
      setVipTokenChecked(true);
  
      console.log(`vip is valid:` + valid)
      if(!valid) {
        UserDataService.clear("vip");
      }
    } catch (error) {
      setIsVip(false);
      setVipTokenChecked(true);
    }
  }

  useUserOnlineSiteStatus();

  return (
    <>
      {
        <UserDataContext.Provider value={{ avatar: '0', ...data, setUid, dataLoaded: !!data, isVip }} >
          { !data ? <LoadingView/> : 
            (!vipToken || (vipToken && vipTokenChecked) ? children : <LoadingView/>) }
        </UserDataContext.Provider>
      }
    </>
  )
}
