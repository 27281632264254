import { COLOR_PRIMARY } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';

export const CircularButton = styled.button`
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: transform 200ms, background-color 200ms;

  svg {
    stroke: #3148BF;
    stroke-width: 2px;
    transition: stroke 200ms;
  }

  &:hover {
    background: ${ COLOR_PRIMARY };
    transform: scale(1.2);
    svg {
      stroke: white;
    }
  }

  &:active {
    transform: scale(.8);
    outline: none;
  }

  &:focus {
    outline: none;
  }
`
