import React from 'react';

/**
 * The existence of these exports is a dirty but effective solution to embed the
 * vector images into the code of the app and eliminate their loading time entirely.
 */

const DEFAULT_COLOR = "#black"

export const StarIcon = ({ color = "#A19AC6" }) => (
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.5 23.25L7.5649 27.4217L9.08037 18.5859L2.66074 12.3283L11.5324 11.0391L15.5 3L19.4676 11.0391L28.3393 12.3283L21.9196 18.5859L23.4351 27.4217L15.5 23.25Z" stroke={ color } strokeWidth="2"/>
  </svg>
)

export const BrushSizeIcon = ({ color = DEFAULT_COLOR, thickness = 2 }) => (
  <svg width="52" height="52" viewBox="-9 -9 52 52" 
  fill="none" strokeLinecap="round" stroke={ color } strokeWidth={ thickness } 
  xmlns="http://www.w3.org/2000/svg">
    <path d="M1 12.9756C1 12.9756 11.3927 -0.0583677 13.5996 2.78486C15.8064 5.62808 -0.537821 17.9106 3.04053 18.4438C6.61888 18.9769 26.1329 0.88953 28.1914 1.00051C30.25 1.11149 2.43892 26.5968 2.9982 28.0665C3.4907 29.3606 22.4578 14.2388 26.9803 10.6091C27.4785 10.2093 27.9098 10.4398 27.667 11.0306C26.9338 12.8147 24.419 17.098 16.0277 26.9973C3.13797 42.2035 28.3355 19.2568 28.3355 19.2568" 
    />
  </svg>
)

export const DrawToolIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="52" height="52" viewBox="-10 -12 52 58" 
  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
  fill="none" stroke={ color }
  xmlns="http://www.w3.org/2000/svg">
    <path d="M30.3648 2.90537C29.4397 1.9849 28.1854 1.4721 26.8804 1.48096C25.5755 1.48982 24.3282 2.01959 23.4157 2.95253L3.92413 22.4387L1.47168 31.7904L10.826 29.3393L30.3176 9.85308C31.2511 8.94122 31.7813 7.69399 31.7902 6.38906C31.7991 5.08412 31.2858 3.82981 30.3648 2.90537V2.90537Z"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M22.8579 3.51039L29.7598 10.4096"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.043 6.32397L26.9449 13.2232"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.92407 22.4387L8.7616 24.5031L10.8314 29.3325"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.4939 9.77357L8.76172 24.5017"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.47168 31.8H28.8234"  strokeLinecap="round"/>
  </svg>
)

export const UndoIcon = ({ color = DEFAULT_COLOR, size = 52 }) => (
  <svg width={size} height={size} viewBox="-9 -9 52 52" 
  fill="none" stroke={ color }
  xmlns="http://www.w3.org/2000/svg">
    <path d="M8.29071 13.9006L2 7.60986L8.24293 1.53287"  strokeWidth="2" strokeLinecap="round"/>
    <path d="M8.78569 29.9936L14.4742 28.9997C27.0055 26.8104 25.7344 8.43241 13.021 7.98914L2.85669 7.63476" 
     strokeWidth="2" 
    strokeLinecap="round"/>
  </svg>
)

export const BucketIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="52px" height="52px" viewBox="-12 -5 52 52" 
  fill="none" stroke={ color } strokeLinecap="round" strokeWidth="2"
  xmlns="http://www.w3.org/2000/svg">
    <path d="M1 19.358l.81-4.917C3.952 1.43 22.654 1.41 24.823 14.416l.824 4.942"/>
    <path d="M3.677 16.015V33.98a23.032 23.032 0 0018.51.262l.622-.262V16.015v0a30.354 30.354 0 00-19.132 0v0z"/>
    <path fill={ color } strokeWidth=".729" d="M3.928 17.624a33.464 33.464 0 0018.161.07l.255-.07c0 1.293-.547 2.516-1.345 3.534-.754.962-1.582 2.255-1.78 3.483-.385 2.376 1.52 3.805 1.52 4.94 0 1.136-.472 2.112-1.52 2.112-1.05 0-1.888-.269-2.389-2.112-.501-1.842.705-3.293.705-4.075 0-.802-1.057-.675-1.517-.018-.463.662-1.075 1.242-1.705.998-1.298-.503-.46-3.52-1.872-4.188-1.412-.668-2.414 2.036-4.431 1.208-2.017-.828-1.587-3.118-2.012-3.618-.426-.5-2.07-1.18-2.07-1.18v-1.084z"/>
    <path d="M1.214 18.472h2.48M23.084 18.472h2.48"/>
  </svg>
)

export const TrashIcon = ({ color = DEFAULT_COLOR }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="52px" height="52px" viewBox="-200 -200 1424 1424"  fill={ color }>
    <path d="M704 128H448c0 0 0-24.057 0-32 0-17.673-14.327-32-32-32s-32 14.327-32 32c0 17.673 0 32 0 32H128c-35.346 0-64 28.654-64 64v64c0 35.346 28.654 64 64 64v576c0 35.346 28.654 64 64 64h448c35.346 0 64-28.654 64-64V320c35.346 0 64-28.654 64-64v-64C768 156.654 739.346 128 704 128zM640 864c0 17.673-14.327 32-32 32H224c-17.673 0-32-14.327-32-32V320h64v480c0 17.673 14.327 32 32 32s32-14.327 32-32l0.387-480H384v480c0 17.673 14.327 32 32 32s32-14.327 32-32l0.387-480h64L512 800c0 17.673 14.327 32 32 32s32-14.327 32-32V320h64V864zM704 240c0 8.837-7.163 16-16 16H144c-8.837 0-16-7.163-16-16v-32c0-8.837 7.163-16 16-16h544c8.837 0 16 7.163 16 16V240z" fill={ color }/>
  </svg>
)

export const EraserIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="52px" height="52px" viewBox="0 -16 52 72" 
  preserveAspectRatio="xMidYMid meet" fill={ color }
  version="1.0" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9704 40.4444 l-6.9093 -0.1204 -4.0444 -3.9722 c-2.2148 -2.1907 -4.863 -4.7907 -5.8981 -5.8019 -1.0352 -1.0352 -1.8778 -2.0463 -1.8778 -2.3111 0 -0.4093 20.9685 -21.3537 26.5296 -26.5056 l1.8778 -1.7333 4.2611 0 4.2611 0 7.3185 7.2944 c6.5241 6.5241 7.3185 7.3907 7.15 7.8963 -0.1685 0.6741 -24.2426 24.6519 -25.1574 25.0852 -0.4333 0.2167 -2.4315 0.2648 -7.5111 0.1685z m2.0463 -6.1148 c1.0352 -0.987 1.8537 -1.9019 1.8537 -2.0222 0 -0.313 -10.1352 -10.1593 -10.4481 -10.1593 -0.1444 0 -1.637 1.3722 -3.3222 3.0574 l-3.0815 3.0574 4.0444 3.9241 4.0204 3.9 2.5519 0 2.5519 0.0241 1.8296 -1.7815z m9.7981 -9.6537 l5.1519 -5.1519 -0.6981 -0.7463 c-0.3852 -0.3852 -0.7944 -0.7222 -0.9389 -0.7222 -0.1444 0 -2.5519 2.287 -5.3685 5.1037 l-5.1278 5.1037 0.7704 0.7944 c0.4333 0.4333 0.8185 0.7944 0.9148 0.7944 0.0722 0 2.4556 -2.3352 5.2963 -5.1759z m-3.8519 -4.3093 l4.9352 -4.9593 -1.1315 -1.1315 -1.1074 -1.1074 -5.2 5.0074 c-2.8648 2.7685 -5.2 5.1278 -5.2 5.2722 0 0.1444 0.4574 0.6981 1.0111 1.2759 0.7463 0.7463 1.0833 0.963 1.3722 0.7944 0.2167 -0.1204 2.6 -2.4315 5.3204 -5.1519z m-5.2 -4.4056 c2.7926 -2.7685 5.1037 -5.0796 5.1519 -5.1759 0.0481 -0.0963 -0.2648 -0.5056 -0.6741 -0.9148 l-0.7463 -0.7222 -5.1519 4.9833 c-2.8407 2.7685 -5.1759 5.1759 -5.1759 5.4167 0 0.313 1.0593 1.3722 1.4204 1.3963 0.0481 0 2.3833 -2.2389 5.1759 -4.9833z m17.5259 0.2407 l0.6981 -0.65 -5.1278 -5.1278 c-2.8167 -2.8167 -5.2241 -5.1278 -5.3444 -5.1278 -0.337 0 -1.3481 0.987 -1.3481 1.3241 0 0.313 9.9185 10.2315 10.2315 10.2315 0.1204 0 0.5296 -0.2889 0.8907 -0.65z m9.1 -0.6741 c0.337 -0.3852 0.2407 -0.4333 -0.337 -0.2167 -0.2167 0.0722 -0.3852 0.2407 -0.3852 0.3611 0 0.337 0.3852 0.2648 0.7222 -0.1444z m-8.5463 -6.6204 c-4.213 -4.237 -4.6222 -4.5741 -5.4167 -4.5741 -0.8426 0.0241 -0.7944 0.0722 3.8037 4.5741 4.0685 3.9963 4.7426 4.5741 5.4167 4.5741 0.7222 0 0.5296 -0.2407 -3.8037 -4.5741z"/>
  </svg>
)

export const TinyPenIcon = () => (
  <svg width="25" height="25" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.09023 12.1274L1.23632 15.1606C1.13165 15.5324 1.47009 15.8774 1.84382 15.7799L4.58031 15.066L15.2904 4.34208C15.6819 3.95017 15.6801 3.31477 15.2866 2.92498L14.0465 1.6965C13.657 1.31067 13.0294 1.3104 12.6396 1.69588L2.09023 12.1274Z" stroke="#A19AC6" strokeWidth="2"/>
    <path d="M11 4L13 6" stroke="#A19AC6" strokeWidth="2"/>
    <path d="M13 16H2" stroke="#A19AC6" strokeWidth="2" strokeLinecap="round"/>
  </svg>
)

export const TinyTickIcon = () => (
  <svg width="15" height="14" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5.33152L4.75706 10L12 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
  </svg>
)

////

export const SendIcon = ({ color = DEFAULT_COLOR }) => (
  // <svg xmlns="http://www.w3.org/2000/svg"  fill={ color }
  //   x="0px" y="0px" width="64px" height="64px" viewBox="0 0 535.5 535.5">
  //     <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75"/>
  // </svg>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 -100 1150 1000" enableBackground="new 0 0 1000 1000" width="64px" height="64px">
    <g><path fill={ color } d="M10,387.9l396.1,129.2l251-171.8l-172.4,252l122.2,394.4L990,8.2L10,387.9z"/></g>
  </svg>
)

////

//// "#3148BF" by design
export const EnableFullScreenIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
  stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M7 21L1 27"/>
    <path d="M27 9V1H19"/>
    <path d="M1 19V27H9"/>
    <path d="M27 1L21 7"/>
    <path d="M21 21L27 27"/>
    <path d="M1 9V1H9"/>
    <path d="M27 19V27H19"/>
    <path d="M1 1L7 7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 10H18V18H10V10Z"/>
  </svg>
)

export const VolumeOnIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg"
   stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.56816 7.34719H1.82353C1.36871 7.34719 1 7.75605 1 8.26041V13.7397C1 14.2441 1.36871 14.6529 1.82353 14.6529H6.56816C7.80519 18.1653 10.7111 20.6143 14.0941 20.9955C14.3256 21.021 14.5559 20.9368 14.7285 20.7638C14.901 20.5908 14.9996 20.3451 15 20.0872V1.91293C14.9999 1.65489 14.9014 1.40893 14.7288 1.23586C14.5562 1.0628 14.3256 0.978778 14.0941 1.00458C10.7111 1.38579 7.80519 3.8348 6.56816 7.34719Z"/>
    <path d="M24 16C25.3333 13.256 25.3333 9.74402 24 7"/>
    <path d="M21.1098 14C22.1504 12.5431 22.1504 10.4569 21.1098 9"/>
</svg>
)

export const VolumeOffIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg"
   stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M10 15.1674L14.0443 17.6444C14.6041 18.0485 15.353 18.1134 15.9788 17.8123C16.6047 17.5112 17 16.8956 17 16.2222V10"/>
    <path d="M16 2.71429C16 2.06497 15.6433 1.47138 15.0787 1.18099C14.514 0.890606 13.8384 0.953273 13.3333 1.34287L6 6.14286H2.66667C1.74619 6.14286 1 6.91037 1 7.85715V11.2857C1 12.2325 1.74619 13 2.66667 13H3.36111"/>
    <path d="M1 19L22 2"/>
  </svg>
)

