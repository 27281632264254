import React, { useEffect, useState } from 'react';
import * as ReactRouter from "react-router-dom";

import { GameStateContext } from '@ven/games/wordplay/gameplay/GameStateContext';

import { Button } from '@ven/shared/components/common/atoms/Button';
import { TabooCard } from '../../organizms/TabooCard';

import { Ticker } from '@ven/shared/components/game/services/Ticker';
import { ScoreBoard } from './ScoreBoard';
import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { ChatPanel } from './ChatPanel';
import { TimerAndScoresHeader } from './TimerAndScoresHeader';

import styled from '@emotion/styled';

//@ts-ignore
import CenterPad from "@ven/shared/components/assets/wordplay/center-pad.png"
import { PlayAgainButton } from '@ven/shared/components/game/molecules/PlayAgainButton';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';

export const InnerContent_GameOver:React.FC = () => 
{
  const history = ReactRouter.useHistory()
  const { game } = React.useContext( GameStateContext )
  
  const data = game.data!
  const myData = data?.players[ game.myUserId! ]
  const playerOnline = data?.presences[ game.myUserId! ]?.online;
  const scores = data.state.scores
  const winningTeamId = 
    scores[ 1 ] === scores[ 2 ] ? null :
    scores[ 1 ] > scores[ 2 ] ? 1 : 2
  const text = winningTeamId ? ( 
    game.getTeamName( winningTeamId ) + " team wins!" 
  ) : "The game was a draw!"

  const BigAvatar = styled(PlayerAvatar)`
    transform: translate(-50%, -50%); 
    top: 320px;
    left: 50%; 
    position: absolute;
  `

  const [redirecting, setRedirecting] = React.useState(false)
  const nextGameUrl = data?.state.nextGameUrl;
  
  return (
    <GameOverWrapper>
      <TopTip> { text } </TopTip>

      <ScoreBoard />

      <ChatPanel title="" />

      <RoundsBit>
        Round { data.state.round.number }/{ data.config?.rounds || 0 }
      </RoundsBit>

      <BluePad>
        <LightBlueStrip />
        <BigAvatar size={ 200 } index={ myData?.username }
           label={game.getTeamName(myData?.team)}
           transparent={ !playerOnline } />
        <LargeText>{ text }</LargeText>
      </BluePad>

      <PlayAgainButton gameName='outlaw'
          nextGameUrl={nextGameUrl} 
          isEventHost={game.data.eventHost}
          isHost={game.amHost()} 
          isSpectator={game.amSpectator()}
          updateState={game.update.state} 
          setRedirecting={setRedirecting} isRedirecting={redirecting}
        />

      <LargeActionButton className="back-to-games" onClick={ () => history.push("/") }> Back to Games </LargeActionButton>
    </GameOverWrapper>
  )
}

export const InnerContent_TurnNotStarted:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )
  const data = game.data!

  const turnPlayerId = data.state.turn.player
  const turnPlayerData = data.players[ turnPlayerId! ]

  const prevTurnResults = data.state.prevTurnResults
  const prevTurnPlayerId = data.state.prevTurnResults?.player
  const prevTurnPlayerData = data.players[ prevTurnPlayerId! ]
  const prevTurnPlayerOrYou = prevTurnPlayerId === game.myUserId ? "You" : prevTurnPlayerData?.username
  const prevTurnPlayerAvatar = prevTurnPlayerData?.avatar
  const playerOnline = data?.presences[prevTurnPlayerId!]?.online;
  const timeout = data?.state.turn.timeout || '';
  const totalTime = game.getConfig()!.timeout;

  const BigAvatarWrapper = styled.div`
    height: 100%;
    display: grid;
    align-items: center;
    padding-top: 5rem;
  `
  return (
    <Wrapper>
      {
        prevTurnResults &&
        <TopTip> 
          <div> That’s time! </div>
        </TopTip>
      }

      <ScoreBoard />

      <ChatPanel title={ `You’re up for the ${ game.getCurrentTeamName() }!` } />

      <RoundsBit>
        Round { data.state.round.number }/{ data.config?.rounds || 0 }
      </RoundsBit>

      <BluePad>
        {
          // data.state.prevTurnResults && 
          <TimerAndScoresHeader values={ {...data.state.prevTurnResults, turnTime: game.getConfig().turnTime }} />  
        }
        {
          <LightBlueStrip>
            {
              prevTurnResults !== undefined &&
              <div className="text"> { prevTurnResults.score >= 0 ? '+' : '' }{ prevTurnResults.score } Points! </div>
            }
          </LightBlueStrip>
        }
        <BigAvatarWrapper>
          <PlayerAvatar size={ 200 } 
            label={ game.getTeamName(prevTurnResults ? prevTurnPlayerData.team : turnPlayerData?.team )}
            index={ prevTurnResults ? prevTurnPlayerAvatar : turnPlayerData?.avatar }
            transparent={ !playerOnline } />
        </BigAvatarWrapper>

        {
          prevTurnResults ?
          <LargeText style={{ fontSize: '23px' }}>
            { prevTurnPlayerOrYou } earned { prevTurnResults.score } points for the { game.getTeamName( prevTurnResults.team ) }!
          </LargeText> :
          <LargeText>
            Get ready!
          </LargeText>
        }
      </BluePad>

      {
        data.state.turn.player === game.myUserId && !game.amSpectator() &&
        <div>
        <LargeActionButton onClick={ () => game.startPlayingTurn() }>
          {/* <TimerCircleProgress time={timeout} totalTime={totalTime}
              radius={25} displayTime={false} stroke={5} /> */}
            Start Turn (skipping turn in {timeout})
          </LargeActionButton>
        </div>
      }
    </Wrapper>
  )
}

export const InnerContent_Ally:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )
  const data = game.data!
  const turnPlayerId = data.state.turn.player!
  const turnPlayerName = data.players[ turnPlayerId! ].username
  const turnPlayerAvatar = data.players[ turnPlayerId! ].avatar
  const playerOnline = data?.presences[turnPlayerId!]?.online;

  const turnPlayerData = data.players[ turnPlayerId! ]
  const turnTeam = turnPlayerData.team
  const turnTeamName = game.getTeamName( turnTeam )

  const BigAvatar = styled.div`
    transform: translate(-50%, -50%); 
    top: 320px;
    left: 50%; 
    position: absolute;
  `
  const fouled = !! game.data.state.turn?.fouls

  return (
    <>
      <TopTip> { turnTeamName }, it’s your turn! </TopTip>

      <ScoreBoard />

      <ChatPanel title={ `${ turnPlayerName } is up for the ${ game.getCurrentTeamName() }!` } />

      <RoundsBit>
        Round { data.state.round.number }/{ data.config?.rounds || 0 }
      </RoundsBit>

      <BluePad>
        <TimerAndScoresHeader values={ {...data.state.turn, turnTime: game.getConfig().turnTime} } />  
        <BigAvatar>
          <PlayerAvatar size={ 200 } index={ turnPlayerAvatar }
          transparent={ !playerOnline }
          label={game.getTeamName(turnPlayerData.team)}
          />
          </BigAvatar>
        {
          ! fouled &&
          <LargeText style={{ fontSize: '23px' }}>
            Guess the words that { turnPlayerName } describes.
          </LargeText>
        }
      </BluePad>
    </>
  )
}

export const InnerContent_Opponent:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )
  const data = game.data!
  const turnPlayerName =  data.players[ data.state.turn.player! ].username

  const style_TabooCard = {
    top: `65px`,
    left: `50%`,
    transform: `translate(-50%, 0%) scale(0.8)`,
    position: `absolute`,
    width: 399,
    height: 515,
    fontSize: 40,
    lineHeight: '70px',
  }

  const fouled = !! game.data.state.turn?.fouls
  const cardData = ! fouled && game?.data?.state?.turn?.card

  return (
    <>
      <TopTip> 
        <div> { game.getCurrentTeamName() } are guessing. </div>
      </TopTip>
      
      <ScoreBoard />

      <ChatPanel title={ `${ turnPlayerName } is up for the ${ game.getCurrentTeamName() }!` } />
    
      <RoundsBit>
        Round { data.state.round.number }/{ data.config?.rounds || 0 }
      </RoundsBit>

      <BluePad>
        <TimerAndScoresHeader values={ {...data.state.turn, turnTime: game.getConfig().turnTime} } />  
        <TabooCard card={ cardData || {} } style={ style_TabooCard } key={ cardData ? 1 : 0 } />
      </BluePad>

      {
        !game.amSpectator() &&
        ! fouled && 
        !! cardData &&
        <div>
          <BottomTip>
          { game.getCurrentTeamName() } are guessing. Did they say a word they shouldn’t have? Call Outlaw on them!
          </BottomTip>
          <ButtonsBar style={{ justifyContent: `center`, bottom: 40,  top: 670 }}>
          <ActionButton style={{ 
            background : "#FC3B68",
            width: 192,
          }} onClick={ () => game.callFoul() }> Call Outlaw! </ActionButton>
        </ButtonsBar>
        </div>
       
      }
    </>
  )
}

export const InnerContent_TurnPlayer:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )
  const data = game.data!
  const myUserId = game.myUserId!

  const style_TabooCard = {
    top: `100px`,
    left: `50%`,
    transform: `translate(-50%, 0%)`,
    position: `absolute`,
    width: 399,
    height: 495,
    fontSize: 40,
    lineHeight: '70px',
  }

  const [cooldownButtons, setCooldownButtons] = useState(false);

  useEffect(() => {
    if(cooldownButtons) {
      setTimeout(() =>  setCooldownButtons(false), 250)
    }

  }, [cooldownButtons])
  const myTurn = data.state.turn.player === myUserId && game.amSpectator();
  const turnActive = true

  const fouled = !! game.data.state.turn?.fouls
  const cardData = game?.data?.state?.turn?.card
  const runTicker = turnActive && myTurn // && !! cardData

  // const turnPlayerData = data.players[ myTurn! ]
  // const turnTeam = turnPlayerData.team

  // const turnTeamName = game.getTeamName( turnTeam )
  const lastSecond = (game?.data?.state?.turn?.time || 0) < 1;
  const scorePointsDisabled = cooldownButtons || lastSecond;
  return (
    <>
      <TimerAndScoresHeader values={ { ...data.state.turn, turnTime: game.getConfig().turnTime} } style={{ width: 399, top: -12 }} />  
      
      <ScoreBoard />

      <ChatPanel title={ `You’re up for the ${ game.getCurrentTeamName() }!` } />

      <RoundsBit>
        Round { data.state.round.number }/{ data.config?.rounds || 0 }
      </RoundsBit>

      <TabooCard card={ {} } style={{ ...style_TabooCard, opacity: .3, top : `120px` }}/>
      <TabooCard card={ {} } style={{ ...style_TabooCard, opacity: .5, top : `110px` }}/>
      <TabooCard card={ cardData || {} } style={ style_TabooCard } key={ cardData ? 1 : 0 } />
      
      <BottomTip>
        <span> You’re up for the { game.getCurrentTeamName() } </span>
      </BottomTip>
     
      {
        ! fouled &&
        !! cardData &&
        <ButtonsBar style={{ top: 670, width: 379 }}>
          <ActionButton style={{ background : "#FC3B68" }} disabled={scorePointsDisabled} onClick={ () => { setCooldownButtons(true); game.passCard(); } }> Pass </ActionButton>
          <div style={{width:12}} />
          <ActionButton style={{ background : "#2FB586" }} disabled={scorePointsDisabled} onClick={ () =>  { setCooldownButtons(true); game.winCard(); } }> Correct </ActionButton>
        </ButtonsBar>
      }
    </>
  )
}


const Wrapper = styled.div`
//   .avatar-container {
//     transform: translate(0,110%);
//   }
`;

const RoundsBit = styled.div`
  position: absolute;
  width: 183px;
  height: 23px;
  right: 50px;
  top: 90px;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: right;

  color: #FFFFFF;
`
const GameOverWrapper = styled.div`
 .play-again-button {
    width : 363px;
    left: 50%; 
    top: 675px;
    transform: translate(-50%, -50%); 
    position: absolute;
 }

 .back-to-games {
    left: 50%; 
    top: 770px;
    transform: translate(-50%, -50%); 
    position: absolute;
 }
`
const BottomTip = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #FFFFFF;

  /* top: 780px; */
  bottom: 50px;
  left: 50%; 
  transform: translate(-50%, -50%); 
  position: absolute;
  width: 40%;
`

const LargeText = styled.div`
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 44px;
  text-align: center;

  color: #FFFFFF;

  top: 445px;
  left: 50%; 
  transform: translate(-50%, 0); 
  position: absolute;
  width: 80%;
`

const LightBlueStrip = styled.div`
  position: absolute;
  top: 114px;
  width: 399px;
  height: 81px;
  background: #ffffff7d;

  display: flex;
  align-items: center;
  justify-content: center;

  & .text {
    font-family: Oswald;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    color: #FFFFFF;
  }
`

const BluePad = ({ children }) => {
  const BluePadImg = styled.div`
    background-image: linear-gradient(140deg,var(--outlaw-pad) 0%,var(--outlaw-pad-secundary) 100%);
    border-radius: 10px;
    width: 399px;
    height: 569px;
    top: 45px;
    left: 50%; 
    transform: translate(-50%, 0); 
    position: absolute;
  `
  return (
    <BluePadImg>
      { children }
    </BluePadImg>
  )
}

const TopTip = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  color: #FFE499;

  top: 0px;
  left: 50%; 
  transform: translate(-50%, 0); 
  position: absolute;
  width: 90%;
`

const BottomWaitTip = styled.div`
  height: 105px;
  left: 50%; 
  bottom: 0px;
  transform: translate(-50%, -0%); 
  position: absolute;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 105px;
  text-align: center;

  color: #FFFFFF;
`

// // // // // //

const ButtonsBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;

  left: 50%; 
  transform: translate(-50%, -50%); 
  position: absolute;
`

const ActionButton = styled(Button)`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 66px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  width: 48%;

  margin: 0;

  &:not([disabled]):hover {
    border: none;
    border: 2px solid white;
  }
  
  /* animation: zoom .500s;
  animation: fade-in .500s; */
`

const LargeActionButton = styled(ActionButton)`
  background : #2FB586;
  width : 363px;

  left: 50%; 
  top: 670px;
  transform: translate(-50%, -50%); 
  position: absolute;
`

/////

export const desktopViews = {
  InnerContent_GameOver,
  InnerContent_TurnNotStarted,
  InnerContent_TurnPlayer,
  InnerContent_Ally,
  InnerContent_Opponent,
}
