import React from 'react';

import star from '@ven/main/assets/images/homepage/star.svg';

import './styles.scss';

interface TestimonalProps {
    props: {
        stars: number;
        description: string;
        image: string;
        name: string;
        job?: string;
    }
};

const Testimonal: React.FunctionComponent<TestimonalProps> = (props: TestimonalProps) => {

    const starElements: Array<any> = [];

    const data = props.props;

    for (let i = 0; i < data.stars; i++) {
        starElements.push(
            <img src={star} key={i} alt="star" />
        )
    }

    return (
        <div className="testimonal">
            <div className="stars">
                {starElements}
            </div>
            <div className="description">
                {data.description}
            </div>
            <div className="person">
                <img src={data.image} alt="" />
                <div className="name-job">
                    <p>{data.name}</p>
                    <p>{data.job}</p>
                </div>
            </div>
        </div>
    );
}

export default Testimonal;