import React from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';

import styled from '@emotion/styled';

// @ts-ignore
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import Notepad from '../notepad/Notepad';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';
import { TurnType } from '@ven/games/brokenmsg/gameplay/GameStateController';

export const SpectatorView:React.FC<any> = () => 
{
  const { game, data } = React.useContext( GameStateContext )

  const roundNumber = game.data?.state.turn?.rotation;
  const roundTotal = game.getPlayersCount() - 1;

  const firstSentence = `Players are writing the first sentence`;

  const turnData = `Waiting for the game to end.`;
  const rounds = `Round: ${ roundNumber } / ${ roundTotal }`;

  const timeLeft = game.data?.state.turn?.time
  const turnType = data?.state.turn.type;

  return ! data ? null : (
    <Wrapper>
      <Spacer size={ 60 } />
      
      <Notepad 
        cover={ true } 
      >
        <div className="top-tip">
        <div className="clock-wrapper">
          <TimerCircleProgress color={'#303744'} time={ timeLeft || '' } totalTime={
            (!roundNumber) ? game.getConfig().firstSentenceTime :
             turnType === TurnType.Drawing
             ? game.getConfig().drawingTime
             : game.getConfig().sentenceTime
          } />
        </div>
        <span>{rounds}</span>
          <span>{`${ (roundNumber) ? turnData : firstSentence }`}</span>
        </div>
      </Notepad>

      <Spacer size={ 35 } />
      
    </Wrapper>

  )
}

const Wrapper = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;


.Notepad .body.input {
  height: 100%;
}

.clock-wrapper {
    height: 8.5rem;
    margin-top: 5px;
}

.top-tip {
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 24px;
  text-align: center;
  color: var(--notepad-text);
  height: 80px;
  width: 373px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

.top-timer {
  position: relative;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waiting-warning {
  font-family: Oswald;
  font-style: normal;
  font-size: 2rem;
  line-height: 30px;
  color: var(--notepad-cover);
  text-align: center;
  display: flex;
  margin-bottom: 2rem;
  position: absolute;
  bottom: 0;
}

`
