import { GetCurrentUserData, UserDataService } from "@ven/platform/main/services/user/UserDataService";
import { database } from "../data/firebase";
import { DEVELOPMENT_MODE } from '@ven/core/debug/consts';
import { LogService } from "@ven/platform/main/services/log/LogService";

export const CouponService = {

  /**
 * Generates an unclaimed code on code-list collection for later use.
 * @returns string | false
 */
  async GenerateUnclaimedCode(userId: string | null = null) {
    try {
      const code = Math.random().toString(36).substring(2, 8).toUpperCase()
        + Math.random().toString(36).substring(2, 8).toUpperCase();
      const codeList = database.ref(`/code-list/${code}`);
      codeList.set({
        status: 'UNCLAIMED',
        userId
      });

      return code;
    } catch (err) {
      // Error on creating new code for user
      console.log(err);
      await LogService.log(err, 'Error: Could not create coupon code.')
      return false;
    }
  },

  /**
  * Set code as claimed on code-list collection.
  * @returns string || false
  */
  async SetCodeAsClaimed(code: string) {
    try {
      const codeList = database.ref(`/code-list/${code}`);
      codeList.set({
        status: 'CLAIMED',
        claimDate: new Date().toISOString(),
      });

      return code;
    } catch (err) {
      // Error on updating code status
      console.log(err);
      await LogService.log(err, 'Error: Could not update coupon code claim.')
      return false;
    }
  },

  /**
  * Check if code is unclaimed
  * @param code 
  * @returns boolean
  */
  async IsUnclaimedCode(code: string) {
    const codeList = database.ref(`/code-list/${code}`);
    const codeStatusRef = await codeList.once('value');
    const codeStatus = codeStatusRef.val();
    return codeStatus && codeStatus.status && codeStatus.status === 'UNCLAIMED';
  },

  /**
  * Add code to user info
  * @param packageName any | 'Game Night Bundle' | 'Team Bundle'
  * @param code 
  * @param price 1200 | 'FREE'
  * @param state 'UNUSED' | 'ACTIVE'
  */
  async PutCodeOnUserInfo(packageName: string, code: string, price: string | number, state: string, id?: string, name?: string, neverExpire?: boolean) {
    try {
      const uid = UserDataService.get('uid');
      const dbRef = database.ref(`/users/${uid}`);
      const gnbRef = database.ref(`/users/${uid}/packages/${packageName.split(' ').join('')}/${code}`);
      dbRef.update({
        paidUser: true,
      });

      let exp: Date | null = null;
      if (!neverExpire) {
        exp = new Date();
        const year = exp.getFullYear() + 1;
        exp.setFullYear(year);
      }

      let data : any = {
        code,
        purchaseDate: new Date().toISOString(),
        expireDate: exp ? exp!.toISOString() : null,
        state,
        name: name || packageName || null,
        id: id || packageName.replace(' ', '') || null,
        type: packageName,
        price
      };

      if (neverExpire) {
        data.overwriteExpireDate = new Date().toISOString().replace(/^20/, '21');
      }
      await gnbRef.set(data);
      return true;

    } catch (err) {
      // Error adding code to user info
      console.log(err);
      await LogService.log(err, 'Error: Could not add code to user info.')
      return false;
    }
  },

  async userHasCoupon(couponCode, packages) {
    let userPackages = packages;
    if (!userPackages) {
      const userData = await GetCurrentUserData();
      if (!userData.packages) {
        return false;
      }
      userPackages = userData.packages;
    }

    const [availableCoupons] = await this.getCurrentUserCoupons(userPackages);

    return availableCoupons.find(coupon => coupon.code == couponCode)
  },

  async getCurrentUserPackages() {
    const userData = await GetCurrentUserData();
    if (!userData.packages) {
      return [];
    }
    const userPackages = Object.keys(userData.packages);

    return userPackages;
  },

  async getCurrentUserWordsPacks() {
    const userData = await GetCurrentUserData();
    if (!userData.packages) {
      return [];
    }
    const userPackages = userData.packages['WordsPack'] || [];
    const userOwnPacksKeys = Object.keys(userPackages);

    return userOwnPacksKeys.map(code => userPackages[code].id);;
  },

  async getCurrentUserCoupons(packages?) {
    let userPackages = packages;
    if (!userPackages) {
      const userData = await GetCurrentUserData();
      if (!userData.packages) {
        return [];
      }
      userPackages = userData.packages;
    }

    const types = Object.keys(userPackages);
    let coupons: any[] = [];
    for (const t of types) {
      if (!t.toLocaleLowerCase().includes('vip' )) {
        for (const v of Object.keys(packages[t])) {
          coupons.push(packages[t][v]);
        }
      }
    }

    return this.separateCoupons(coupons);
  },

  separateCoupons(coupons: Array<any>) {
    let availableCoupons = new Array;
    let userExpiredCoupons = new Array;
    for (const key of Object.keys(coupons)) {
      const coupon = coupons[key];
      const list = coupon.state === 'UNUSED' || coupon.state === 'ACTIVE'
        ? availableCoupons
        : userExpiredCoupons;

      list.push(coupon)
    }
    return [availableCoupons, userExpiredCoupons]
  },

  async validateActiveCode(uid) {
    const activeCouponRef = database.ref(`/users/${uid}/activeCoupon`);
    const activeCode = await activeCouponRef.once('value');
    const activeCodeValue = activeCode.val();
    if (!activeCodeValue) {
      return null;
    }

    let endDate = new Date(activeCodeValue.activationDate);
    const ahead48hours = endDate.getTime() + this.getBundleExpirationTime();
    endDate.setTime(ahead48hours);
    if(activeCodeValue.overwriteExpireDate) {
      endDate = new Date(activeCodeValue.overwriteExpireDate);
  } 

    const activeCodeIsStillActive = endDate > new Date();
    return activeCodeIsStillActive;
  },

  async expireActiveCode(uid, code?) {
    let activeCodeValue = code;
    const activeCouponRef = database.ref(`/users/${uid}/activeCoupon`);
    if (!activeCodeValue) {
      const activeCode = await activeCouponRef.once('value');
      activeCodeValue = activeCode.val();
    }
    const couponStateRef =
      database.ref(`/users/${uid}/packages/${activeCodeValue.type.split(' ').join('')}/${activeCodeValue.code}/state`);
    couponStateRef.set('USED');
    activeCouponRef.set(null);
  },

  async validateExpiredActiveCode(uid, code?) {
    let activeCodeValue = code;
    const activeCouponRef = database.ref(`/users/${uid}/activeCoupon`);
    if (!activeCodeValue) {
      const activeCode = await activeCouponRef.once('value');
      activeCodeValue = activeCode.val();
    }

    // There is not coupon active
    if (!activeCodeValue) {
      return null;
    }

    // The active code is expired
    let endDate = new Date(activeCodeValue.activationDate);
    endDate.setTime(endDate.getTime() + this.getBundleExpirationTime());
    if(activeCodeValue.overwriteExpireDate) {
      endDate = new Date(activeCodeValue.overwriteExpireDate);
    } 

    if (endDate < new Date()) {
      return false;
    }

    // Active code is still active
    return true;
  },

  async checkAndExpireActiveCode(uid, code?) {
    const valid = await this.validateExpiredActiveCode(uid, code);
    
    // There is not coupon active
    if (valid == null) {
      return null;
    }

    if(valid) {
      return true;
    }

    await CouponService.expireActiveCode(uid, code);
    return false;
  },

  getBundleExpirationTime() {
    return (48 * 60 * 60 * 1000);
  // return 0;
  },

  watchPackages(uid, callback) {
    const ref = database
      .ref(`/users/${uid}/packages/`);

    ref.on('value', o => {
      const val = o.val();
      if (!val)
        return;

      callback(val);
      // ref.off(callback);
    })

    return () => ref.off(callback);
  },

  watchWordsPack(uid, callback) {
    const ref = database
      .ref(`/users/${uid}/packages/WordsPack`);

    ref.on('value', o => {
      const val = o.val();
      if (!val)
        return;

      callback(val);
      // ref.off(callback);
    })

    return () => { } //ref.off(callback);
  },

  watchPackage(uid, packageName, callback) {
    const ref = database
      .ref(`/users/${uid}/packages/${packageName}`);

    ref.on('value', o => {
      const val = o.val();
      if (!val)
        return;

      callback(val);
      ref.off(callback);
    })

    return () => { } //ref.off(callback);
  }
}

DEVELOPMENT_MODE && (window['CouponService'] = CouponService);
