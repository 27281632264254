import firebase from "firebase";
import { DEVELOPMENT_MODE } from "../debug/consts";

export const PacksService = {
  async getUserPacks(name: string, userId: string) {
    const freePacksSnapshot = await firebase
      .firestore()
      .collection("packs")
      .where("game", "==", name)
      .where("owner", "==", null)
      .get();

    const freePacks = freePacksSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }))

    const packsFromUserSnapshot = await firebase
      .firestore()
      .collection("packs")
      .where("game", "==", name)
      .where("owner", "==", userId)
      .get();

    const packsFromUser = packsFromUserSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    const data = [...freePacks, ...packsFromUser];
    return data;
  }
}

DEVELOPMENT_MODE && (window['PacksService'] = PacksService);
