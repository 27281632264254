import React from "react";
import { auth } from "../data/firebase";

import type { GameStateController } from "../gameplay/GameStateController";
import { UserDataService } from "@ven/platform/main/services/user/UserDataService";
import { GameFunctionsService } from "../services/GameFunctionsService";

export const ConsoleDebugFunctions = () => {

  const getGame = () =>  {
      return (window as any).game;
  }

    const game = (window as any).game;
    const firebaseUrl =
    'https://console.firebase.google.com/u/1/project/ven-games---staging/database/ven-games---staging/data/';
    const users = 'users';
    const open = (url) =>  {
      window.open(firebaseUrl + url, "_blank"); 
    }

    const inspectUsers = (id?) =>  {
      open(`${users}${id ? '/'+ id : ''}`); 
    }
    const inspectGame = (game, id?) =>  {
      open(`game-sessions/${game}${id ? '/'+ id : ''}`);
    }
    const inspectRoom = (id?) =>  {
      open(`game-rooms${id ? '/'+ id : ''}`);
    }
    const inspectPlayer = (game, roomId, id?) =>  {
      open(`game-sessions/${game}/${roomId}/players${id ? '/'+ id : ''}`); 
    }
    
    window['inspectUsers'] = () => {
      inspectUsers();
    }
    window['inspectRooms'] = () => {
      inspectRoom();
    }

    window['inspectRoom'] = (id) => {
      inspectRoom(id ? id : getGame().code);
    }
    window['inspectGame'] = (id) => {
      inspectGame(id ? id : `${getGame().gameId}/${getGame().roomId}`);
    }

    window['inspectMe'] = () => {
      inspectPlayer(getGame().gameId, getGame().roomId, game?.myUserId);
    }
    window['inspectHost'] = () => {
      inspectPlayer(getGame().gameId, getGame().roomId, getGame().data.host);
    }
    window['inspectPlayer'] = (id) => {
      inspectPlayer(getGame().gameId, getGame().roomId, id || game?.myUserId);
    }
    window['inspectGamePlayers'] = () => {
      inspectPlayer(getGame().gameId, getGame().roomId);
    }
    window['inspectPlayers'] = () => {
      getGame().getPlayers().forEach(p => {
        inspectPlayer(getGame().gameId, getGame().roomId, p.uid);
      })
    }

    window['inspectUser'] = (id) => {
      inspectUsers(id ? id : auth.currentUser?.uid);
    }
    window['inspectUserHost'] = (id) => {
      inspectUsers(getGame().data.host);
    }


    window['inspectScribbler'] = () => {
      inspectGame('scribbler')
    }
    window['inspectOutlaw'] = () => {
      inspectGame('outlaw')
    }
    window['inspectBrokenMsg'] = () => {
      inspectGame('brokenMsg')
    }

    window['inspect'] = (command) => {
   
      if(!command) {
        open(`game-sessions/${getGame().gameId}/${getGame().roomId}`);
        return;
      }

      let userId = '/';

      if(command == 'scribbler' || command == 'outlaw' || command == 'brokenmsg') {
        inspectGame(command);
        return;
      }

      if(command == 'room') {
        inspectRoom(getGame().gameId);
        debugger;
        return;
      }

      if(command == 'session') {
        inspectGame(`${getGame().gameId}/${getGame().roomId}`);
        return;
      }

      if(command == 'me')
        userId += game?.myUserId || ''
      if(command == 'host')
        userId += getGame().data.host || '';

      open(users + userId);
    }

    window['playGame'] = (gameId) => {
      const uid = UserDataService.get('uid');
      GameFunctionsService.createRoom(gameId, (uid as string))
      .then((data) => data && window.history.pushState({}, '', `/play/${data.code}`));
    }

    window['addPuppets'] = (amount = 4) => {
      const game = getGame();
      const code = game.code;
      
      if(!amount){
        amount = game.data.maxPlayers;
      }
      for (let index = 0; index < amount; index++) {
        GameFunctionsService.joinPuppets(code, amount);
      }
    }

    window['fullPuppets'] = (puppetId) => {
      const game = getGame();
      const code = game.code;
      const amount = game.data.maxPlayers - Object.keys(game.data.players).length;
      
      GameFunctionsService.joinPuppets(code, amount, puppetId);
    }

    window['cleanPuppets'] = () => {
      const game = getGame();
      const code = game.code;
      GameFunctionsService.cleanPuppets(code);
    }

    
}
