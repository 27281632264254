import React from 'react';

import { COLOR_TOOL_ACTIVE, COLOR_TOOL_IDLE } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled'

const ToolBarWrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: white;

  .tool-button {
    padding: 0;
    margin: 8px;
    width: 52px;
    height: 52px;
    border: none;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;

    &[disabled] {
      opacity: .2;
    }

    /* The Icon */
    & .icon {
      transition: transform 250ms;
    }
    &:active .icon {
      transition: transform 100ms;
      transform: scale(.85);
    }

    /* The Circle */
    &:after {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      transition: zoom 400ms, border 400ms;
      border: 12px solid #0000;
      border-radius: 50%;
    }
    &[current]:after {
      border: 2px solid ${ COLOR_TOOL_ACTIVE }
    }
  }
`

type IconProps = {
  color:string,
}

type ToolBarProps = React.PropsWithRef<{
  buttons : {
    Icon : React.FC<IconProps>,
    onClick : () => any,
    selected? : boolean,
    iconProps? : any
  }[],
  className?:string
}>

export const ToolBar:React.FC<ToolBarProps> = ({ buttons, className, ...props }) => 
{
  return (
    <ToolBarWrapper className={ 'toolbar dropping-the-shadow ' + ( className || '' ) } {...props}>
      { buttons.map( (buttonProps,i) => <ToolBarButton key={ i } { ...buttonProps } /> ) }
    </ToolBarWrapper>
  )
}

export const ToolBarButton = ({ onClick, Icon, selected=false, iconProps={}, ...props }) => (
  <button
  className="tool-button"
  {...{ current : selected ? '' : null }}
  onPointerDown={ e => e.preventDefault() }
  onClick={ onClick }
  { ...props }>
    <div className="icon">
      <Icon color={ selected ? COLOR_TOOL_ACTIVE : COLOR_TOOL_IDLE } {...iconProps} />
    </div>
  </button>
)
