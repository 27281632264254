import React from 'react';

import './styles.scss';

interface ActModalProps {
    couponValue: any;
    close: Function;
    activate: Function;
    isThereActiveCode: boolean;
}

const ActivationModal: React.FC<ActModalProps> = ({ couponValue, close, activate, isThereActiveCode }) => {
    return (
        <div className="activation-modal">
            { !isThereActiveCode &&
                <div className="content">
                    <p>Are you sure you want to start your <br /> {couponValue.type} ?</p>
                    <p>Once you start, you will have 48 hours to enjoy your benefits.</p>
                    <button className="start" onClick={() => activate(couponValue)}>Yes, start!</button>
                    <button className="back" onClick={() => close()}>No, take me back!</button>
                </div>
            }
            { isThereActiveCode &&
                <div className="content">
                    <p>You already have an active coupon code!</p>
                    <p>Once your coupon time finishes, you will be able to activate another one.</p>
                    <button className="back" onClick={() => close()}>OK, take me back!</button>
                </div>
            }
        </div>
    )
}

export default ActivationModal;
