import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import styled from '@emotion/styled'
import { SocialSection } from './SocialSection';

export const MainFooter: React.FC<{darkTheme?: boolean }> = () => {

  return (<Wrapper>
      <SocialSection/>
      <footer>
          <div>Virtual Engagement - VEN Games</div>
          <div><a href="mailto:contact@ven.games">Contact@VEN.games</a></div>
          <div><Link to="/termsandconditions">Terms {'&'} Conditions</Link></div>
          <div><Link to="/privacy-policy">Privacy Policy</Link></div>
          <div><a href="https://forms.gle/wXFgK2RzbxbxdkEw6">Leave Feedback</a></div>
      </footer>
    </Wrapper>
    )
}

const Wrapper = styled.div`

footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px #303744;
    width: 90%;
    height: 5.0rem;
    margin: auto;
    /* margin-top: 10.0rem; */
    div {
        font: 300 1.5rem Oswald;
        color: #303744;
        a {
            color: #303744;
            cursor: pointer;
            text-decoration: none;
        }
        &:first-of-type {
            width: 60%;
        }
    }
}

@media (max-width: 700px) {
  footer {
      /* margin-top: 8.0rem; */
      div {
          &:first-of-type {
              display: none;
          }
      }
  }
}
`
