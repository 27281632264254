import React, { useMemo } from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';

import { useWindowSize } from '@ven/shared/components/hooks/useWindowSize';
import { Dimensions } from '@ven/games/brokenmsg/other/dimensions';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

import { SelfScalingContent } from '@ven/shared/components/game/wrappers/SelfScalingContent';

import { HowToPlay_BrokenMsg } from './HowToPlay_BrokenMsg';

import { FirstSentenceView } from './FirstSentenceView';
import { CanvasView } from './CanvasView';
import { GameOverView } from './GameOverView';

import { GamePhase } from '../../gameplay/GameStateController';
import { BottomRibbon } from '@ven/shared/components/common/atoms/BottomRibbon';
import { MainHeader } from '@ven/platform/main/components/organisms/MainHeader';

//@ts-ignore
import TimerSound from "@ven/shared/components/assets/sounds/Timeisup_7Seconds.mp3"
import { audioService } from '@ven/core/services/SoundService';
import { SpectatorView } from './SpectatorView';

export const InGameContent:React.FC = () => 
{
  const { width, height } = useWindowSize();
  const USE_MOBILE_LAYOUT = width <= MOBILE_BREAK_PX;
  const designDimensions = { ...USE_MOBILE_LAYOUT ? Dimensions.mobile : Dimensions.desktop }

  const HIDE_TOP_AND_BOTTOM_BAR = height < MOBILE_BREAK_PX;
  if ( HIDE_TOP_AND_BOTTOM_BAR ) {
    designDimensions.insetY = 0;
  }

  if ( USE_MOBILE_LAYOUT ) {
    designDimensions.insetY = 0;
  }

  const { game } = React.useContext( GameStateContext )

  const gameOver = game.data?.state?.over;
  const firstSentence = game.data?.state.phase === GamePhase.FirstSentence;

  if ( USE_MOBILE_LAYOUT && gameOver ) {
    designDimensions.height = 900;
    designDimensions.width = 460;
  }

  useMemo(() => {
    audioService.preload(TimerSound);
  }, [])

  const getView = () => 
    game.amSpectator() && !gameOver 
    ? < SpectatorView />
    : firstSentence 
    ? < FirstSentenceView />
    : ! gameOver 
    ? <CanvasView /> 
    : <GameOverView />

  const [ showHowToPlay, setShowHowToPlay ] = React.useState( false )

  return (
    <>
      
      {
        !USE_MOBILE_LAYOUT ? <MainHeader inGame={ true } /> : null
      }
      
      <SelfScalingContent 
        className="inner-content brokenmsg" 
        designDimensions={ designDimensions } 
        fixed={ true } 
        // disabled={ true }
        >
        { getView() }
      </SelfScalingContent>

      { 
        USE_MOBILE_LAYOUT 
        ? null 
        : <BottomRibbon game={game} callbacks={{ showHowToPlay : () => setShowHowToPlay( true ) }} /> 
      }
        
      {
        showHowToPlay &&
        ! USE_MOBILE_LAYOUT &&
        <HowToPlay_BrokenMsg handleClose={ () => setShowHowToPlay( false ) } absolute={ false } mobileBreak={ MOBILE_BREAK_PX } />
      }
    </>
  )
}

