import { MAIN_HEADER_VIRTUAL_HEIGHT_PX } from "@ven/shared/components/styling/breakpoints";

export const Dimensions = {
  mobile : {
    width: 380,
    height: 698,
    insetY : MAIN_HEADER_VIRTUAL_HEIGHT_PX,
  },
  desktop : {
    width: 640,
    height: 818,
    insetY : MAIN_HEADER_VIRTUAL_HEIGHT_PX,
  },
}
