import CanvasGifEncoder from "@pencil.js/canvas-gif-encoder";

// import venImg from '@ven/platform/main/assets/images/logo_ven.svg';
import venImg from "@ven/components/assets/logo.png"

const GifService = () => {
    const generateGif = (canvases: any[], drawLogo: boolean = true) => {
       
        const canvasTempRef = document.createElement("canvas");
        canvasTempRef.style.display = "none";
        canvasTempRef.height = canvases[0].height;
        canvasTempRef.width = canvases[0].width;

        const ctx = canvasTempRef.getContext("2d");
        if(drawLogo && ctx != null)  {
            const imgTemp = document.createElement("img");
            imgTemp.src = venImg;

            canvasTempRef.height = canvases[0].height + imgTemp.height / 10;

            ctx.fillStyle = "#6F5CFC"
            ctx.fillRect(0, canvases[0].height, canvasTempRef.width, canvasTempRef.height);

            drawWatermark(imgTemp, ctx, canvases[0].height);
            imgTemp.remove();
        }
      
        const options = {
            alphaThreshold: 0,
            quality: 0.1,
        };
        const encoder = new CanvasGifEncoder(canvasTempRef.width, canvasTempRef.height, options);
        const time = 1000;
        canvases.forEach(canvas => {
            const ctx = canvasTempRef.getContext("2d");
            ctx!.fillStyle = "#FFFFFF"
            ctx?.fillRect(0, 0, canvasTempRef.width, canvases[0].height);
            ctx?.drawImage(canvas, 0, 0);
            encoder.addFrame(ctx, time)
        });

        const gif = encoder.end();
        const blob = new Blob([gif.buffer], { type: 'image/gif' });
        downloadBlob(blob, "my.gif")
        encoder.flush();

        canvasTempRef.remove();
    }
    
    const drawWatermark = (logo, ctx, heightOffset) => {
        if (!logo)
            return;

        ctx.drawImage(logo, 10, heightOffset + 2, logo.width / 10, logo.height / 10);
        ctx.font = "14px Bitter";
        ctx.fillStyle = "#FFFFFF"
        ctx.textAlign = "end";
        ctx.fillText("www.ven.games", ctx.canvas.width - 10, heightOffset + 20);//logo.width + 10, heightOffset);
    }

    const downloadBlob = (blob, filename?) => {

        if (!filename) filename = 'notepad.gif'

        const e = document.createEvent('MouseEvents'),
            a = document.createElement('a')

        a.download = filename
        a.href = window.URL.createObjectURL(blob)
        a.dataset.downloadurl = [blob.type, a.download, a.href].join(':')
        e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        a.dispatchEvent(e)
    }

    return {
        generateGif
    }
}

export default GifService;