import React from 'react';

import { range } from '@ven/core/utils/arrays';

import type { GameStateController } from '@ven/core/gameplay/GameStateController';
import { Spacer } from '../atoms/Separator';
import { ShareableLink } from './ShareableLink';

import styled from "@emotion/styled";
import { SelectAvatarSlider } from './SelectAvatarSlider';
import { NameInput } from './NameInput';
import type { WithGame, WithReferrerId, WithTheme } from '../../prop-types';

export const PregamePageWrapper = styled.div`
  &.dimmed:before {
    content: '';
    background: #000000CC;
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    pointer-events: none;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .main-header {
    /* width: 100vw; */
  }
`

export const ShareGameWrapper = styled.div`
.share-label-large {
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}

.share-label-small {
  font-family: Bitter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 27px;
  text-align: center;
}

.share-code {
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  user-select: all;
  span {
    margin: 0 4px;
  }
}
`

export const PadContent_ShareGame:React.FC<Partial<WithTheme> & WithGame & WithReferrerId> = ({ refererId, game }) => (
  <ShareGameWrapper className="inner-content">
    <div className="share-label-large"> Want friends to join? </div>
    <div className="share-label-small"> Simply send them this link: </div>
    <Spacer size={ 14 } />
    <ShareableLink gameId={ game.gameId } roomCode={ game.code! } refererUserId={ refererId } />
    {
      game.code && 
      <>
        <div className="share-label-small"> or have them use the code: </div>
        <div className="share-code"> 
          { 
            game.code &&
            range( Math.ceil( game.code.toString().length / 2 ) )
            .map( i => <span key={ i }>{ game.code.toString().substr( 2 * i , 2 ) }</span> )
          } 
        </div>
      </>
    }
    <Spacer size={ 28 } />
  </ShareGameWrapper>
)

export const PadContent_StandBy:React.FC<WithGame> = ({ game }) => (
  <ShareGameWrapper className="inner-content">
    <div className="share-label-large"> Stand by. </div>
    <div className="share-label-small"> 
      { 
        ! game.canStartGame() 
        ? "Waiting for more players to join."
        : "Please wait for the host to start the game."
      }
    </div>
  </ShareGameWrapper> 
)

export const UsernameAndAvatarForm:React.FC<Partial<WithTheme> & { spacing?:number }> = ({ spacing, theme = ""}) => {
  return <>
    <div className="label"> Your Name: </div>
    <NameInput />
    { spacing && <Spacer size={ spacing } /> }
    <div className="label"> Your Avatar: </div>
    <SelectAvatarSlider theme={ theme } />
  </>
}
