import React from 'react';

const DEFAULT_COLOR = "#black"

export const SendIcon = ({ color = DEFAULT_COLOR }) => (
  <svg xmlns="http://www.w3.org/2000/svg"  fill={ color }
    x="0px" y="0px" width="64px" height="64px" viewBox="0 0 535.5 535.5">
      <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75"/>
  </svg>
)

export const EnableFullScreenIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
  stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M7 21L1 27"/>
    <path d="M27 9V1H19"/>
    <path d="M1 19V27H9"/>
    <path d="M27 1L21 7"/>
    <path d="M21 21L27 27"/>
    <path d="M1 9V1H9"/>
    <path d="M27 19V27H19"/>
    <path d="M1 1L7 7"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 10H18V18H10V10Z"/>
  </svg>
)

export const VolumeOnIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg"
   stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.56816 7.34719H1.82353C1.36871 7.34719 1 7.75605 1 8.26041V13.7397C1 14.2441 1.36871 14.6529 1.82353 14.6529H6.56816C7.80519 18.1653 10.7111 20.6143 14.0941 20.9955C14.3256 21.021 14.5559 20.9368 14.7285 20.7638C14.901 20.5908 14.9996 20.3451 15 20.0872V1.91293C14.9999 1.65489 14.9014 1.40893 14.7288 1.23586C14.5562 1.0628 14.3256 0.978778 14.0941 1.00458C10.7111 1.38579 7.80519 3.8348 6.56816 7.34719Z"/>
    <path d="M24 16C25.3333 13.256 25.3333 9.74402 24 7"/>
    <path d="M21.1098 14C22.1504 12.5431 22.1504 10.4569 21.1098 9"/>
</svg>
)

export const VolumeOffIcon = ({ color = DEFAULT_COLOR }) => (
  <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg"
   stroke={ color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M10 15.1674L14.0443 17.6444C14.6041 18.0485 15.353 18.1134 15.9788 17.8123C16.6047 17.5112 17 16.8956 17 16.2222V10"/>
    <path d="M16 2.71429C16 2.06497 15.6433 1.47138 15.0787 1.18099C14.514 0.890606 13.8384 0.953273 13.3333 1.34287L6 6.14286H2.66667C1.74619 6.14286 1 6.91037 1 7.85715V11.2857C1 12.2325 1.74619 13 2.66667 13H3.36111"/>
    <path d="M1 19L22 2"/>
  </svg>
)

