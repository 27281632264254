import React from "react";
import { useEffect } from "react";
import { GetSessionAndRedirectToCheckout } from "@ven/platform/main/services/PaymentService";
import Notepad from "@ven/games/brokenmsg/components/notepad/Notepad";
import styled from "@emotion/styled";
import { CouponService } from "@ven/shared/core/services/CouponService";

const PackInit = () => {

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const id = query.get('id') || '';
        let name = query.get('name');
        name = name && name != 'undefined' ? name : id || '';
        let price = query.get('price') || '0';
        CouponService.GenerateUnclaimedCode().then(async (code) => {
            await GetSessionAndRedirectToCheckout(code, 'pack', +price, id, name!);
        });
    }, []);

    return (
        <Wrapper>
            <Notepad>
                <div className="body">
                    <span>redirecting...</span>
                    <h1>Thanks for supporting VEN GAMES!</h1>
                </div>
            </Notepad>
        </Wrapper>
    );
}
export default PackInit;   

const Wrapper = styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;

    span {
        font-size: 2.5rem;
    }

    h1 {
        text-align: center;
    }
`