import React from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';
import { PlayerAvatar } from '../../../../shared/components/game/molecules/PlayerAvatar';
import { MessageInput } from '../molecules/MessageInput';
import type { ChatMessage } from '@ven/games/scribbler/gameplay/GameStateController';

import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { COLOR_DARK, COLOR_GRAY, COLOR_PRIMARY } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';

interface Props 
{ 
  history : Record<string, ChatMessage>
  handleSend? : (message:string) => any
}
export const ChatPanelMobile:React.FC<Props> = ({ history, handleSend }) =>
{
  const { data } = React.useContext( GameStateContext);
  const myUserData = React.useContext( UserDataContext );
  const turn = data?.state?.turn?.number || 0;
  // const [ focused, setFocused ] = React.useState( false )

  let entries = Object.entries( history ).reverse().slice(0,2)
  
  return (
    <Wrapper> 
      { handleSend && 
        <MessageInput {...{ style: { borderRadius : 12 }}} 
        handleSend={ handleSend } 
        // setFocused={ setFocused }
        /> 
      }
      {
        // ! focused &&
        <MessagesContainer className="chat-messages-container">
          <MessageList>
            {
              entries.map( ([id,m]) => (
                m.sender === '' 
                ?
                  !m.to || m.to == myUserData.uid
                  ?
                  <Message key={ id } className={`${m.turn && m.turn != turn ? 'transparent' : '' } system`}> 
                    <span className="text"> { m.text } </span>
                  </Message>
                  : null
                : <Message key={ id } className={m.turn && m.turn != turn ? 'transparent' : '' }> 
                  <PlayerAvatar size={ 26 } index={ data?.players[ m.sender ]?.avatar }/>
                  <span className="sender"> { data?.players[ m.sender ]?.username }: </span>
                  <span className="text"> { m.text } </span>
                </Message>
              ) )
            }
          </MessageList>
        </MessagesContainer>
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  
  @media (min-width: ${ MOBILE_BREAK_PX }px) {
    display: none;
  }
`
const MessagesContainer = styled.div`
  padding: 0;
  margin: 0;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px;
  z-index: 9;
  /* justify-content: flex-end; */
`
const MessageList = styled.ul`
  margin-top: auto;
  padding: 0;
  margin: 0;
  width: 100%;
`
const Message = styled.li`
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 5px 12px;
  margin-bottom: 9px;
  line-height: 22px;
  text-align: start;
  
  border-radius: 12px;
  background: #F9F9FF;

  /* animation: slide-left-sprite 200ms; */

  & div {
    float: left;
    padding-right: 5px;
  }

  & span {
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: ${ COLOR_DARK };
  }

  & span.sender {
    font-weight: bold;
  }

  &.system span {
    font-weight: bold;
    color: ${ COLOR_PRIMARY };
  }

  & .avatar {
    width: 26px;
    height: 26px;
  }

  &.transparent * {
    opacity: 0.3;
  }
`
