import { DEVELOPMENT_MODE } from "../debug/consts";

const URL_BASE = process.env.FUNCTION_URL;

export const NewsletterService = {
  async post( route:string, body:any ) {
    try {
      // const response = await fetch(`http://localhost:5001/ven-games---staging/us-central1/app/newsletter/${ route }`, {
      const response = await fetch(`${ URL_BASE }/newsletter/${ route }`, {
      method: 'post',
        body: JSON.stringify(body),
        headers: { "Content-Type": "application/json" }
      });
      const data = await response.json();
      if ( data.error ) {
        throw new Error( "NewsLetter Function Error: " + data.error );
      }
      return data.data;
    } catch ( error ) {
      console.error( error );
      return false;
    }
  },

  async subscribe( email:string, displayName?:string ) {
    if(!DEVELOPMENT_MODE) {
      return this.post('subscribe', {email, displayName})
    }
    const mockSubscribe = async () => {
      return { email, fake: true }
    }
    return mockSubscribe();
  },
}

DEVELOPMENT_MODE && ( window['NewsletterService'] = NewsletterService );
