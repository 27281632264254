import { GetUserByUid, UserDataService } from './user/UserDataService'
import firebase, { auth, googleProvider, facebookProvider } from '@ven/core/data/firebase'
import { auth as currentAuth, database } from '@ven/shared/core/data/firebase';
import { LogService } from './log/LogService';
import { getRandomAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { GameFunctionsService } from '@ven/shared/core/services/GameFunctionsService';
import { analyticsService } from './AnalyticsService';
import { NewsletterService } from '@ven/shared/core/services/NewsletterService';


export const SignInWithService = async (provider, providerName, keepInformed = true) => {
    let userAuth;
    const oldUid = auth.currentUser?.uid;

    try {
        //link is not working because other account already is with liked with this provider
        // if (isAnon && isAnon === 'true') {
        //     userAuth = await auth.signInWithPopup(provider);
                // userAuth = await auth.currentUser?.linkWithPopup(provider);
        // } else {
            userAuth = await auth.signInWithPopup(provider);
        // }
        
        if (!userAuth) {
            return false;
        }
        const { given_name : username, picture, email } = userAuth.additionalUserInfo.profile;
        if (picture) UserDataService.set('photoUrl', picture);
        const { providerId } = userAuth.credential;
        UserDataService.set('provider', providerId);
        
        await CreateUserOnDb({...userAuth.user, username: username || email, keepInformed, avatar: picture, providerId })
        await GameFunctionsService.updateUserId(userAuth.user.uid, oldUid!)

        const user = await GetUserByUid(userAuth.user.uid);
        if ( ! user ) {
            return;
        }
        
        return true;
    } catch (err) {
        await LogService.log(err, 'Error: Could not sign in with ' + providerName)
        console.log(err);
        return false;
    };
};


export const SignInWithGoogle = async (keepInformed = true) => {
    return await SignInWithService(googleProvider, 'Google', keepInformed);
};

export const SignInWithFacebook = async (keepInformed = true) => {
    return await SignInWithService(facebookProvider, 'Facebook', keepInformed);
};

export const SignInWithCustomToken = async (token, keepInformed = true) => {
    let userAuth;

    try {
        userAuth = await auth.signInWithCustomToken(token);
        if (!userAuth) {
            console.log("it seems to be working")
            return false;
        }

        console.log("tried to create a new user")
        return false;
    //     const { given_name : username, picture, email } = userAuth.additionalUserInfo.profile;
    //     if (picture) UserDataService.set('photoUrl', picture);
    //     const { providerId } = userAuth.credential;
    //     UserDataService.set('provider', providerId);
        
    //     await CreateUserOnDb({...userAuth.user, vip, username: username || email, keepInformed, avatar: picture, providerId })
    //     await GameFunctionsService.updateUserId(userAuth.user.uid, oldUid!)

    //     const user = await GetUserByUid(userAuth.user.uid);
    //     if ( ! user ) {
    //         return;
    //     }
        
    //     return true;
    } catch (err) {
        await LogService.log(err, 'Error: Could not sign in with token')
        console.log(err);
        return false;
    };
};

export const CreateAnonId = async (currentGame = null) => {
    let userAuth;
    try {
        userAuth = await auth.signInAnonymously();
        if (!userAuth) {
            return false;
        }
        await CreateUserOnDb({...userAuth.user, username: 'Anonymous' + new Date().getUTCMilliseconds(), keepInformed: false, avatar: getRandomAvatar(), providerId : 'Anonymous' })
        return userAuth.user.uid;

    } catch (err) {
        await LogService.log(err, 'Error: Could not create anonymous user.')
        console.log(err);
        return false;
    };
};

export const SignUpWithEmailAndPassword = async (username: string, userEmail: string, userPassword: string, keepInformed: boolean) => {
    let userAuth;
    let isAnon = UserDataService.get('isAnon');

    const oldUid = auth.currentUser?.uid;
    try {
        if (isAnon && isAnon === 'true') {
            try {
                const credential = firebase.auth.EmailAuthProvider.credential(userEmail, userPassword);
                userAuth = await auth.currentUser?.linkWithCredential(credential);
            } catch (err) {
                userAuth = await auth.createUserWithEmailAndPassword(userEmail, userPassword);
            }
        } else {
            userAuth = await auth.createUserWithEmailAndPassword(userEmail, userPassword);
        }
        
        if (userAuth) {
            const { providerId } = userAuth.additionalUserInfo;
            
            await CreateUserOnDb({...userAuth.user, username, providerId, keepInformed })
            await GameFunctionsService.updateUserId(userAuth.user.uid, oldUid!)

            return true;
        }

        return false;
    } catch (err) {
        await LogService.log(err, 'Error: Could not sign in with email and password.')
        console.log(err);
        return false;
    }
}


export const CreateUserOnDb = async ({uid, email, providerId, username, keepInformed = false, vip = false, avatar = null }) => {
    const dbRef = database.ref(`/users`);
    let newUserRef = dbRef.child(uid);
    const valueRef = await newUserRef.once('value');
    const value = valueRef.val();
    if(value && (value.providerId && value.providerId != "Anonymous")) {
        analyticsService.Login(uid, providerId, username)
        return;
    }

    const packages = newUserRef.child('packages');
    const packagesValue = await packages.once('value');
    await newUserRef.update({
        uid,
        email,
        providerId,
        username,
        keepInformed,
        avatar,
        packages: {
            ...packagesValue.val(),
        }
    });
    
    if(keepInformed && email) {
        NewsletterService.subscribe(email, username)
    }
    analyticsService.Signup(uid, providerId, username);
}

export const LogInWithEmailAndPassword = async (userEmail: string, userPassword: string) => {
    let userAuth;
    const oldUid = auth.currentUser?.uid;

    try {
        userAuth = await auth.signInWithEmailAndPassword(userEmail, userPassword);

        if (!userAuth) {
            return;
        }
        const { uid } = userAuth.user;

        const user = await GetUserByUid(uid);

        if ( ! user ) {
            return;
        }

        // const dbRef = database.ref(`/users/${uid}/packages/vip`);
        // await dbRef.set(vip ? true : false);

        analyticsService.Login(uid, 'emailAndPassword', userAuth.user.username || user.username)

        await GameFunctionsService.updateUserId(userAuth.user.uid, oldUid!)

        return true;
    } catch (err) {
        await LogService.log(err, 'Error: Could not login with email and password.')
        console.log(err);
        return err.code;
    };
}

export const ResetPassword = async (email: string) => {
    try {
        const actionCodeSettings = {
            url: `https://${process.env.AUTH_DOMAIN}`
        }
        await auth.sendPasswordResetEmail(email, actionCodeSettings);
    } catch (err) {
        await LogService.log(err, 'Error: Could not send password change email.');
        console.log(err);
        return false;
    }
    return true;
}

export const OnChangeHandler = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'displayName') {
        UserDataService.set('username', value);
    }
    else if (name === 'userEmail') {
        UserDataService.set('userEmail', value);
    }
    else if (name === 'userPassword') {
        UserDataService.set('password', value);
    }
};
