import type { PlayerID } from '@ven/shared/core/gameplay/GameSessionData';
import type { GameStateController } from './GameStateController';
import { CanvasData, GamePhase, Submission, TurnType } from './GameStateController';
import { GamePseudoServer as GamePseudoServerBase, GameServerAction } from '@ven/shared/core/gameplay/GamePseudoServer';
import { analyticsService } from '@ven/platform/main/services/AnalyticsService';

type Action = GameServerAction & (
  | {
    type : "submit",
    canvasOrSentence: string|CanvasData,
    forRotation: number
  }
)

/**
 * The intention is for this class to eventually become the de-facto server entity, 
 * which will handle all the common gameplay logic and only take requests from other
 * players in the form of 'actions', then resolve them in the correct sequence.
 */
export class GamePseudoServer extends GamePseudoServerBase<Action,GameStateController,GameStateController['data']>
{
  protected async tick() {
    if ( this.data?.state?.over ) return;
    if ( !this.data?.state?.started ) return;
    if ( !this.data?.state?.turn?.started ) return;
    if ( !this.game.amHost() ) return;
    if ( this.game.amHost() && this.game.amSpectator() ) return;
    
    const { delay, turn, phase } = this.data.state;
    const time = turn?.time; // ?? because 0 is falsy too

    if ( time == undefined ) {
      return;
    }

    if ( delay > 0 ) {
      await this.update.state({ delay : delay - 1 })
      return;
    }
    
    const playerStates = Object.values( this.data.players );
    const allPlayersHaveSubmitedForThisTurn = playerStates.every( p => p.submittedForThisTurn );

    if ( time > 0 ) {
      if ( allPlayersHaveSubmitedForThisTurn ) {
        await this.update.turn({ time : 0 });
      } else {
        await this.update.turn({ time : time - 1 })
      }
    } 
    else {
      
      if ( phase === GamePhase.FirstSentence ) {
        if ( ! allPlayersHaveSubmitedForThisTurn ) {
          if ( this.data.state.timeout > 0 ) {
            await this.update.state({ timeout : this.data.state.timeout - 1 })
          } else {
            const afkPlayers = Object.entries(this.data.players).filter( ([_,p]) => ! p.submittedForThisTurn )
            this.log.trace(`FirstSentence -- submitting instead of `+afkPlayers);
            Promise.all( afkPlayers.map( ([uid,p]) => this.submit( `${ p.username } not writing his sentence`, uid ) ) )
          }
        } else {
          this.log.trace("haveAllPlayersSubmittedForThisTurn => startBreakingTheMessageRotation");
          await this.game.startBreakingTheMessageRotation();
        }
      }
      else 
      if ( phase === GamePhase.BreakingTheMessage ) {
        if ( ! allPlayersHaveSubmitedForThisTurn ) {
          if ( this.data.state.timeout > 0 ) {
            await this.update.state({ timeout : this.data.state.timeout - 1 })
          } else {
            const afkPlayers = Object.entries(this.data.players).filter( ([_,p]) => ! p.submittedForThisTurn )
            this.log.trace(`BreakingTheMessage -- submitting instead of `+afkPlayers);
            Promise.all( afkPlayers.map( ([uid,p]) => 
            this.submit( this.data.state.turn.type === TurnType.Drawing ? p.tempCanvas || [] : '', uid ) ) )
          }
        } 
        else {
          const nextRotationNumber = ( this.data.state.turn.rotation || 0 ) + 1;
          const playersCount = this.game.getPlayersCount();
          if ( nextRotationNumber < playersCount ) {
            this.log.trace(`(${ nextRotationNumber }) < (${ playersCount }) => startNextTurn()`);
            await this.game.startNextTurn( nextRotationNumber );
          } else {
            this.log.trace(`(${ nextRotationNumber }) >= (${ playersCount }) => end()`);
            await this.end();
          }
        }
      }
    }
  }

  async handleAction( action: Action )
  {
    try {
      if ( action.type === "submit" ) {
        if ( action.forRotation !== this.data.state.turn.rotation ) {
          throw new Error(`${ action.sender 
          } tried to submit for rotation ${ action.forRotation 
          } during rotation ${ this.data.state.turn.rotation }`)
        }
        if ( this.data.players[ action.sender ]?.submittedForThisTurn ) {
          throw new Error(`${ action.sender 
          } tried to submit for rotation ${ action.forRotation 
          } but they are already marked as having submitted`)
        }
        await this.submit( action.canvasOrSentence, action.sender )
      }
    } catch ( e ) {
      this.log.error( e );
    }
  }

  //// Actual Gameplay Logic

  async submit( canvasOrSentence:string|CanvasData, submitter:PlayerID ) {
    if ( typeof canvasOrSentence === "string" ) {
      if ( this.data.state.turn.type === TurnType.Drawing ) {
        throw new Error(`I tried to submit a sentence for a drawing` )
      }
    } else {
      if ( this.data.state.turn.type === TurnType.Guessing ) {
        throw new Error(`I tried to submit a drawing for a sentence` )
      }
    }

    this.log.trace(`Submitting ${ 
      typeof canvasOrSentence === "string" ? canvasOrSentence : canvasOrSentence?.length
    } as ${ this.data.players[submitter].username }`);

    const submitterState = this.data.players[ submitter ]

    const originator = submitterState.currentSubject?.originator ?? submitter
    const submission:Submission = typeof canvasOrSentence === "string" 
    ? {
      author: submitter,
      originator: originator,
      rotation : this.data.state.turn.rotation,
      sentence: canvasOrSentence || '?',
      type: "sentence",
    } : {
      author: submitter,
      originator: originator,
      rotation : this.data.state.turn.rotation,
      canvas: 
        canvasOrSentence?.length 
        ? canvasOrSentence 
        : submitterState.tempCanvas || [],
      type: "drawing",
    }
    
    // console.warn({ submitter, submission })

    const storyIndex = this.data.state.turn.rotation;

    await Promise.all([
      storyIndex == 0 
      ? this.update.player( submitter, {
        firstSentence : canvasOrSentence as string || '',
        submittedForThisTurn : true,
      })
      : this.update.player( submitter, { 
        submittedForThisTurn : true,
        tempCanvas: [],
      }),
      this.ref.player( originator ).child('story/' + storyIndex).set(submission)
    ])

    //// After Submission

    // if ( this.game.haveAllPlayersSubmittedForThisTurn() ) {
    //   this.onAllPlayersHaveSubmittedForThisTurn()
    // }
  }

  async end()
  {
    await this.update.state({ over : true, })
    analyticsService.GameCompleted(this.game.gameId, this.game.roomId!);
  }
}
