import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import codeIcon from '../../../assets/images/code_icon.svg';

import './styles.scss';

const JoinRoom: React.FC = () => {
    const [roomCode, setRoomCode] = useState('');
    const history = useHistory();

    const routeUser = (code: string) => {
        if (code !== '') {
            const regex = /[\D]/gi;
            history.push(`/play/${code.trim().replace(regex, '')}`);
        }
    } 

    return (
        <div className="joinroom-landing">
            <div className="joinroom-content">
                <div className="arrow"></div>
                <div className="join-box">
                    <form>
                        <div className="input-box">
                            <img className="input-icon" src={codeIcon} alt="" />
                            <input
                                type="text"
                                name="joincode"
                                placeholder=" Room Code"
                                id="joincode"
                                autoComplete="off"
                                autoFocus={true}
                                onChange={(e) => setRoomCode(e.target.value)}
                            />
                            <div></div>
                        </div>
                        <button className="login-button" onClick={() => routeUser(roomCode)}>Join</button>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default JoinRoom;
