import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Button, Card, Spinner, Tab, Tabs } from 'react-bootstrap';
import { JSONSchema7 } from "json-schema";
import Form from "@rjsf/core";
import { GetCurrentUserData } from '@ven/platform/main/services/user/UserDataService';
import { HostEventsService } from '@ven/shared/core/services/HostEvents';
import SessionInfo from './SessionInfo';
import styled from '@emotion/styled';
import { GAMES } from '@ven/platform/main/games';
import { HostEventContext } from '.';
import _ from 'lodash';


interface CreateSessionProps {
  data?: any;
}

const CreateSession: React.FC<CreateSessionProps> = (props) => {
  const createSession: JSONSchema7 = {
    title: "Create Room",
    type: "object",
    required: ['name', 'game', 'groups'],
    properties: {
      name: { type: "string", title: "Name" },
      game: { type: "string", title: "Game",
              enum: Object.keys(GAMES),
              enumNames: Object.keys(GAMES).map(e => _.capitalize(e)),
              default: Object.keys(GAMES)[0] },
      groups: { type: "number", enum: 
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 
        11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30
      ], title: "Groups", default: 2 },
    }
  };


  const [formData, setFormData] = useState(Object);
  const [userData, setUserData] = useState(Object);

  const {session, setSession} = useContext(HostEventContext);

  const [spinner, setSpinner] = useState(false)
  useEffect(() => {
    GetCurrentUserData().then(userData => {
      setUserData({
        ...userData
      })
    });
  }, []);

  const submit = async (schemeData) => {
    const { formData: submittedFormData } = schemeData;
    submittedFormData.creator = userData.uid;
    submittedFormData.creatorName = userData.username;
    setSpinner(true);
    HostEventsService.createHostEventSession(props.data.id, submittedFormData)
      .then((docRef : any) => {
        setSpinner(false);
        window.history.pushState({}, '', '/eventHost/' + props.data.id + '/' + docRef.id);
        console.log("Document written with ID: ", docRef.id);

        setSession({ id: docRef.id, ...submittedFormData });
      })
      .catch((error) => {
        setSpinner(false);
        console.error("Error adding document: ", error);
      });
  }

  const log = (type) => console.log.bind(console, type);


  return (
    <Wrapper>
      {/* <Helmet>
              <title> Account - Profile | VEN Games </title>
          </Helmet> */}

      <Accordion className={spinner ? "spinner" : ''} defaultActiveKey="0">
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Create Session
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <Form schema={createSession}
                formData={formData}
                // uiSchema={uiSchema}
                onChange={e => { console.log(e); return setFormData(e.formData) }}
                onSubmit={submit}
                onError={log("errors")}
                noHtml5Validate />
              <div className="loading">
                <Spinner animation="border" variant="light"/>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>

    </Wrapper>

  )
}

const Wrapper = styled.div`
.spinner {
  button[type='submit']{
    background-color: gray;
    pointer-events: none;
  }

  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(128, 128, 128, 0.5);
  }
}
legend {
  display: none;
}
fieldset {
  border: 0;
}
`

export default CreateSession;
