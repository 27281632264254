import React, { useMemo } from 'react';
import * as ReactRouter from "react-router-dom";

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';
import type { GameStateController } from '@ven/games/scribbler/gameplay/GameStateController';

import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { LargeText, OverlayDialogBase, SmallText } from './OverlayDialogBase';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { InlineSpacer, Spacer } from '@ven/shared/components/common/atoms/Separator';

import { COLOR_DARK, COLOR_LIGHT, COLOR_PRIMARY, COLOR_TOOL_ACTIVE, COLOR_TOOL_IDLE } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';

//@ts-ignore
import Cup1 from "@ven/components/assets/scribbler/Gold.png"
//@ts-ignore
import Cup2 from "@ven/components/assets/scribbler/Silver.png"
//@ts-ignore
import Cup3 from "@ven/components/assets/scribbler/Bronze.png"
import { PlayAgainButton } from '@ven/shared/components/game/molecules/PlayAgainButton';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

interface Props 
{ 
  game : GameStateController,
}
export const OverlayDialog_GameOver:React.FC<Props> = ({ game }) =>
{ 
  const { data } = React.useContext( GameStateContext )!

  const [redirecting, setRedirecting] = React.useState(false)
  const nextGameUrl = data?.state.nextGameUrl;
  
  const players = Object
    .entries( data?.players || {} )
    .map( ([ id, playerData ]) => ({ id, ...playerData }) )
    .sort( (a,b) => b.score - a.score )
  
  const history = ReactRouter.useHistory()

  const getPlacementBit = (i:number) => {
    if ( i === 0 ) return <img src={ Cup1 } />
    if ( i === 1 ) return <img src={ Cup2 } />
    if ( i === 2 ) return <img src={ Cup3 } />
    return <Placement> { i + 1 }th </Placement>
  }

  const Base = styled(OverlayDialogBase)`
    width: 405px;
    max-width: 90%;
    padding: 20px 12px;
  `

  return (
    useMemo(() => 
    <Base 
    dimColor="#303744" 
    confetti={ true }
    popAnimation={ !redirecting && !nextGameUrl }
    >
      {
        data?.state.turn.word ?
        <div>
          <SmallText> That’s Game! The word was </SmallText>
          <Spacer size={ 6 } />
          <Word> { data?.state.turn.word } </Word>
        </div>
        : <div>
          <SmallText> That’s Game! Last Round was skipped </SmallText>
        </div>
      }
      <Spacer size={ 20 } />
      <FinalScore> Final Scores </FinalScore>
      
      <PlayerTableContainer>
        <PlayerTable>
          {
            players.map( (p, i) => (
            <PlayerListItem key={ p.id }>
              <PlacementWrapper>{ getPlacementBit( i ) } </PlacementWrapper>
              <PlayerAvatar size={ 32 } index={ p.avatar }/> 
              <PlayerName> { p.username } </PlayerName>
              <Score>
                { p.score || 0 }
              </Score>
            </PlayerListItem>
          ) )
              }
        </PlayerTable>
      </PlayerTableContainer>

      <Spacer size={ 20 } />

      <PlayAgainButton gameName='scribbler'
          nextGameUrl={nextGameUrl} 
          isEventHost={game.data.eventHost}
          isHost={game.amHost()} 
          isSpectator={game.amSpectator()}
          updateState={game.update.state} 
          setRedirecting={setRedirecting} isRedirecting={redirecting}
        />
      
      <Spacer size={ 20 } />
      
      <GreenButton onClick={ () => history.push("/") }> See all Games </GreenButton>
    </Base>
    , [nextGameUrl, game.amHost(), redirecting])
  )
}

const Placement = styled.span`
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  line-height: 15px;
  color: #A19AC6;
  padding-left: 8px;   /** hack */
`

const Word = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #303744;
`
const FinalScore = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #303744;
`

const PlayerTableContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  min-width: 292px;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
`

const PlayerTable = styled.div`
  width: 100%;
  min-width: 200px;
  border-spacing: 0 20px; 
  border-collapse:separate;
`

const PlayerListItem = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    "place avatar playerName playerName score";

  margin-bottom: 20px;
  width: 100%;
  gap: 5px;
  overflow: hidden;
`
const PlacementWrapper = styled.div`
  min-width: 35px;
  align-items: center;
  grid-area: place;
`

const Score = styled.span`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${ COLOR_PRIMARY };
  text-align: right;
  min-width: 50px;

  @media (max-width: ${ MOBILE_BREAK_PX }px) {
    font-size: 18px;
    text-align: center;
  }
  grid-area: score;
`

const PlayerName = styled.span`
  padding-right: 5px;
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: ${ COLOR_DARK };
  text-align: left;
  max-width: 180px;
  grid-area: playerName;
`