/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from '@emotion/styled';
import { COLOR_GRAY } from "@ven/shared/components/styling/colors";

import React from 'react';

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';
import type { PlayerID } from '@ven/shared/core/gameplay/GameSessionData';

import { WordOrHint } from "../atoms/WordOrHint";

export const WordOverCanvas:React.FC<{myUserId:PlayerID}> = ({ myUserId }) =>
{
  const { game, data } = React.useContext( GameStateContext )!

  const myTurn = data?.state.turn.player === myUserId && !game.amSpectator()
  const whole = myTurn || !! data?.players[ myUserId ]?.guessed

  const word = data?.state?.turn.word && 
  ( 
    whole ? 
    data?.state?.turn.word : 
    data?.state?.turn.wordHint 
  ) || '...'

  return (
    <div 
    css={ css`
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 12px;
      font-size: 10em;
      pointer-events: none;

      // hack to fix hiding behind canvas on some screen sizes! o.O
      transform: translateZ(100px);
      
      @keyframes word-over-canvas { from { transform: translateZ(100px) scale(.125); } }
      animation: word-over-canvas 500ms ease-out;
    `}>
      <div
      css={ css`
        font-family: Bitter;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: ${ COLOR_GRAY };
        margin-bottom: 4px;
        pointer-events: none;
      `}>
        { myTurn ? "Draw this" : "Guess this" }
      </div>
      <WordOrHint word={ word } whole={ whole } />
    </div>
  )
}
