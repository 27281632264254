import React from 'react';

//// Types

export const configuration = 
{
  canvas : 
  {
    width : 650, 
    height : 628,
    minLineLength : 5,
  },
  tools : 
  {
    brushColors : [ 0xFFFFFF, 0xa19ac6, 0x303744, 0xf1be09, 0xf47c63, 0x2fb586, 0x5a91ef, 0xfc3b68, 0x3148bf, 0xCC0000 ],
    brushSizes : [ 8, 16, 24, 48 ],
  }
}

export interface DrawingSettings
{
  brushColor : number,
  brushSize : number,
  tool : DrawingTool,
}

export enum DrawingTool
{
  Draw = "draw",
  Erase = "erase",
}

//// Choices & default values

export const DEFAULT_SETTINGS:DrawingSettings = {
  brushColor : configuration.tools.brushColors[ 1 ],
  brushSize : configuration.tools.brushSizes[ 0 ],
  tool : DrawingTool.Draw,
}

//// React context & provider

const reducer = ( current:DrawingSettings, updates:Partial<DrawingSettings> ) => 
( { ...current, ...updates } as DrawingSettings )

type DrawingSettingsContextValue = { settings : DrawingSettings, updateSettings : React.Dispatch<Partial<DrawingSettings>> }

export const DrawingSettingsContext = React.createContext<DrawingSettingsContextValue>({ 
  settings : DEFAULT_SETTINGS, 
  updateSettings : null as any 
});

export const DrawingSettingsProvider:React.FC<any> = ({ children }) => 
{
  const [ settings, updateSettings ] = React.useReducer( reducer, DEFAULT_SETTINGS );

  return (
    <DrawingSettingsContext.Provider value={{ settings, updateSettings }}>
      { children }
    </DrawingSettingsContext.Provider>
  );
}
