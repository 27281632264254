import React, { useMemo } from 'react';

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';
import type { PlayerID } from '@ven/shared/core/gameplay/GameSessionData';

import { SelfScalingContent } from '@ven/shared/components/game/wrappers/SelfScalingContent';
import { MobileInGameContent } from './mobile/MobileInGameContent';
import { useWindowSize } from '@ven/shared/components/hooks/useWindowSize';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { Dimensions } from '../../other/dimensions';
import { DesktopInGameContent } from './desktop/DesktopInGameContent';

import { LastSecondsBuzzer } from '@ven/shared/components/common/misc/LastSecondsBuzzer';
import { MainHeader } from '@ven/platform/main/components/organisms/MainHeader';
import { OrientationChecker } from '@ven/shared/components/common/atoms/OrientationChecker';
import { isIPad } from '@ven/core/utils/deviceDetectionUitl';
import { audioService } from '@ven/core/services/SoundService';

//@ts-ignore
import TimerSound from "@ven/shared/components/assets/sounds/Timeisup_7Seconds.mp3"
//@ts-ignore
import CorrectSound from "@ven/shared/components/assets/sounds/correct.mp3"
import styled from '@emotion/styled';
import { BottomRibbon } from '@ven/shared/components/common/atoms/BottomRibbon';
interface Props 
{ 
  myUserId : PlayerID,
}
export const InGameTemplate:React.FC<Props> = ({ myUserId }) =>
{
  const { game, data } = React.useContext( GameStateContext )

  const { width, height } = useWindowSize();
  const USE_MOBILE_LAYOUT = width <= MOBILE_BREAK_PX;
  const designDimensions = { ...USE_MOBILE_LAYOUT ? Dimensions.mobile : Dimensions.desktop }

  if ( height <= MOBILE_BREAK_PX ) {
    designDimensions.insetY = 0;
  }

  useMemo(() => {
    audioService.preload(TimerSound);
    audioService.preload(CorrectSound);
  }, [])

  if ( ! data?.state?.turn?.player )
  {
    return null
  }

  return (
    <Wrapper className='in-game-scribbler'>
      {
        !USE_MOBILE_LAYOUT && height > MOBILE_BREAK_PX ? <MainHeader inGame={ true } /> : null
      }
      
      <SelfScalingContent className={`inner-content scribbler ${USE_MOBILE_LAYOUT ? 'mobile' : 'desktop'}`} designDimensions={ designDimensions } fixed={ true } alignTop={true}>
        <LastSecondsBuzzer game={ game }/>
        {
          USE_MOBILE_LAYOUT 
          ? <MobileInGameContent myUserId={ myUserId } />
          : <DesktopInGameContent myUserId={ myUserId } />
        }
        { 
        USE_MOBILE_LAYOUT 
        ? null 
        : <BottomRibbon game={game} callbacks={{ showHowToPlay : null }} /> 
      }
      </SelfScalingContent>
      {isIPad() ? <OrientationChecker/> : null}
    </Wrapper>
  )
}


const Wrapper = styled.div``
