import React, { useMemo } from 'react';

import { GameStateContext } from '../../../gameplay/GameStateContext';

import styled from '@emotion/styled';
import { PlayerUpBit } from '../../organizms/PlayerUpBit';
import { Team } from '@ven/games/wordplay/gameplay/GameStateController';
import { PlayersModalContext } from '@ven/shared/components/common/PlayersModal';
import { ScoreInput } from '@ven/shared/components/common/ScoreInput';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';

export const ScoreBoard:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )
  const myUserData = React.useContext( UserDataContext );
  
  const data = game.data!

  const { show, setShow } = React.useContext( PlayersModalContext )

  const myUserId = game.myUserId!
  const myState = game.data.players[ myUserId ]
  const myTeam = myState?.team ?? 0

  const nextPlayer = data.state.turn.started ? game.getNextPlayer() : data.state.turn.player

  const NextPlayerUpBit = styled(PlayerUpBit)`
    width: 214px;
    height: 48px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;

    .text {
      font-size: 12px;
      line-height: 14px;
    }
  `

  return (
    <Wrapper>
          <RoundsBit>
            Leaderboard
          </RoundsBit>
          {
        useMemo(() => {
          const teams = [ 
            Object.values( data.players || {} ).filter( p => p.team === Team.Red ), 
            Object.values( data.players || {} ).filter( p => p.team === Team.Blue ),
          ]
          
          return <ScoreCard>
            { myUserData.eventHost && 
             <EditScoreInstruction>You can edit scores because you are an Event host</EditScoreInstruction>}
             {/* { (game.amHost() || myUserData.eventHost) && 
             <EditScoreInstruction>You can edit scores because you are{
                game.amHost() && myUserData.eventHost ? " the Boss and a Event host" :
                game.amHost() ? " the Boss" : " a Event host"
             }</EditScoreInstruction>} */}
             
            <PlayerListItem className={ myTeam === 1 ? 'mine' : '' }> 
              <span className="name" onClick={() => setShow(true)}> { game.getTeamName(1) } </span>
              {
                myUserData.eventHost
                //game.amHost() || myUserData.eventHost
                ? <ScoreInput 
                  currentValue={ data.state.scores ? data.state.scores[1] : 0 }
                  updateCallback={value => {game.updateScoreManually(value, 1, myUserId, myUserData.username)}}/>
                : <span className="score"> { data.state.scores ? data.state.scores[1] : 0 }  </span>
              }
             
              {/* !props.data ? <Spinner animation="border"/> : <InfoInput data={...props.data}/>} */}
             
            </PlayerListItem>
            {/* <PlayerListItemName>
            {
              teams[0].map(p => <span>{p.username}</span> )
            }
            </PlayerListItemName> */}
            <PlayerListItem className={ myTeam === 2 ? 'mine' : '' }> 
              <span className="name" onClick={() => setShow(true)}> { game.getTeamName(2) } </span>
              {
                myUserData.eventHost
                ? <ScoreInput 
                  currentValue={ data.state.scores ? data.state.scores[2] : 0 }
                  updateCallback={value => {game.updateScoreManually(value, 2, myUserId, myUserData.username)}}/>
                : <span className="score"> { data.state.scores ? data.state.scores[2] : 0 }  </span>
              }
            </PlayerListItem>
            <ShowPlayerModalInstruction onClick={() => setShow(true)}>click to see players</ShowPlayerModalInstruction>
            
          </ScoreCard>
         } ,[data.state.scores])
        }
      
        <BottomBit>
        {
          nextPlayer && !data.state.over &&
          <NextPlayerUpBit playerId={ nextPlayer } />
        }
        </BottomBit>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  top: 130px; 
  left: 22px; 
  /* transform: translate(-0%, -50%);  */
  position: absolute;
  width: 267px;
  height: 430px;
`

const PlayerListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 35px;
  margin: 5px 0;
  position: relative;

  & .name {
    font-family: Bitter;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    line-height: 24px;
    
  cursor: pointer;
  }

  &.mine::after {
    content: '';
    position: absolute;
    width: 5px;
    /* height: 21px; */
    left: 0;
    top: 15%;
    bottom: 5%;
    background: #F47C63;
    border-radius: 0px 4px 4px 0px;
  }

  &.mine .name {
    border-bottom: 2px solid #F47C63;
  }
  
  & .score {
    font-family: Bitter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 41px;
    line-height: 31px;

    color: var(--outlaw-headers);
  }
`

const PlayerListItemName = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  width: 100%;
  padding: 0px 35px;
  margin: 5px 0;
  position: relative;

  span {
    font-size: 8px;
  }
`

const EditScoreInstruction = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 58px;
  border-radius: 12px 0 0 0;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  padding: 0 10px;
`

const ShowPlayerModalInstruction = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 58px;
  border-radius: 12px 0 0 0;

  
  cursor: pointer;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
`

const RoundsBit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 58px;
  border-radius: 12px 0 0 0;

  background:  var(--outlaw-headers);
  color: #FFFFFF;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
`

const ScoreCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  overflow: hidden;
  width: 100%;
  height: 285px;
  border-radius: 0 0 0 12px;
  padding-top: 30px;

  box-shadow: -6px 7px 28px rgba(0, 0, 0, 0.0819766);
  background: #F9F9FF;
  color: #303744;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 31px;

  z-index: 1;
`

const BottomBit = styled.div`
  width: 100%;
  height: 102px;
  margin-top: -12px;
  border-radius: 0 0 0 12px;

  background: var(--outlaw-headers);
  color: #FFFFFF;

  position: relative;
`
