import React, { useState } from 'react';

import styled from '@emotion/styled';
import Modal from 'react-bootstrap/esm/Modal';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import { Team } from '@ven/games/wordplay/gameplay/GameStateController';


type Props = {
  game: any,
  selectedPlayer:any,
  show:boolean,
  setShow:Function,
  kick?:boolean
  changeTeams?:boolean
}

export const HostPowers:React.FC<Props> = ({ game, selectedPlayer, show, setShow, kick= true, changeTeams= false }) => {
  
  const [modelLoading, setModalLoading] = useState(false);

  const handleClose = () => setShow(false);

  const transferHost = async (data) => {
    setModalLoading(true);
    await game.ref.host().set( data.uid );
    setModalLoading(false);
    setShow(false);
    window.scrollTo({ top : 0 });
    // await game.events.dispatch( 'hostChanged', data.uid, previousHost );
  }

  const kickPlayer = async (data) => {
    setModalLoading(true);
    await game.ref.players().child(data.uid).remove();
    setModalLoading(false);
    setShow(false);
    // await game.events.dispatch( 'hostChanged', data.uid, previousHost );
  }

  const toggleTeam = async (data) => {
    setModalLoading(true);
    await game.ref.players().child(data.uid).update({team: data.team == Team.Red ? Team.Blue : Team.Red});
    setModalLoading(false);
    setShow(false);
    // await game.events.dispatch( 'hostChanged', data.uid, previousHost );
  }

  return <Modal show={show} onHide={handleClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          {modelLoading ? "loading" : 
        <ListGroup variant="flush">
          <ListGroup.Item action onClick={() => { transferHost(selectedPlayer); }}>Promote <b>{selectedPlayer.username} to Boss</b></ListGroup.Item>
          { !kick ? null : <ListGroup.Item action onClick={() => { kickPlayer(selectedPlayer); }}>Kick <b>{selectedPlayer.username}</b></ListGroup.Item>}
          { !changeTeams ? null : <ListGroup.Item action onClick={() => { toggleTeam(selectedPlayer); }}>Toggle <b>{selectedPlayer.username}</b> Team</ListGroup.Item>}
        </ListGroup>
        }
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>

}
