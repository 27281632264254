import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { CouponService } from '@ven/shared/core/services/CouponService';
import React, { useState } from 'react';

import './styles.scss'

interface ChooseCardProps {
    coupon: any,
    onActiveButton: Function,
    dateFormat: any,
    unusedDateFormat: any
}

const CouponItem: React.FC<ChooseCardProps> = ({
    coupon,
    onActiveButton,
    dateFormat,
    unusedDateFormat
}) => {
    let date2 = new Date(coupon.activationDate);
    date2.setTime(date2.getTime() + CouponService.getBundleExpirationTime());

    if(coupon.overwriteExpireDate) {
        date2 = new Date(coupon.overwriteExpireDate);
    } 
    const formatCode = (code: string) => {
        const splitCode = code.split('');
        let formattedCode: Array<string> = [];
        for (const [index, value] of splitCode.entries()) {
            if (index % 4 === 0 && index > 0) {
                formattedCode.push('-');
            }
            formattedCode.push(value);
        }
        return formattedCode.join('');
    }

    return (
        <div className={`coupon ${coupon.state === 'ACTIVE' ? 'activate' : ''}`}>
            <p className="coupon-name">{`${coupon.name && coupon.type != coupon.name ? coupon.name + ' ' : ''}${coupon.type}`}</p>

            { coupon.state === 'ACTIVE'
                ? <p className="coupon-status">active</p> :
                coupon.state === 'USED'
                    ? <p className="coupon-status">USED</p>
                    : <GreenButton className="coupon-status activate" onClick={() => onActiveButton(coupon)}>Activate</GreenButton>
            }

            <div className="code">
                <p className="label">Code: {formatCode(coupon.code)}</p>
            </div>
            {<div className="exp-date">
                <p className="label">
                    {!coupon.expireDate ? null
                        : coupon.state === 'UNUSED'
                            ? `Valid until ${new Date(coupon.expireDate).toLocaleString('en', unusedDateFormat)}`
                            : `${coupon.state === 'USED' ? 'Ended ' : 'Ends'} on ${date2.toLocaleString('en', dateFormat)}`
                    }
                </p>
            </div>
            }
        </div>
    )
};

export default CouponItem;
