import firebase from "firebase";

import type { GameSessionData, PlayerID } from "../GameSessionData";
import type { GameStateController } from "../GameStateController";

export interface WithLogging {
  debug: Record<PlayerID,{
    logs: Record<string,{
      time: typeof firebase.database.ServerValue.TIMESTAMP | number,
      hostLastTick: string,
      msg: string,
      author: string, 
      severity: 'info'|'error',
      stack?: Error['stack']
    }>
  }>
}

export type LogEntry = WithLogging['debug']['']['logs']['']

export class LoggingFeature<T extends GameStateController<GameSessionData&WithLogging>> {
  constructor ( private readonly game:T ) {
    window.addEventListener("error", e => {
      this.error(e as any);
    });
  }

  public trace(msg:string) {
    // console.trace(`TRACE: ${ msg }`, )
    console.log(`TRACE: ${ msg }`, )
    this.addLogEntry( `[TRACE] ` + msg, 'info' );
  }

  public error(error:string|Error) {
    console.error(`ERROR: ${ error }`)
    this.addLogEntry( 
      `[ERROR] ` + error, 'error', 
      error instanceof Error && error.stack
    );
  }

  private addLogEntry(msg:string, severity:LogEntry['severity'], stack?:any ) {
    try {
      const data:LogEntry = {
        msg: msg,
        author: this?.game?.getMyState()?.username || 'unnamed',
        hostLastTick: this.game.data.players[this.game.data.host!].lastOnline ?? 'unknown',
        time: firebase.database.ServerValue.TIMESTAMP,
        severity,
        ...stack && { stack },
      }
      this.game.ref.room().child(`debug/${ this.game.myUserId }/logs`).push( data );
    } catch( e ) {
      console.error( e )
    }
  }
}
