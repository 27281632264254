import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { MAIN_HEADER_HEIGHT_PX, MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { GetCurrentUserData, UserDataService } from '../../services/user/UserDataService';
import MobileMenu from "./MobileMenu"
import JoinRoom from "./JoinRoom"
import Login from '../pages/Login';

import styled from '@emotion/styled'

// @ts-ignore
import LogoImage from "@ven/components/assets/logo.png"
// @ts-ignore
import LogoDarkImage from "@ven/components/assets/logo-dark.png"
// @ts-ignore
import HamburgerIcon from "@ven/platform/main/assets/images/hamburger.svg"
import { GameFunctionsService } from '@ven/shared/core/services/GameFunctionsService';
import ChooseHowToPlay from '../pages/ChooseHowToPlay';
import DirtyModalService from '@ven/core/utils/dirtyModalService';
import { SimpleCombobox } from '@ven/shared/components/common/atoms/SimpleCombobox';
import { auth, database } from '@ven/shared/core/data/firebase';
import { UserDataContext } from '../../services/user/UserDataContext';

const HeadDiv = styled.div`
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  
  background: none;
  position: relative;
  z-index: 9;
  max-width: 1250px;
  min-width: 850px;
  width: 100%;
  height: ${MAIN_HEADER_HEIGHT_PX}px;
  padding: 0 85px;

  top: 0;

  transition: top 200ms;

  pointer-events: none;

  &>* {
    pointer-events: auto;
  }

  span, a {
    color: white;
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 47px;
  }
  
  span img {
    height: 48px;
  }

  a {
    text-decoration: none;
    flex-grow: 1;
  }

  div {
    flex-grow: 8;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  div .login {
    border: 0;
    background: transparent;
    font: 400 1.8rem Oswald;
    color: #FFFFFF;
    width: 10.0rem;
  }

  div .signup {
    border: solid 0.2rem #FFFFFF;
    border-radius: 0.8rem;
    background: transparent;
    font: 400 1.8rem Oswald;
    color: #FFFFFF;
    width: 14.0rem;
    height: 4.0rem;
    margin-right: 1.0rem;
  }

  div .join {
    border: 0;
    border-radius: 0.8rem;
    background: #FFFFFF;
    font: 400 1.8rem Oswald;
    color: #303744;
    width: 14.0rem;
    height: 4.0rem;
    margin-left: 2.0rem;
  }

  div a {
    flex-grow: unset;
    width: 80px;
  }

  button {
    cursor: pointer;
  }

  .logo-image {
      cursor: pointer;
    }

  .hamburger,
  .mobile-menu-container {
    display: none;
  }

  .no-scroll {
    overflow: hidden;
  }  

  @media (max-width: ${MOBILE_BREAK_PX}px), (max-height: ${MOBILE_BREAK_PX}px) {
    // top: -${MAIN_HEADER_HEIGHT_PX}px;
    // display: none;
    max-width: unset;
    min-width: unset;
    width: 90%;
    height: 10.0rem;
    padding: 0;

    .logo-image {
      display: none !important;
    }

    .hamburger {
      display: block;
      // change to block to show it on mobile
      flex-grow: 1;
    }

    [id="app"] {
      overflow: hidden;
    }

    div .signup {
      display: none;
    }
  
    div .join {
      border: 0;
      border-radius: 0.8rem;
      background: #FFFFFF;
      font: 400 1.4rem Oswald;
      color: #303744;
      width: 10.0rem;
      height: 3.0rem;
      margin-left: 2.0rem;
    }

    div .login {
      display: none;
    }
  
  }
`

export const MainHeader: React.FC<{ inGame?: boolean, darkTheme?: boolean}> = ({ inGame = false, darkTheme = false }) => {
  const history = useHistory();
  const [loginModal, setLoginModal] = useState(false);
  const [modalView, setModalView] = useState('login');
  const [modalCHTPView, setModalCHOTPView] = useState(0);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [chooseModal, setChooseModal] = useState(false);
  const [showJoinRoom, setShowJoinRoom] = useState(false);
  const [username, setUsername] = useState('Account');

  const myUserData = React.useContext( UserDataContext );

  DirtyModalService.setOpenChooseHowToPlayModal(() => {
    UserDataService.clear('choosenGame');
    setChooseModal(!chooseModal);
  })

  const currentPath = useLocation().pathname.replace('/overview', '') ////HACK 

  useEffect(() => {
    if(myUserData?.username)
      setUsername(myUserData?.username);
  }, [ myUserData ])

  const isAnon = UserDataService.get('isAnon');

  const logout = async () => {
    const oldUid = auth.currentUser?.uid;
    UserDataService.set('previousUid', oldUid!)
    await auth.signOut();
    if(!inGame) {
      window.location.reload(); //temp fix for account page 
    }
  }

  const divStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  };

  const openModal = (view: string) => {
    setChooseModal(false);
    setMobileMenu(false);
    setModalView(view);
    setLoginModal(true)
  }

  const playGame = (view: number) => {
    const views = ['outlaw', 'scribbler', 'brokenmsg']
    const choosenGame = views[view] || views[0];

    UserDataService.set('choosenGame', choosenGame);

    const uid = myUserData.uid;
    const isAnon = UserDataService.get('isAnon');

    if (uid && isAnon && isAnon === 'false') {
      GameFunctionsService.createRoom(views[view], uid)
        .then((data) => data && history.push(`/play/${data.code}`));
      // history.push(`/${choosenGame}`)
    } else {
      window.scroll(0, 0);
      setModalCHOTPView(0);
      setMobileMenu(false);
      setLoginModal(false)
      setChooseModal(true);
    }
  }

  const clickedContinueWithoutRegister = () => {
    setChooseModal(false);
    window.scroll(0, 0);
  }

  const userMenuOptions =
    [ 
      {
        name:"Account", 
        link:"/account"
      },
      {
        name:"Logout", 
        onClick:logout
      }
    ];
    
    if(myUserData.packEditor) {
      userMenuOptions.splice(1, 0, 
        {
          name:"Pack Editor", 
          link:"/packs"
        })
    }

    if(myUserData.eventHost) {
      userMenuOptions.splice(1, 0, 
        {
          name:"Event Host", 
          link:"/eventHost"
        },
        {
          name:"Vip Panel", 
          link:"/vipPanel"
        })
    }

  return (
    <div style={divStyle} className={'main-header ' + (mobileMenu ? 'no-scroll' : '')}>
      {/* Login Modal */}
      { loginModal &&
        <div className="login-modal">
          <div className="full-screen-close" onClick={() => setLoginModal(false)} />
          <Login loginCloseCallback={() => setLoginModal(false)} activeView={modalView}></Login>
        </div>
      }

      {/* Mobile Menu */}
      { mobileMenu &&
        <MobileMenu
          login={() => openModal('login')}
          signup={() => openModal('signup')}
          close={() => setMobileMenu(false)}
          outlaw={() => playGame(0)}
          scribbler={() => playGame(1)}
          brokenMsg={() => playGame(2)}
          logout={logout}
          account={() => {  window.location.assign('/account'); }}
        />
      }

      {/* Choose How To Play Modal */}
      {chooseModal && (
        <div className="choose-modal">
          <div className="full-screen-close" onClick={() => setChooseModal(false)} />
          <ChooseHowToPlay chooseCloseCallback={clickedContinueWithoutRegister} modalView={modalCHTPView}
          loginCallback={() => openModal('login')} 
          signupCallback={() => openModal('signup')}></ChooseHowToPlay>
        </div>
      )}
      <HeadDiv className="options" >
        <img src={HamburgerIcon} className="hamburger" alt="Menu" onClick={() => setMobileMenu(true)} />
        <span onClick={() => { window.location.assign('/'); }} className="logo-image">
          <span> <img src={!darkTheme ? LogoImage : LogoDarkImage} /> </span>
        </span>
        <div>
           {
            (isAnon && isAnon === 'false') &&
            <SimpleCombobox name={username ? username : 'Account'} options={
              !inGame ? userMenuOptions : []}>
            </SimpleCombobox>
          }
          {
            !inGame && (!isAnon || isAnon === 'true') &&
            <div>
              <button className="login" onClick={() => openModal('login')}>Log In</button>
              <button className="signup" onClick={() => openModal('signup')}>Sign Up</button>
            </div>
          }
          {!inGame &&
            <button className="join" onClick={() => setShowJoinRoom(!showJoinRoom)}>Join a Room</button>
          }
          {showJoinRoom &&
            <JoinRoom />
          }
        </div>

      </HeadDiv>
    </div>
  )
}
