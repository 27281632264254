import React from 'react';
import firebase from 'firebase';

import { auth, database } from '@ven/core/data/firebase';

import type { GameStateController } from '@ven/core/gameplay/GameStateController';
import type { GameSessionData } from '@ven/core/gameplay/GameSessionData';

export const useUserOnlineStatus = (game: GameStateController<GameSessionData>) => {
  const ready = !!game
    && !!game.myUserId
    && !!game.data
    && !!game.data.players[game.myUserId]

  return React.useEffect(() => {
    if (!ready) {
      return;
    }
    const modeOnline = game.amSpectator() ? 'spectator' : 'online';
    const lastModeOnline = game.amSpectator() ? 'lastSpectatorOnline' : 'lastOnline';
    const setAsOnline = () => {
      game.ref.presence(game.myUserId).update({
        [modeOnline]: true,
        [lastModeOnline]: firebase.database.ServerValue.TIMESTAMP,
      })
    }
    const backOnlineOff = game.ref.presence(game.myUserId).on('value', o => {
      const presence = o.val();
        if (presence && !presence[modeOnline])
          setAsOnline();
    });

    let infoConnected;
    try {
      setAsOnline();
      infoConnected = database.ref(".info/connected")
        .on('value', snap => !!snap.val() && setAsOnline());
      game.ref.presence(game.myUserId).onDisconnect().update({
        [modeOnline]: false,
        [lastModeOnline]: firebase.database.ServerValue.TIMESTAMP,
      });
    } catch (e) {
      console.warn(e);
    }
    
    return () => {
      game.ref.presence(game.myUserId).off('value', backOnlineOff);
      if (infoConnected)
        database.ref(".info/connected").on('value', infoConnected)
      // window.removeEventListener('popstate', checkURL);
    }
  }, [ready]);
}
