import React from 'react';

import type { GameStateController } from '@ven/core/gameplay/GameStateController';
import type { GameSessionData } from '@ven/core/gameplay/GameSessionData';

import { getTimeString36 } from '@ven/core/utils/getTimeString36';

export const useSingleTabSession = <TData extends GameSessionData, TGame extends GameStateController<TData>> ( game: TGame ) => 
{
  const [tabHash,setTabHash] = React.useState<string>()

  // const joined = !! game.data && !! game.getMyState();
  const joined = !!game 
             && !!game.myUserId 
             && !!game.data 
             && (!game.amSpectator() && !!game.data.players[ game.myUserId ])
  
  React.useEffect( () => {
    if ( !joined ) {
      return;
    }
    if ( !tabHash ) {
      const tabHash = getTimeString36();
      setTabHash( tabHash )
      console.log( `set single-tab hash to ${ tabHash }`)
      game.update.me({ tabHash : tabHash } as Partial<TData['players'][0]>)
    }
  }, [joined] )

  return ! joined || ! tabHash || ! game.getMyState()?.tabHash
    ? undefined : tabHash === game.getMyState().tabHash;
}
