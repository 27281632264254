import React from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';
import { PlayerAvatar } from '../../../../shared/components/game/molecules/PlayerAvatar';
import { MessageInput } from '../molecules/MessageInput';
import type { ChatMessage } from '@ven/games/scribbler/gameplay/GameStateController';

import { COLOR_DARK, COLOR_GRAY, COLOR_PRIMARY } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';
import { UserDataContext, UserDataProvider } from '@ven/platform/main/services/user/UserDataContext';

interface Props 
{ 
  history : Record<string, ChatMessage>
  handleSend? : (message:string) => any
}
export const ChatPanel:React.FC<Props> = ({ history, handleSend }) =>
{
  const myUserData = React.useContext( UserDataContext );
  const { game, data } = React.useContext( GameStateContext );
  const turnPlayerAvatar =  data!.state.turn.player!;
  const username = data?.players[turnPlayerAvatar]?.username
  const turn = data?.state?.turn?.number || 0;

  const messageList = React.useRef<HTMLDivElement>(null);
  
  React.useEffect(() => {
    if(messageList.current)
      messageList.current.scrollTop = messageList.current.scrollHeight;
  }, [history]);

  return (
    <Wrapper> 
      <Header> {`${username}`} is drawing now! </Header>
      <MessagesContainer className="chat-messages-container" ref={messageList}>
        <MessageList>
          {
            Object.entries( history ).map( ([id,m]) => (
              m.sender === '' 
              ?
                !m.to || m.to == myUserData.uid
                ?
                <Message key={ id } className={`${m.turn && m.turn != turn ? 'transparent' : '' } system`}> 
                  <span className="text"> { m.text } </span>
                </Message>
                : null
              : <Message key={ id } className={m.turn && m.turn != turn ? 'transparent' : '' }> 
                <PlayerAvatar size={ 26 } index={ data?.players[ m.sender ]?.avatar }/>
                <span className="sender"> { data?.players[ m.sender ]?.username }: </span>
                <span className="text"> { m.text } </span>
              </Message>
            ) )
          }
        </MessageList>
      </MessagesContainer>
      { !game.amSpectator() && handleSend && <MessageInput handleSend={ handleSend } /> }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #F9F9FF;
  border-radius: 12px 12px 12px 0;
  height: 100%;

  .chat-message-input-panel{
    overflow: visible;
  }
`
const Header = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 52px;
  border-radius: 12px;
  padding: 20px;
  background: white;
  color: ${ COLOR_GRAY };
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
`
const MessagesContainer = styled.div`
  padding: 0;
  margin: 0;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: flex-end; */
`
const MessageList = styled.ul`
  margin-top: auto;
  padding: 0;
  margin: 0;
`
const Message = styled.li`
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start; */
  text-align: start;
  padding: 8px 20px;

  & div {
    float: left;
    padding-right: 5px;
  }

  & span {
    font-family: Oswald;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    color: ${ COLOR_DARK };
  }

  & span.sender {
    font-weight: bold;
  }

  &.system span {
    font-weight: bold;
    color: ${ COLOR_PRIMARY };
  }

  &.transparent * {
    opacity: 0.3;
  }
`
