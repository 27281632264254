import React, { useEffect } from 'react';
import * as ReactRouter from "react-router-dom";
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';

import "bootstrap/dist/css/bootstrap.min.css";
import "@ven/platform/main/styles/app.scss"
import 'react-toastify/dist/ReactToastify.css';

// import PreRelease from '../components/pages/PreRelease';
import FriendRegistration from '../components/pages/FriendRegistration';
import PostRelease from '../components/pages/PostRelease';
import PostTeamRelease from '../components/pages/PostTeamsRelease';
import Account from '../components/pages/Account';
import PackEditor from '../components/pages/PackEditor';
// import Login from '../components/pages/Login';
// import Signup from '../components/pages/Signup';
// import ChooseHowToPlay from '../components/pages/ChooseHowToPlay';
import GameNightSteps from '../components/pages/GameNightSteps';
import TermsAndConditions from '../components/pages/Meta/TermsAndConditions';
import { RoomCodeRouteWrapper } from './RoomCodeRouteWrapper';
import { database } from '@ven/core/data/firebase';
import { GameRoomLogsPage } from './debug/GameRoomLogs';
import { UserDataProvider } from '../services/user/UserDataContext';
import firebase from '../../../shared/core/data/firebase';
import PackInit from './organisms/PackRedirect/PackInit';
import PackConfirmation from './organisms/PackRedirect/PackConfirmation';
import { analyticsService } from '../services/AnalyticsService';
import { DEVELOPMENT_MODE } from '@ven/shared/core/debug/consts';
import { ConsoleDebugFunctions } from '@ven/shared/core/debug/consoleDebugFunctions';
import ReactPixel from 'react-facebook-pixel';
import { UserDataService } from '../services/user/UserDataService';
import RoomSession from './pages/RoomSession';
import ZoomRoomSession from './pages/RoomSession/zoomRoomSession';
import PrivacyPolicy from './pages/Meta/Privacy';
import SupportPage from './pages/Meta/Support';
import EventHostPage from './pages/HostEvent';
import RolesPage from './pages/Roles';
import VipCodePage from './pages/HostEvent/Vip';

const BASE_ROUTE = ""

const d = new Date();
const month = d.getMonth();

month == 11
  ? UserDataService.set('holiday', 'true')
  : UserDataService.clear('holiday');

  var connectedRef = database.ref(".info/connected");
connectedRef.on("value", function(snap) {
  if (snap.val() === true) {
    console.log(`[${new Date().toUTCString()}] - Firebase Connected`);
    return;
  }

  console.log(`[${new Date().toUTCString()}] - Firebase Disconnected`);
});

const PageLogger = () => {
  // const location = ReactRouter.useLocation();
  useEffect(() => {
    console.log(document.title)
    analyticsService.setCurrentScreen(document.title);
    // firebase.analytics().setCurrentScreen('test');
  }, [document.title]);

  
  return <></>;
}

  {/* <!-- Facebook Pixel Code --> */}
      {/* <script dangerouslySetInnerHTML={{ __html: `!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '282788003299112');
      fbq('track', 'PageView');
      </script>
      <noscript><img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=282788003299112&ev=PageView&noscript=1"` }}
    /> */}
  {/* <!-- End Facebook Pixel Code --> */}
// const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init('374267107203560', undefined, options);
ReactPixel.pageView();

DEVELOPMENT_MODE && ConsoleDebugFunctions();

export const App:React.FC = () => 
(
  <ReactRouter.BrowserRouter basename={ BASE_ROUTE }>
    {/* <PageLogger /> */}
    <div className={`App`} id="app">

      <UserDataProvider>

        <Helmet>
          <title> VEN Games - Play remote games. Anytime, anywhere</title>
          <meta name="description" content="Play online party games with your remote teams, 
          family and friends. Online drawing and word games make it easy to connect and have fun!"/>
        </Helmet>
        
        {/* Removing header not needed on the first page for now. */}
        {/* <MainHeader /> */}

        <ReactRouter.Switch>
          {/* <ReactRouter.Route path="/postrelease" component={ PostRelease }  /> */}
          {/* <ReactRouter.Route path="/login" component={ Login }  /> */}
          {/* <ReactRouter.Route path="/signup" component={ Signup }  /> */}
          <ReactRouter.Route path="/room-session" component={ RoomSession }  />
          <ReactRouter.Route path="/zoom" component={ ZoomRoomSession }  />
          <ReactRouter.Route path="/pack-init" component={ PackInit }  />
          <ReactRouter.Route path="/pack-confirmation" component={ PackConfirmation }  />
          <ReactRouter.Route path="/gamenight" component={ GameNightSteps }  />
          <ReactRouter.Route path="/account" component={ Account }  />
          <ReactRouter.Route path="/packs" component={ PackEditor }  />
          <ReactRouter.Route path="/roles" component={ RolesPage }  />
          <ReactRouter.Route path="/vip" component={ PostRelease }  />
          <ReactRouter.Route path="/team" component={ PostTeamRelease }  />
          <ReactRouter.Route path="/teams" component={ PostTeamRelease }  />
          <ReactRouter.Route path="/termsandconditions" component={ TermsAndConditions }  />
          <ReactRouter.Route path="/privacy-policy" component={ PrivacyPolicy }  />
          <ReactRouter.Route path="/friend-register" component={ FriendRegistration }  />
          <ReactRouter.Route path="/support" component={ SupportPage }  />
          <ReactRouter.Route path="/vipPanel/:vipCode?" component={ VipCodePage }  />
          <ReactRouter.Route path="/eventHost/:eventId?/:sessionId?" component={ EventHostPage }  />
          <ReactRouter.Route path={ `/play/:code` } 
            component={ props => RoomCodeRouteWrapper({ ...props?.match?.params }) } />
          <ReactRouter.Route path={ `/watch/:code` } 
            component={ props => RoomCodeRouteWrapper({ ...props?.match?.params }) } />
          <ReactRouter.Route path={ `/debug/:code` } 
            component={ props => GameRoomLogsPage({ ...props?.match?.params }) } />
          <ReactRouter.Route path="/" component={ PostRelease }  />


          <ReactRouter.Redirect to=""/>
        </ReactRouter.Switch>

      </UserDataProvider>

      <ToastContainer />

    </div>
  </ReactRouter.BrowserRouter>
)
