import React, { useEffect, useState } from 'react';
import * as ReactRouter from "react-router-dom";

import type { GameStateController } from '../../../gameplay/GameStateController';

import { Button } from '@ven/shared/components/common/atoms/Button';
import { TabooCard } from '../../organizms/TabooCard';

import { Ticker } from '@ven/shared/components/game/services/Ticker';
import { ScoreBoard } from './ScoreBoard';
import { Separator } from '@ven/shared/components/common/atoms/Separator';
import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { TimerAndScoresHeader } from './TimerAndScoresHeader';

import styled from '@emotion/styled';

//@ts-ignore
import ExImage from "@ven/shared/components/assets/wordplay/ex.png"
import { CenteredContent } from '@ven/shared/components/common/CenteredContent';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { GameStateContext } from '@ven/games/wordplay/gameplay/GameStateContext';
import { PlayAgainButton } from '@ven/shared/components/game/molecules/PlayAgainButton';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';

export const InnerContent_GameOver:React.FC = () => 
{
  const history = ReactRouter.useHistory()
  const { game } = React.useContext( GameStateContext )

  const data = game.data!
  const scores = data.state.scores
  const winningTeamId = 
    scores[ 1 ] === scores[ 2 ] ? null :
    scores[ 1 ] > scores[ 2 ] ? 1 : 2
  const text = winningTeamId ? ( game.getTeamName( winningTeamId ) + " team wins!"  ) : "The game was a draw!"

  const [redirecting, setRedirecting] = React.useState(false)
  const nextGameUrl = data?.state.nextGameUrl;

  return (
    <GameOverWrapper>
      <TopTip> { text } </TopTip>

      <Separator size={ 30 } />

      <ScoreBoard/>

      <Separator size={ 30 } />
      
      <PlayAgainButton gameName='outlaw'
          nextGameUrl={nextGameUrl}
          isEventHost={game.data.eventHost}
          isHost={game.amHost()}
          isSpectator={game.amSpectator()}
          updateState={game.update.state} 
          setRedirecting={setRedirecting} isRedirecting={redirecting}
        />

      <LargeActionButton className="back-to-games" onClick={ () => history.push("/") }> Back to Games </LargeActionButton>
    </GameOverWrapper>
  )
}

export const InnerContent_TurnNotStarted:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )

  const data = game.data!

  const turnPlayerId = data.state.turn.player
  const turnPlayerData = data.players[ turnPlayerId! ]
  const turnPlayerName = data.players[ turnPlayerId! ].username
  const turnTeam = turnPlayerData.team
  const turnTeamName = game.getTeamName(turnTeam);
  const playerOnline = data?.presences[turnPlayerId!]?.online;
  const timeout = data?.state.turn.timeout || '';
  const totalTime = game.getConfig()!.timeout;

  const prevTurnResults = data.state.prevTurnResults
  const prevTurnPlayerData = data.players[ data.state.prevTurnResults?.player! ]
  const prevTurnPlayerOrYou = data.state.prevTurnResults?.player === game.myUserId && !game.amSpectator() ? "You" : prevTurnPlayerData?.username
  const prevTurnplayerOnline = data?.presences[prevTurnPlayerData.uid!]?.online;

  const BlueStrip = styled.div`
    position: absolute;
    width: 200%;
    height: 81px;
    left: 50%; 
    top: 130px; 
    transform: translate(-50%, 0%); 
    color: white;
    background: #ffffff7d;

    display: flex;
    align-items: center;
    justify-content: center;

    & .avatar {
      position: absolute;
      left: calc(50% - 180px);
    }
    & .text {
      font-family: Oswald;
      font-style: normal;
      font-weight: 500;
      font-size: 2.5em;
      line-height: 59px;
      text-align: center;
      color: #FFFFFF;
    }
  `

  const NextPlayerText = styled.div`
    width: 294px;
    height: 60px;
    top: 656px;
    left: 50%;
    transform: translate(-50%,0);
    position: absolute;

    display: flex;
    flex-direction: row;

    .text {
      flex: 1 1;
      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      color: #FFFFFF;

      display: flex;
      align-items: center;

      padding-left: 16px;
      
      .yellow {
        color: #FFE499;
      }
    }
  `

  return (
    <>
      {
        prevTurnResults !== undefined &&
        <TopTip> 
          <div> { prevTurnPlayerOrYou } earned { prevTurnResults.score } </div> 
          <div> points for { game.getTeamName( prevTurnResults.team ) }! </div>
        </TopTip>
      }

      {
        prevTurnResults !== undefined &&
        <BlueStrip>
          <PlayerAvatar size={ 50 } index={ prevTurnPlayerData.avatar }
          label={game.getTeamName(prevTurnPlayerData.team)}
          transparent={!prevTurnplayerOnline}/>

          <div className="text"> 
            { prevTurnResults.score >= 0 ? '+' : '' }{ prevTurnResults?.score } Points! 
          </div>
        </BlueStrip>
      }

      <ScoreBoard/>

      <NextPlayerText>
        <TimeoutClock>
          <TimerCircleProgress time={timeout} totalTime={totalTime} 
            radius={40} displayTime={false} stroke={4}
          />
            <PlayerAvatar size={60} index={ turnPlayerData?.avatar }
              label={game.getTeamName(turnPlayerData?.team)}
              transparent={!playerOnline} />
        </TimeoutClock>

        <div className="text">
          <div>
            { turnPlayerName } { turnPlayerId === game.myUserId && !game.amSpectator() ? `you’re ` : `is ` }
            up for the <span className="yellow">{ turnTeamName }</span> to give clues!
          </div>
        </div>
      </NextPlayerText>

      {
        data.state.turn.player === game.myUserId! && !game.amSpectator()
        ?
        <div>
          <LargeActionButton onClick={ () => game.startPlayingTurn() }>
            {/* <TimerCircleProgress time={timeout} totalTime={totalTime}
                radius={25} displayTime={false} stroke={5} /> */}
                Start Turn (skipping turn in {timeout})
            </LargeActionButton>
            {/* if not started, turn will be skipped in {timeout}... */}
        </div>
        :
        <BottomWaitTip> Starting the next round… </BottomWaitTip>
      }  

    </>
  )
}

const TimeoutClock = styled.div`
  display: grid;
  margin-top: -10px;

  .avatar-container {
    grid-area: 1/1;
  }
  .circle-clock {
    grid-area: 1/1;
  }
`;

export const InnerContent_TurnNotStarted_FirstTurn:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )

  const data = game.data!

  const turnPlayerId = data.state.turn.player
  const turnPlayerData = data.players[ turnPlayerId! ]
  const turnPlayerName = data.players[ turnPlayerId! ].username
  const turnTeam = turnPlayerData.team
  const turnTeamName = game.getTeamName(turnTeam);
  const playerOnline = data?.presences[turnPlayerId!]?.online;

  const timeout = data?.state.turn.timeout || '';
  const totalTime = game.getConfig()!.timeout;

  const BluePad= styled.div`
    width: 384px;
    height: 454px;

    background: var(--outlaw-headers);
    box-shadow: -6px 7px 28px rgba(0, 0, 0, 0.0819766);
    border-radius: 12px;
    position: relative;

    text-align: center;
    box-sizing: border-box;
    padding: 52px;

    user-select: none;
    z-index: 20;

    &::before {
      content: '';
      border: 6px solid #FFFFFF;
      border-radius: 11px;
      position: absolute;
      top: 26px;
      bottom:26px;
      left: 22px;
      right: 22px;
      pointer-events: none;
    }

    .avatar {
      position: absolute;
      top: 81px;
      left: 50%; 
      transform: translate(-50%, 0%); 
    }

    .next-player-text {
      position: absolute;
      top: 214px;
      left: 50%; 
      transform: translate(-50%, 0%); 
      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #FFFFFF;
    }

    .button {
      position: absolute;
      top: 301px;
      left: 50%; 
      transform: translate(-50%, 0%); 
      max-width: 192px;
    }
    .hint {
      position: absolute;
      top: 331px;
      left: 50%; 
      transform: translate(-50%, 0%); 
      font-family: Bitter;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      color: #FFFFFF;
    }

    .yellow {
      color: #FFE499;
    }
  `

  return (
    <>
      <CenteredContent style={{ height: `100%` }}>
        <Dimmer/>
        <BluePad>
        <TimeoutClock>
          <TimerCircleProgress time={timeout} totalTime={totalTime} 
            radius={80} displayTime={false} stroke={8}
          />
            <PlayerAvatar size={80} index={ turnPlayerData?.avatar }
             label={game.getTeamName(turnPlayerData?.team)}
              transparent={!playerOnline} />
        </TimeoutClock>
          <div className="next-player-text">
            { turnPlayerName } { turnPlayerId === game.myUserId && !game.amSpectator() ? `you’re ` : `is ` }
            up for the <span className="yellow">{ turnTeamName }</span> to give clues!
          </div>
          {
            turnPlayerId === game.myUserId && !game.amSpectator()
            ?
            <GreenButton className={"button"} onClick={ () => game.startPlayingTurn() }>
              Let's go!
            </GreenButton>
            :
            <div className="hint">
              <i>
                Waiting for { turnPlayerName }
              </i>
            </div>
          }
        </BluePad>
      </CenteredContent>
    </>
  )
}

const Dimmer = styled.div`
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;

  background: #000C;
  z-index: 1;
`

export const InnerContent_Ally:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )

  const data = game.data!
  const turnPlayerId = data.state.turn.player
  const turnPlayerData = data.players[ turnPlayerId! ]
  const turnPlayerName = data.players[ turnPlayerId! ].username
  const turnTeam = turnPlayerData.team
  const turnTeamName = game.getTeamName( turnTeam )
  const playerOnline = data?.presences[turnPlayerId!]?.online;

  const AvatarContainer = styled.div`
    top: 410px;
    left: 50%; 
    transform: translate(-50%, -50%); 
    position: absolute;
  `

  const BigText = styled.div`
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;

    width: 80%;
    top: 615px;
    left: 50%; 
    transform: translate(-50%, -50%); 
    position: absolute;
    z-index: 1;
  `

  const TheX = styled.img`
    top: 460px;
    left: 50%; 
    transform: translate(-50%, -50%);
    position: absolute;
  `

  const fouled = !! game.data.state.turn?.fouls

  return (
    <>
      <TopTip> { turnTeamName }, it’s your turn! </TopTip>

      <TimerAndScoresHeader values={ { ...data.state.turn, turnTime : game.getConfig().turnTime } } />  

      <TheX src={ ExImage }/>

      <AvatarContainer>
        <PlayerAvatar size={ 180 } index={ turnPlayerData.avatar }
         label={game.getTeamName(turnPlayerData.team)}
            transparent={!playerOnline}/>
      </AvatarContainer>

      {
        !fouled &&
        <BigText>
          Guess the words that { turnPlayerName } describes
        </BigText>
      }
    </>
  )
}

export const InnerContent_Opponent:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )

  const data = game.data!

  const style_TabooCard = {
    top: `470px`,
    left: `210px`,
    transform: `translate(-50%, -50%)`,
    position: `absolute`,
  }
  
  const fouled = !! game.data.state.turn?.fouls
  const cardData = ! fouled && game?.data?.state?.turn?.card

  return (
    <>
      <TopTip> The opposing team is guessing.</TopTip>

      <TimerAndScoresHeader values={ { ...data.state.turn, turnTime: game.getConfig().turnTime } } />  
    
      <TabooCard card={ cardData || {} } style={ style_TabooCard }/>

      {
        !game.amSpectator() &&
        !fouled && 
        !! cardData &&
        <>
          <BottomTip>
            Did they say a word they shouldn’t have? Call Outlaw on them!
          </BottomTip>
          <ButtonsBar style={{ justifyContent: `center`, bottom: '40px',  top: '840px' }}>
            <ActionButton style={{ background : "#FC3B68" }} onClick={ () => game.callFoul() }> Call Outlaw! </ActionButton>
          </ButtonsBar>
        </>
      }
    </>
  )
}

export const InnerContent_TurnPlayer:React.FC = () => 
{
  const { game } = React.useContext( GameStateContext )

  const data = game.data!
  const myUserId = game.myUserId!

  const style_TabooCard = {
    top: `480px`,
    left: `210px`,
    transform: `translate(-50%, -50%)`,
    position: `absolute`,
  }

  const myTurn = data.state.turn.player === myUserId && game.amSpectator();
  
  const fouled = !! game.data.state.turn?.fouls
  const cardData = game?.data?.state?.turn?.card

  const turnStarted = data.state.turn.started
  const runTicker = turnStarted && myTurn //&& !! cardData

  const [cooldownButtons, setCooldownButtons] = useState(false);

  useEffect(() => {
    if(cooldownButtons) {
      setTimeout(() =>  setCooldownButtons(false), 250)
    }

  }, [cooldownButtons])

  const lastSecond = (game?.data?.state?.turn?.time || 0) < 1;
  const scorePointsDisabled = cooldownButtons || lastSecond;
  return (
    <>
      <TopTip> Give clues to your team!</TopTip>

      <TimerAndScoresHeader values={ {...data.state.turn, turnTime: game.getConfig().turnTime } } />  

      <TabooCard card={ {} } style={{ ...style_TabooCard, opacity: .3, top : `500px` }}/>
      <TabooCard card={ {} } style={{ ...style_TabooCard, opacity: .5, top : `490px` }}/>
      <TabooCard card={ cardData || {} } style={ style_TabooCard } key={ cardData ? 1 : 0 } />

      {
        !fouled &&
        !! cardData &&
        <ButtonsBar style={{ top: '790px' }}>
          <ActionButton style={{ background : "#FC3B68" }} disabled={scorePointsDisabled} onClick={ () => { setCooldownButtons(true); game.passCard(); } }> Pass </ActionButton>
          <div style={{width:12}} />
          <ActionButton style={{ background : "#2FB586" }} disabled={scorePointsDisabled} onClick={ () => { setCooldownButtons(true); game.winCard(); } }> Correct </ActionButton>
        </ButtonsBar>
      }
    </>
  )
}

const TopTip = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  text-align: center;

  color: #FFE499;

  top: 40px;
  left: 50%; 
  transform: translate(-50%, 0); 
  position: absolute;
  width: 90%;
`

const BottomTip = styled.div`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  text-align: center;

  color: #FFFFFF;

  /* top: 780px; */
  bottom: 90px;
  left: 50%; 
  transform: translate(-50%, -50%); 
  position: absolute;
  width: 90%;
`

const BottomWaitTip = styled.div`
  width: 304px;
  height: 225px;
  left: 50%; 
  /* top: 840px; */
  bottom: -100px;
  transform: translate(-50%, -0%); 
  position: absolute;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  text-align: center;

  padding-top: 40px;
  color: #FFFFFF;

  background:  var(--outlaw-headers);;
  box-shadow: -6px 6px 25px rgba(0, 0, 0, 0.0819766);
  border-radius: 10.8px 10.8px 0 0;
`

// // // // // //

const ButtonsBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;

  left: 50%; 
  transform: translate(-50%, -50%); 
  position: absolute;
`

const ActionButton = styled(Button)`
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 66px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  width: 48%;

  margin: 0;

  &:not([disabled]):hover {
    border: none;
    border: 2px solid white;
  }
  
  /* animation: zoom .500s;
  animation: fade-in .500s; */
`
const GameOverWrapper = styled.div`
 .play-again-button {
    width : 363px;
    left: 50%; 
    top: 705px;
    transform: translate(-50%, -50%); 
    position: absolute;
  }

 
 .back-to-games {
    left: 50%; 
    top: 795px;
    transform: translate(-50%, -50%); 
    position: absolute;
 }
`

const LargeActionButton = styled(ActionButton)`
  background : #2FB586;
  width : 363px;

  left: 50%; 
  top: 780px;
  transform: translate(-50%, -50%); 
  position: absolute;
`

/////

export const mobileViews = {
  InnerContent_GameOver : InnerContent_GameOver,
  InnerContent_TurnNotStarted : InnerContent_TurnNotStarted,
  InnerContent_TurnPlayer : InnerContent_TurnPlayer,
  InnerContent_Ally : InnerContent_Ally,
  InnerContent_Opponent : InnerContent_Opponent,
}
