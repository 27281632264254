import React from 'react';

import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { getReferrerIdFromUrl, UserDataService } from '@ven/platform/main/services/user/UserDataService';

import { GameStateContext } from '../../../gameplay/GameStateContext';
import { Team } from '../../../gameplay/GameStateController';

import { Spacer } from '@ven/shared/components/common/atoms/Separator';
import { CustomGameConfigurationContent } from './CustomGame';
import { SelectTeamDropdown } from '@ven/shared/components/common/pregame/SelectTeamDropdown';
import { PadContent_ShareGame, PadContent_StandBy, PregamePageWrapper, UsernameAndAvatarForm } from '@ven/shared/components/common/pregame/common';
import { CircledAvatarAtTheTop, HowToPlayLink, InvitedText, JoinButton, PleaseEnterNameTip } from '@ven/shared/components/common/pregame/JoinModal';
import { MainHeader } from '@ven/platform/main/components/organisms/MainHeader';

import { HowToPlay_Outlaw } from '../HowToPlay_Outlaw';

import styled from "@emotion/styled";
import { CustomGamePad, PlayersList_TeamColumns } from '@ven/shared/components/common/pregame/CustomGame';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';

// @ts-ignore
import imgOutlaw from '../../../../../platform/main/assets/images/homepage/word_outlaw.svg';
import { SwitchViewMode } from '@ven/shared/components/common/SwitchViewMode';

export const PreGameContent: React.FC = () => {
  const { game } = React.useContext(GameStateContext)
  const myUserData = React.useContext(UserDataContext);

  const referer = getReferrerIdFromUrl()
  const refererData = game.data.players[referer!]

  const [showHowToPlay, setShowHowToPlay] = React.useState(false)

  const myUserId = game.myUserId!;
  const myPlayerState = game.data.players[myUserId]

  const imEventHostInEventHostGame = myUserData.eventHost && game.data.eventHost;

  const [myTeam, setMyTeam] = React.useState(() => {
      if(!myPlayerState?.team) {
        myPlayerState && game.update.player(myUserId, { team: Team.Red });
        return Team.Red;
      }
      return myPlayerState?.team
  });

  const setMyTeamAndUpdateGameState = team => {
    setMyTeam(team)
    !!game.haveJoined() && game.update.player(myUserId, { team })
  }
   
  const showHowToPlayFirstTime = () => {
    if(UserDataService.get('howToPlayOutlawShown')) {
      return;
    }
    setShowHowToPlay(true);
    UserDataService.set('howToPlayOutlawShown', 'true')
    localStorage.howToPlayBrokenMsg = true;
  }
  
  const isWatchUrl = window.location.href.includes('/watch/');
  if(isWatchUrl) {
    game.forceSpectatorMode = true;
    if(!game.amHost() && (!myUserData.eventHost && game.data.eventHost))
      return <div>
         <MainHeader />
         <SwitchViewMode game={game} isSpectator={true}/>
      </div>
  }

  showHowToPlayFirstTime()

  return (
    <PregamePageWrapper>
      <MainHeader />
      {
        showHowToPlay &&
        <HowToPlay_Outlaw handleClose={() => setShowHowToPlay(false)} absolute={false} />
      }

      
      <Spacer size={ 15 } />      
      {
         myUserData.eventHost && game.data.eventHost
         ?
         // EventHost
         <CustomGameConfigurationContent openHowToPlay={() => setShowHowToPlay(true)} />
        :
        !game.haveJoined()
          ?
          // Not joined
          <BluePad>
            <CircledAvatarAtTheTop index={refererData?.avatar || myUserData?.avatar} />

            <Spacer size={48} />

            <InvitedText gameName="Outlaw" refererData={refererData} />

            {/* <div className="label"> Team: </div> */}
            {/* <SelectTeamDropdown game={game} usedTeamState={[myTeam, setMyTeam]} /> */}

            <UsernameAndAvatarForm />

            <Spacer size={30} />

            <JoinButton username={ myUserData.username } game={ game } joinData={{ team: myTeam }} />
            
            <Spacer size={50} />

            <HowToPlayLink openHowToPlay={() => setShowHowToPlay(true)} />
          </BluePad>
          :
          game.data.host === myUserId && !game.data?.eventHost
            ?
            // Joined, Host
            <CustomGameConfigurationContent openHowToPlay={() => setShowHowToPlay(true)} />
            :
            // Joined, not Host
            <PostJoinedTeamSelection>
              <img src={imgOutlaw} />
              <Spacer size={50} />
              <CustomGamePad headerColor="#5A91EF" className="TeamPad">
                <div className="title title-teams">
                  <div className="column">
                    {/* <div className="bigHeading">Team 1</div> */}
                    <div className="bigHeading">{game.getTeamName(1)}</div>
                  </div>
                  <div className="column">
                    {/* <div className="bigHeading">Team 2</div> */}
                    <div className="bigHeading">{game.getTeamName(2)}</div>
                  </div>
                </div>

                <PlayersList_TeamColumns game={game} teams={[Team.Red, Team.Blue]} />

                {
                  !game.canStartGame() && <>
                    <Spacer size={28} />
                    <div className="hint"> Waiting for at least two players on each team. </div>
                    <Spacer size={28} />
                  </>
                }
              </CustomGamePad>

              <Spacer size={50} />

              <GreenButton
                className="button-play"
                onClick={() => setMyTeamAndUpdateGameState(myTeam == Team.Red ? Team.Blue : Team.Red)}>
                Switch Teams
            </GreenButton>

              <Spacer size={25} />

              <HowToPlayLink openHowToPlay={() => setShowHowToPlay(true)} />

              <Spacer size={50} />

              <div className="waiting-warning">
                Waiting for the host to start the game…
              </div>

            </PostJoinedTeamSelection>
      }
    </PregamePageWrapper>
  )
}

const PostJoinedTeamSelection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .how-to-tip a {
    color: #2FB586;
    cursor: pointer;
  }

  .waiting-warning {
    font-family: Oswald;
    font-style: normal;
    font-size: 2rem;
    line-height: 30px;
    text-align: center;
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column-reverse;
  }

  .TeamPad {
    min-height: 610px;
  }
    
`

const BluePad = styled.div`
  width: 475px;
  /* height: 773px; */
  max-width: 90vw;

  background: var(--outlaw-headers);
  box-shadow: -6px 7px 28px rgba(0, 0, 0, 0.0819766);
  border-radius: 12px;
  position: relative;

  text-align: center;
  box-sizing: border-box;
  padding: 52px;
  margin-bottom: 140px;

  user-select: none;

  &::before {
    content: '';
    border: 6px solid #FFFFFF;
    border-radius: 11px;
    position: absolute;
    top: 26px;
    bottom:26px;
    left: 22px;
    right: 22px;
    pointer-events: none;
  }

  .avatar-wrapper {
    width: 100px;
    height: 100px;
    border: 6px solid #FFFFFF;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 20px;
    left: 50%;
    border-radius: 50%;
    background: var(--outlaw-headers);
    overflow: hidden;
  }

  .label {
    font-family: Oswald;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: #FFFFFF;
    padding-top: 30px;
    padding-left: 5px;
    text-align: left;
  }

  .button-play {
    width: 310px;
    display: inline-block;
  }

  .how-to-tip {
    position: absolute;
    left: 50%;
    bottom: 26px;
    transform: translate(-50%, 0%);
    width: 100%;
    pointer-events: none;

    font-family: Bitter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 100px;
    text-align: center;

    color: #FFFFFF;

    a {
      color: #2FB586;
      cursor: pointer;
      pointer-events: all;
    }
    a:hover {
      text-decoration: underline;
    }
  }

  .share-label-large {
    font-family: Bitter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }

  .share-label-small {
    font-family: Bitter;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
  }
`
