/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from '@emotion/styled';
import { COLOR_DARK, COLOR_GRAY } from "@ven/shared/components/styling/colors";

import React from 'react';

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';

//@ts-ignore
import CornerImage from "@ven/shared/components/assets/canvas-corner-clean.png";
import { PlayerAvatar } from "@ven/shared/components/game/molecules/PlayerAvatar";

interface Props 
{ 
}
export const TimerCorner:React.FC<Props> = ({  }) =>
{ 
  const { data } = React.useContext( GameStateContext )!

  const timeText = ( data!.state.turn.time || 0 ).toString().padStart( 2, '0' )
  const turnPlayerAvatar =  data!.state.turn.player!;
  const playerAvatar =  data?.players[turnPlayerAvatar]?.avatar
  const playerOnline = data?.presences[turnPlayerAvatar]?.online;
  
  return (
    <Wrapper>
      <PlayerAvatar size={35} index={playerAvatar} transparent={!playerOnline}></PlayerAvatar>
      <ClockText>
        { timeText }
      </ClockText>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 150px;
  height: 150px;
  background: url(${ CornerImage });
  pointer-events: none;
  
  // hack to fix hiding behind canvas on some screen sizes! o.O
  transform: translateZ(100px);
  
  @keyframes timer-corner { from { transform: translate(-50px, 0); } }
  animation: timer-corner 1000ms;

  .avatar {
    position: absolute;
    left: 10px;
    bottom: 40px;
  }
`

const ClockText = styled.div`
  color: black;
  position: absolute;
  left: 10px;
  bottom: 8px;
  line-height: 100%;
  font-family: 'Lucida Console', 'Courier New', Courier, monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  color: ${ COLOR_DARK };
`
