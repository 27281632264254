import React, { useMemo } from 'react';

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';
import type { GameStateController } from '@ven/games/scribbler/gameplay/GameStateController';

import { PlayerAvatar } from '@ven/shared/components/game/molecules/PlayerAvatar';
import { LargeText, OverlayDialogBase, SmallText } from './OverlayDialogBase';

import { COLOR_GRAY, COLOR_LIGHT, COLOR_TOOL_ACTIVE, COLOR_TOOL_IDLE } from '@ven/shared/components/styling/colors';
import styled from '@emotion/styled';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';
import { SyncLoader } from 'react-spinners';
import { TimerCircleProgress } from '@ven/shared/components/game/molecules/TimerCircleProgress';

interface Props 
{ 
  game : GameStateController,
}
export const OverlayDialog_ChooseWord:React.FC<Props> = ({ game }) =>
{
  const { data } = React.useContext( GameStateContext )!

  const [words, setWords] = React.useState( () => {
    async function getNewWords() {
      const options = await game.getNewWords( 3 );
      setWords(options);
    }
    getNewWords();
    return new Array;
  })
  
  const Hint = SmallText
  const Title = LargeText

  const roundLabelText = !! data?.state?.round?.number ? `Round: ${ data?.state?.round?.number } / ${ data.config.rounds }` : `...`

  const OnClickWordChoice = async ( word:string ) =>
  {
    await game.addToBannedWords( words )
    await game.chooseWord( word )
  }

  const ChoiceButton = ({word}) => (
    <Button onClick={ () => OnClickWordChoice( word ) }> 
      { word } 
    </Button>
  )

  const currentPlayerId = data?.state?.turn?.player || ''
  const currentPlayerName = data?.players?.[ currentPlayerId ]?.username
  const currentPlayerAvatar = data?.players?.[ currentPlayerId ]?.avatar
  const currentPlayerOnline = data?.presences?.[ currentPlayerId ]?.online

  const timeout = data?.state?.turn?.timeout || ''
  const totalTime = game.getConfig()!.wordTime;

  return (
    <OverlayDialogBase>
        <TimeoutClock>
            <TimerCircleProgress time={timeout} totalTime={totalTime} 
              radius={35} displayTime={false} stroke={4}
              color={"#303744"} />
              <PlayerAvatar size={52} index={ currentPlayerAvatar }
                transparent={!currentPlayerOnline} />
          </TimeoutClock>

      <Hint> It’s your turn to draw, { currentPlayerName }! </Hint>
      <Title> Pick a word. </Title>
      {
        useMemo(() => 
          words.length 
            ? words.map((word,i) => <ChoiceButton key={ i } word={ word } /> )
            : [1,2,3].map((word,i) => <ButtonReadonly key={ i }><SyncLoader size={6} color="#3148BF"></SyncLoader></ButtonReadonly> )
        , [words.length])
      }
    <Round> { roundLabelText } </Round> 
    <Hint>Pick a word or your turn will be skipped in {timeout}...</Hint>
    </OverlayDialogBase>
  )
}

const TimeoutClock = styled.div`
  display: grid;
  
  .avatar-container {
    grid-area: 1/1;
  }
  .circle-clock {
    grid-area: 1/1;
  }
`;

const Round = styled.div`

  @media (min-width: ${ MOBILE_BREAK_PX }px) {
    display: none;
  }

  color: ${ COLOR_GRAY };
  width: 100%;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    width: 8rem;
    margin: 1rem -10rem;
    border-top: 1px solid;
  }
  
  &:after {
    content: '';
    position: absolute;
    width: 8rem;
    margin: 1rem 2rem;
    border-top: 1px solid;
  }
`

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Bitter', serif;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  border-radius: 12px;
  border: none;
  outline: none;
  max-width: 292px;
  width: 90%;
  height: 66px;
  margin-bottom: 24px;
  color: white;
  cursor: pointer;

  color: ${ COLOR_TOOL_IDLE };
  background: ${ COLOR_LIGHT };
  /* background: white; */
  border: 1px solid ${ COLOR_TOOL_IDLE };
  transition: color 300ms, background-color 300ms, border-color 300ms;
  
  &:hover {
    color: white;
    background: ${ COLOR_TOOL_ACTIVE };
    border: 1px solid ${ COLOR_TOOL_ACTIVE };
    transition: color 150ms, background-color 150ms, border-color 150ms;
  }
`

const ButtonReadonly = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Bitter', serif;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
  border-radius: 12px;
  border: none;
  outline: none;
  max-width: 292px;
  width: 90%;
  height: 66px;
  margin-bottom: 24px;
  color: white;

  color: ${ COLOR_TOOL_IDLE };
  background: ${ COLOR_LIGHT };
  /* background: white; */
  border: 1px solid ${ COLOR_TOOL_IDLE };
  transition: color 300ms, background-color 300ms, border-color 300ms;
`