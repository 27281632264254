import React, { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { VipCodeService } from '@ven/shared/core/services/VipCodeService';
import styled from '@emotion/styled';
import { ClipboardCopy } from '@ven/shared/components/common/atoms/ClipboardCopy';
// import { JSONSchema7 } from "json-schema";

interface VipInfoProps {
  data?: any;
}

const VipInfo: React.FC<VipInfoProps> = (props) => {

  async function deleteHostVip(vip) {
    const session = await VipCodeService.delete(vip.id);
    window.history.pushState({}, '', '/vipCode/createVip');
    console.log(session)
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Wrapper>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Vip</Modal.Title>
        </Modal.Header>
        <Modal.Body>You are about to delete '{props.data.code}' vip code. 
        Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => {
            deleteHostVip(props.data);
            handleClose();
          }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Header>
          {props.data.code}

          <Button variant="danger" onClick={handleShow}>
            Delete
          </Button>

        </Card.Header>
        <Card.Body>
          <div className="media text-muted pt-3">
            <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
              <strong className="d-block text-gray-dark">Expire In</strong>
              {props.data.expiresIn}
            </p>
          </div>
          <div className="media text-muted pt-3">
            <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
              <strong className="d-block text-gray-dark">Times Used</strong>
              {props.data.timesUsed ? props.data.timesUsed: 0 }
            </p>
          </div>
          <div className="media text-muted pt-3">
            <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
              <strong className="d-block text-gray-dark">One Time Use</strong>
              { props.data.oneTimeUse ? "true" : "false" }
            </p>
          </div>
          <div className="media text-muted pt-3">
            <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
              <strong className="d-block text-gray-dark">Creator</strong>
              {props.data.creator}
            </p>
          </div>
          <div className="media text-muted pt-3">
            <p className="media-body pb-3 mb-0 small lh-125 border-bottom border-gray">
              <strong className="d-block text-gray-dark">Copy redeem link</strong>
              <ClipboardCopy copyText={`${window.location.origin}?vipCode=${props.data.code}`} />
            </p>
          </div>
          
          {/* <CreateSession data={props.data}></CreateSession> */}
          {/* <SessionInfo data={props.data}></SessionInfo> */}
        </Card.Body>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.nav-item {
  outline: 1px solid rgba(0,0,0,0.1);
  background: #fff;
}
button.btn.btn-danger{
  float: right;
}
&>.card {
  background-color: transparent;
  border: 0;
}
`

export default VipInfo;
