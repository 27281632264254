import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import wiggle from '../../../../assets/images/homepage/wiggle.svg';
import curveSectionBottom from '@ven/platform/main/assets/images/homepage/curve-section-bottom.svg';
import curveSectionTop from '@ven/platform/main/assets/images/homepage/curve-section-top.svg';
import curveSectionFooter from '@ven/platform/main/assets/images/homepage/curve-section-footer.svg';

import cards from '../../../../assets/images/homepage/cards.png';
import faces from '../../../../assets/images/homepage/faces.png';
import faces2 from '../../../../assets/images/homepage/faces2.png';
import devicesMobile from '../../../../assets/images/homepage/devices-mobile.png';
import facesMobile from '../../../../assets/images/homepage/mobile-faces.png';
import testimonialBackground from '../../../../assets/images/homepage/testimonial-background.png';
import Testimonal from '@ven/shared/components/common/atoms/Testimonal/Testimonal';



import testimonalSuneet from '../../../../assets/images/homepage/test_suneet.jpg';
import testimonalNicole from '../../../../assets/images/homepage/test_nicole.jpg';
import testimonalEric from '../../../../assets/images/homepage/test_eric.jpg';
import styled from '@emotion/styled';
import { GreenButton } from '@ven/shared/components/common/atoms/GreenButton';
import { MainFooter } from '../../../organisms/MainFooter';

const testimonalProps = [
    {
        stars: 5,
        description: 'This was such a fun game for the whole family! We played it virtually and felt like we were all together again!',
        image: testimonalSuneet,
        name: 'Suneet'
    }, {
        stars: 5,
        description: 'After we put the kids down to bed, we just want some stay-at-home fun to play with our friends.  During the pandemic, VEN has been great because we can stay connected and safe.',
        image: testimonalNicole,
        name: 'Nicole'
    }, {
        stars: 5,
        description: 'We played Scribbler as a family and had a fantastic time - it was a great way to connect for some quality together time, even with people across the country.',
        image: testimonalEric,
        name: 'Eric'
    },
];

const testimonals: Array<any> = [];

for (const [i, test] of testimonalProps.entries()) {
    testimonals.push(
        <Testimonal key={i} props={test}></Testimonal>
    );
}

const Curve = styled.div`
    background-image: url(${curveSectionBottom});
    background-size: 100% 210px;
    background-repeat: no-repeat;
    width: 130rem;
    height: 175px;
    margin-top: -172px;

    @media(max-width: 700px) {
        display: none;
    }
`

const CurveSectionTop = styled.div`
    background-image: url(${curveSectionTop});
    background-size: 100% 136px;
    background-position: top;
    background-repeat: no-repeat;
    width: 130rem;
    height: 156px;
    transform: translateY(-1px);

    transform-origin: bottom;
    @media(max-width: 700px) {
        display: none;
    }
`

const CurveSectionFooter = styled.div`
    background-image: url(${curveSectionFooter});
    background-size: 100% 100px;
    background-repeat: no-repeat;
    width: 130rem;
    margin-top: -55px;
    height: 57px;
    @media(max-width: 700px) {
        width: 100%;
    }
`

interface BottomProps {
    clickPlayGames: Function,
    openFreebieFlow: Function
};

const Bottom: React.FC<BottomProps> = ({
    clickPlayGames,
    openFreebieFlow,
}) => {

    const history = useHistory();
    const [testMobileContent, setTestMobileContent] = useState(<Testimonal props={testimonalProps[0]}></Testimonal>);
    const [viewTest, setViewTest] = useState(0);

    const goToGameNightBundle = () => {
        history.push('/gamenight');
    };

    const nextTest = () => {
        let nxtTest = viewTest === 2 ? 0 : viewTest + 1;
        forceChangeTest(nxtTest);
    }

    const previousTest = () => {
        let nxtTest = viewTest === 0 ? 2 : viewTest - 1;
        forceChangeTest(nxtTest);
    }

    const forceChangeTest = (newTest: number) => {
        setViewTest(newTest);
        setTestMobileContent(<Testimonal props={testimonalProps[newTest]}></Testimonal>);
    }

    return (
        <div className="bottom">
            <Curve />
            <CardsContainer>
            <div className="cards">
                <img src={cards} alt="" />
            </div>
            <div className="text">
                <img src={wiggle} alt="" />
                <p>Play with relevant words</p>
                <div>
                    <span>Use start up or industry specific packs to have work-safe and relevant words for games.
                        You can even create your own word pack just email <a href="mailto:contact@ven.games">contact@ven.games</a> for more information!
                    </span>
                </div>
            </div>
            </CardsContainer>
            <TestimonialSection className="">
                <CurveSectionTop/>
                <div className="phone-tablet">
                    <img src={wiggle} alt="" />
                    <p>At home or on the go.</p>
                    <p>All our games are desktop and mobile friendly.
                        Just send a join link to your friends and they can join from any device!</p>
                </div>
                <div className="families">
                    <img src={wiggle} alt="" />
                    <p>Learn why companies around the world are playing VEN</p>
                </div>
                <div className="video-wrapper">
                    <iframe src="https://www.youtube.com/embed/7aV2I4zPyTI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                
                <div className="testimonals">
                    {testimonals}
                </div>
                <div className="testimonals-mobile" id="mobileTestimonals">
                    {testMobileContent}
                    <div className="steppers">
                        <div className="back-arrow" onClick={previousTest}><p>{'<'}</p></div>
                        <div className={`step ${viewTest === 0 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(0)}>{' '}</div>
                        <div className={`step ${viewTest === 1 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(1)}>{' '}</div>
                        <div className={`step ${viewTest > 1 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(2)}>{' '}</div>
                        <div className="forward-arrow" onClick={nextTest}><p>{'>'}</p></div>
                    </div>
                </div>
            </TestimonialSection>
            <CurveSectionFooter/>
            <FooterContainer>
                <div className="call-to-action-footer">
                    <img src={wiggle} alt="" />
                    <p>Organize a VEN Team Building Event!</p>
                    <p>Your team will thank you for it.</p>
                    <GreenButton onClick={() => openFreebieFlow(1)}>Play Games</GreenButton>
                </div>
                <div className="faces-left"></div>
                <div className="faces-right"></div>
            </FooterContainer>
            <MainFooter/>
        </div>
    );
};

const TestimonialSection = styled.div`
    background-color: rgba(116, 88, 253, 1);
    background-image: url(${testimonialBackground}), linear-gradient(135deg, rgba(116, 88, 253, 1) 0%, rgba(45, 17, 146, 1) 100%);
    background-repeat: no-repeat;
    background-size: contain;

    @media(max-width: 700px) {
        background-image: linear-gradient(135deg, rgba(116, 88, 253, 1) 0%, rgba(45, 17, 146, 1) 100%);
 
        &::before {
            content: '';
            display: block;
            height: 514px;
            width: 386px;
            margin-top: -297px;
            position: absolute;
            background-repeat: no-repeat;
            background-image: url(${devicesMobile});
        }
    }
    /* &::before {
        content: '';
        background-image: url(${curveSectionTop});
        display: block;
        background-size: 100% 136px;
        background-repeat: no-repeat;
        width: 130rem;
        height: 156px;

        transform-origin: top;
        transform: translateY(-1px);

        @media(max-width: 700px) {
            display: none;
        }
    }

    &::after {
        content: '';
        display: block;
        background-image: url(${curveSectionFooter});
        background-size: 100% 100px;
        background-repeat: no-repeat;
        width: 130rem;
        height: 57px;
        /* transform: translateY(-1px); */

        /* @media(max-width: 700px) {
            display: none;
        }
    }  */

`
  

const CardsContainer = styled.div`
   display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 5rem;

    .cards img {
        width: 100%;
    }
    .text{
        width: 46rem;
    }
    p {
        font: 400 4.0rem Bitter;
        margin-top: 2.0rem;
    }
    div {
        margin-top: 2.0rem;
    }
    span {
        font: 400 2.5rem Oswald;
    }

    @media(max-width: 700px) {
        flex-direction: column;
        margin: 5rem;

        .text{
            width: inherit;
        }
        p {
            font: 400 4.0rem Bitter;
            margin-top: 2.0rem;
        }
        div {
            margin-top: 2.0rem;
        }
        span {
            font: 400 2.5rem Oswald;
        }
    }
`
const FooterContainer = styled.div`
    display: grid;
    grid-template-columns: 0.7fr 1fr 0.7fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas:
    "left center right";

    overflow: hidden;

    .call-to-action-footer {
        grid-area: center; 
    
        background: #FAFAFA;
        height: 65rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
            margin-top: 15px;
        }

        p:first-of-type {
            color: #303744;
            font: 400 5.0rem Bitter;
            width: 70.0rem;
            text-align: center;
            margin-top: 2.0rem;
        }

        p:last-of-type {
            color: #303744;
            font: 400 2.5rem Oswald;
            width: 54.0rem;
            text-align: center;
            margin-top: 2.0rem;
        }
    }

    .faces-left {
        grid-area: left;
        background-image: url(${faces});
        display: block;
        background-repeat: no-repeat;
        background-position: center;
    }
    
    .faces-right {
        grid-area: right;
        background-image: url(${faces2});
        display: block;
        background-repeat: no-repeat;
        background-position: center;
    }

    @media(max-width: 700px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 0px 0px;
        grid-template-areas:
        "center";

        &::after {
            content: '';
            display: block;
            height: 514px;
            width: 386px;
            margin-top: -27px;
            position: absolute;
            background-repeat: no-repeat;
            background-image: url(${facesMobile});
        }

        .call-to-action-footer {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            padding-left: 2.0rem;
            grid-area: center; 
            background: #FAFAFA;
            height: 432px;

            p:first-of-type {
                color: #303744;
                font: 400 4.0rem Bitter;
                width: inherit;
                text-align: left;
            }

            p:last-of-type {
                color: #303744;
                font: 400 2.5rem Oswald;
                width: inherit;
            }
        }

    .faces-left {
        display: none;
    }

    .faces-right {
        display: none;
     }
}
`
export default Bottom;
