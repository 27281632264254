import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MainHeader } from '../../organisms/MainHeader';
import { parse } from 'papaparse';
import Form from "@rjsf/core";
import _ from "lodash";

import 'react-datasheet/lib/react-datasheet.css';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { MoonLoader } from 'react-spinners';

const RolesPage: React.FC = () => {

  const search = useLocation().search;
  const token = new URLSearchParams(search).get('token');

  const [requested, setRequested] = useState(false);
  const [response, setResponse] = useState(Object);
  const [responseText, setResponseText] = useState('');

  const requestRole = async () => {
    const url = process.env.FUNCTION_URL //'http://localhost:5001/ven-games---staging/us-central1/app'; // ;
       
    const response = await fetch(`${url}/roles/`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST', body: JSON.stringify({
        token
      })
    });
    setRequested(true);
    if (!response.ok) {
      const responseText = await response.text();
      setResponseText(responseText);
      return;
      // let json = await response.json();
      // console.log(json);
    }
    setResponse(response);
  }

  
  React.useEffect(() => {
    requestRole();
  }, []);

  return (

    <Wrapper>
      <Helmet>
        <title> Roles | VEN Games </title>
      </Helmet>
      <div className="main-account">
        <div className="main-header-account">
          <MainHeader darkTheme={true} />
        </div>
        <div className="main-account">
          {
            !requested ?
              <div className="spinner-wrapper">
                <MoonLoader
                  size={50}
                  color={"#303744"}
                  loading={true}
                />
              </div>
               : !token
               ?
               <div className="not-logged">
                 <p>No token, so there is not way to give user permission</p>
               </div>
              : !response.ok
                ?
                <div className="not-logged">
                  <p>Something went wrong:</p>
                  <p>{responseText}</p>
                </div>
                :
                <div className="not-logged">
                  <p>All right, the user now have permission </p>
                </div>
          }
        </div>
      </div>
    </Wrapper>
  )      
}

const Wrapper = styled.div`
  /* max-width: 1200px; */
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .main-account{
    background: #eeeeee;
    /* margin: auto; */
  }

 form {
   margin: 40px;
   
   button[type=submit]{
     width: 100%;
     font-size: 1.5rem;
   }
 }
`;

const DropboxArea = styled.div`
  margin: 5%;
  background: lightgray;
  border: dashed 2px black;
  padding: 7%;

  display: flex;
  flex-direction: column;
  align-items: center;
`


export default RolesPage;
