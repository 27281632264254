/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import styled from '@emotion/styled';
import { COLOR_GRAY } from "@ven/shared/components/styling/colors";

import React, { useEffect, useMemo } from 'react';

import { DrawingCanvas } from '@ven/games/scribbler/components/organisms/DrawingCanvas';
import { DrawingToolbox } from '@ven/games/scribbler/components/templates/desktop/DrawingToolbox';

import { GameStateContext } from '@ven/games/scribbler/gameplay/GameStateContext';
import type { PlayerID } from '@ven/shared/core/gameplay/GameSessionData';

import { configuration } from '@ven/games/scribbler/drawing/DrawingSettings';

import { DrawingSettingsProvider } from '@ven/games/scribbler/drawing/DrawingSettings';
import { DrawingPalette } from '@ven/games/scribbler/components/organisms/DrawingPalette';
import { GameHeader } from '@ven/games/scribbler/components/organisms/GameHeader';
import { PlayerCard } from '@ven/games/scribbler/components/organisms/PlayerCard';
import { ChatPanel } from '@ven/games/scribbler/components/organisms/ChatPanel';
import { OverlayDialog_ChooseWord } from '../../organisms/OverlayDialog_ChooseWord';
import { WordOrHint } from "../../atoms/WordOrHint";
import { OverlayDialog_Wait } from "../../organisms/OverlayDialog_Wait";
import { TimerCorner } from "../../organisms/TimerCorner";
import { OverlayDialog_GameOver } from "../../organisms/OverlayDialog_GameOver";
import { OverlayDialog_PreTurn } from "../../organisms/OverlayDialog_PreTurn";
import { EmptyCanvasFilling } from "../../organisms/EmptyCanvasFilling";
import { HowToPlay_Scribbler } from "./HowToPlay";
import { WordOverCanvas } from "../../molecules/WordOverCanvas";
import { audioService } from "@ven/shared/core/services/SoundService";
//@ts-ignore
import CorrectSound from "@ven/shared/components/assets/sounds/correct.mp3"
import _ from "lodash";
interface Props {
  myUserId: PlayerID,
}
export const DesktopInGameContent: React.FC<Props> = ({ myUserId }) => {
  const { game, data } = React.useContext(GameStateContext)

  const [showHowToPlay, setShowHowToPlay] = React.useState(false)


  const joined = (data?.players || {})[myUserId] !== undefined
  const myTurn = data?.state.turn.player === myUserId && !game.amSpectator()
  const currentPlayer = data?.state.turn.player

  const watchOnly = !joined || !myTurn || game.amSpectator();

  const isDrawingStage = !!data?.state?.turn.word

  if (!data) {
    return null
  }

  const showPreTurnModal =
    data.state.turn.number > 1 &&
    (!data.state.turn?.started || !myTurn)


  const messages = data.state.turn?.chat
  const [chatMessagesCount, setChatMessagesCount] = React.useState(() => messages ? Object.keys(messages).length : 0)
  if (messages && Object.keys(messages!).length > chatMessagesCount) {
    setChatMessagesCount(messages ? Object.keys(messages).length : 0)
  }
  useEffect(() => {
    if (!messages)
      return;

    const lastMessage = _.last(Object.keys(messages));
    if (lastMessage && messages[lastMessage].correct)
      audioService.play(CorrectSound, .5);
  }, [chatMessagesCount])


  return (
    <Wrapper>
      {
        showHowToPlay &&
        <HowToPlay_Scribbler handleClose={() => setShowHowToPlay(!showHowToPlay)} absolute={true} />
      }

      <GameHeader key='header' myUserId={myUserId} />

      <GameContent>

        <LeftBit>
          {
            Object.entries(data.players).sort((a, b) => {
              const [idPlayerA, dataPlayerA] = a;
              const [idPlayerB, dataPlayerB] = b;
              return dataPlayerB.score - dataPlayerA.score;
            }).map(([id, playerData], i) => (
              <PlayerCard key={i} {...{
                name: playerData?.username || '',
                avatar: playerData?.avatar,
                score: playerData?.score || 0,
                transparent: !data.presences[playerData.uid]?.online,
                label: playerData.uid == game.data?.host ? 'Boss' : '',
                placement: i + 1, //data.placement,
                drawing: currentPlayer === id,
                guessed: currentPlayer !== id && playerData?.guessed,
              }} />
            ))
          }
        </LeftBit>

        <CenterBit>
          {
            React.useMemo(() => (
              <DrawingSettingsProvider>
                {
                  myTurn &&
                  <DrawingPalette colors={configuration.tools.brushColors} />
                }
                <DrawingCanvas {...{ game, persistent: {} as any, config: configuration.canvas, watchOnly }}>
                  {/* {
                    isDrawingStage && 
                    <WordOverCanvas myUserId={ myUserId } />
                  } */}
                  {
                    isDrawingStage &&
                    <TimerCorner />
                  }
                </DrawingCanvas>
                {
                  myTurn &&
                  <DrawingToolbox {...{ game, optionChoices: configuration.tools }} />
                }

              </DrawingSettingsProvider>
            ), [currentPlayer, isDrawingStage])
          }
          {
            !data.state.turn.started &&
            !data.state.turn.canvas &&
            !myTurn &&
            <EmptyCanvasFilling />
          }
          {
            React.useMemo(() =>
              !!data.state.over &&
              <OverlayDialog_GameOver game={game} key="gameover" />,
              [!!data.state.over]
            )
          }
          {
            !data.state.over &&
            !isDrawingStage &&
            (
              showPreTurnModal
                ?
                <OverlayDialog_PreTurn game={game} />
                :
                myTurn
                  ?
                  <OverlayDialog_ChooseWord game={game} />
                  :
                  <OverlayDialog_Wait />
            )
          }
        </CenterBit>

        <RightBit className='dropping-the-shadow'>
          <ChatPanel history={data.state.turn?.chat || {}} handleSend={myTurn ? undefined : m => game.guessWord(m)} />
          <div className='how-to' onClick={() => setShowHowToPlay(!showHowToPlay)}>How to Play</div>
        </RightBit>

      </GameContent>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  padding: 10px;

  user-select: none;
`
const GameContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 0 0px;
`
const CenterBit = styled.div`
  position: relative;
  width: ${configuration.canvas.width}px;
  height: 100%;
`
const LeftBit = styled.div`
  overflow: hidden;
  margin-right: 12px;
  height: 622px;
  flex: 0 225 225px;

  width: 225px;
`
const RightBit = styled.div`
  /* overflow: hidden; */
  margin-left: 12px;
  height: 628px;
  flex: 0 316 316px;

  width: 316px;
  position: relative;

  .how-to {
    position: absolute;
    bottom: -30px;
    right: 12px;
    
    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */

    text-align: right;
    
    color: #FFFFFF;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`
