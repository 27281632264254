import React from 'react';

import Loader from "react-spinners/PuffLoader";
import { CenteredContent } from '@ven/shared/components/common/CenteredContent';

export const LoadingView:React.FC = () => 
{
  const loading = true
  return (
    <CenteredContent>
      <Loader
        size={50}
        color={"#FFFC"}
        loading={ loading }
      />
    </CenteredContent>
  )
}
