import React from 'react';

import type { GameStateController } from '@ven/games/scribbler/gameplay/GameStateController';
import { DrawingSettingsContext, DrawingTool } from '@ven/games/scribbler/drawing/DrawingSettings';
import { BrushSizeIcon, BucketIcon, DrawToolIcon, EraserIcon, TrashIcon, UndoIcon } from '../../assets/icons';
import { ToolBar } from "@ven/shared/components/game/drawing/ToolBar";

import { undo } from "../../services/UndoCanvasAction";

import styled from '@emotion/styled'

const ToolBarGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  
  .toolbar {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
`

const Separator = styled.div`
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
`

interface Props 
{
  game : GameStateController,
  optionChoices : {
    brushSizes : number[],
  }
}
export const DrawingToolbox:React.FC<Props> = ({ game, optionChoices }) => 
{
  const { settings, updateSettings } = React.useContext( DrawingSettingsContext )

  return (
    <ToolBarGroup>

      <ToolBar buttons={
        [
          { Icon : DrawToolIcon, onClick : () => updateSettings({ tool : DrawingTool.Draw }), selected : settings.tool === DrawingTool.Draw },
          { Icon : EraserIcon, onClick : () => updateSettings({ tool : DrawingTool.Erase }), selected : settings.tool === DrawingTool.Erase },
          { Icon : UndoIcon, onClick : () => undo( game ) },
          { Icon : TrashIcon, onClick : () => game.clearCanvas() },
        ]
      }/>

      <Separator/>
      
      <ToolBar buttons={
        optionChoices.brushSizes.map( (size,i) => ({
            Icon : BrushSizeIcon,
            iconProps: { thickness: [1,3,5,8][ i ] },
            onClick: () => updateSettings({ brushSize : size }),
            selected: size === settings.brushSize,
        }) )
      }/>

    </ToolBarGroup>
  )
}
