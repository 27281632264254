import React from 'react';

import styled from '@emotion/styled'
import { COLOR_DARK, COLOR_HIGHLIGHTER } from '@ven/shared/components/styling/colors';

const Wrapper = styled.div<{ whole:boolean }>`
  padding: 0 12px;
  text-align: center;
  font-family: "Bitter";
  font-weight: bold;
  font-size: 35px;
  text-transform: uppercase;
  letter-spacing: 8px;
  letter-spacing: ${ props => props.whole ? 'auto' : '10px' };
  transition: letter-spacing 150ms;
  color: ${ COLOR_DARK };
  background: ${ COLOR_HIGHLIGHTER };
`

interface Props 
{ 
  word : string
  whole : boolean
}
export const WordOrHint:React.FC<Props> = ({ word, whole }) =>
{
  return (
    <Wrapper whole={ whole }>
      { word }
    </Wrapper>
  )
}
