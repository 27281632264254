import React from 'react';
import firebase from 'firebase';

import { auth, database } from '@ven/core/data/firebase';

import { GetCurrentUserRef, UserDataService } from '@ven/platform/main/services/user/UserDataService';

export const useUserOnlineSiteStatus = () => {
  const ready = !!auth.currentUser?.uid;

  let connectedRef;
  return React.useEffect(() => {
    if (!ready) {
      return;
    }

    let unmount;
    try {
      // const myUserData = React.useContext( UserDataContext );
      GetCurrentUserRef().then(ref => {
        if (!ref) {
          return;
        }
        const setAsOnline = () => {
          ref.update({
            online: true,
            lastOnline: firebase.database.ServerValue.TIMESTAMP,
          })
        }
        const backOnlineOff = ref.on('value', o => {
          const presence = o.val();
          if (presence && presence.online)
            return;
          setAsOnline();
        });

        let infoConnected;
        setAsOnline();
        infoConnected = database.ref(".info/connected")
          .on('value', snap => !!snap.val() && setAsOnline());
        ref.onDisconnect().update({
          online: false,
          lastOnline: firebase.database.ServerValue.TIMESTAMP,
        });
        unmount = () => {
          ref.off('value', backOnlineOff);
          if (infoConnected)
            database.ref(".info/connected").on('value', infoConnected)
          // window.removeEventListener('popstate', checkURL);
        }
      });
    } catch (e) {
      console.warn(e);
    }

    return unmount
  }, [ready]);
}
