import React from 'react';

import { SmallButton } from '@ven/shared/components/common/atoms/SmallButton';

import styled from '@emotion/styled';
// import { MOBILE_BREAK_PX } from '../../styling/breakpoints';

export const HowToPlay:React.FC<any> = ({ howToData, handleClose, absolute, mobileBreak }) =>
{
  return (
    <Container 
    image={ howToData.image } className={ absolute ? "absolute" : "normal" }
    mobileBreak={ mobileBreak || 960 }
    >
      <div className="pad">
        <div className="header">
          <p className="title"> { howToData.title } </p>
          <button className="x-button" onClick={ () => handleClose() }>
            <EmbeddedXImage />
          </button>
        </div>
        <div className="content">
          <div className="text-bit">
            {
              howToData.text.map( section => (
                <div key={ section.heading }>
                  <div className="heading"> { section.heading }: </div>
                  <div className="paragraph"> { section.content } </div>
                </div>
              ) )
            }
          </div>
          <div className="image-bit" />
        </div>
      </div>
    </Container>
  )
}

const EmbeddedXImage = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1L22.834 22.834" stroke="white" strokeWidth="2" strokeLinecap="round"/>
  <path d="M22.834 1L1 22.834" stroke="white" strokeWidth="2" strokeLinecap="round"/>
  </svg>
)

const Container = styled.div<{ image:string, mobileBreak:number }>`
  z-index: 90;
  position: relative;

  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 6px 6px 50px 6px;
  }

  &.normal {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }

  :before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000C;
    z-index: -1;
    animation: fade-in 200ms;
  }

  .pad {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: white;
    border-radius: 12px;
    overflow: hidden;
    z-index: 12;
    
    width: 100%;
    justify-content: flex-start;
  }
  &.absolute .pad {
    width: 100%;
    height: 100%;
  }
  &.normal .pad {
    max-width: 1155px;
    min-height: 772px;
  }

  .header {
    height: 94px;
    width: 100%;
    padding-left: 83px;

    background: #303744;
    color: #FFFFFF;

    font-family: Bitter;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 94px;
    letter-spacing: 1px;
    
    .title {
      animation: fade-in 600ms;
    }

    .x-button {
      background: none;
      border: none;
      position: absolute;
      top: 47px;
      right: 30px;
      transform: translate(-50%,-50%);
      transition: transform 200ms;
      cursor: pointer;

      &:hover {
        transform: translate(-50%,-50%) scale(1.2);
      }
      &:active, &:focus {
        outline: none;
      }
      &:active {
        transform: translate(-50%,-50%) scale(.9);
        transition: transform 60ms;
      }
      
      @keyframes rotateIn { from { opacity: 0; transform: translate(-50%,-50%) rotate(45deg); } }
      animation: rotateIn 600ms;
    }
  }

  .content {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    position: relative;
    height: 100%;
  }

  .text-bit {
    flex: 1 1;
    display: block;
    color: #303744;
    height: 100%;
    padding: 83px;

    .heading {
      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 40px;
      letter-spacing: 0.533333px;
      text-transform: uppercase;
      color: #3148BF;

      width: fit-content;
      border-bottom: 2px solid #FFE499;
      margin-bottom: 4px;
    }

    .paragraph {
      white-space: pre-line;
      font-family: Oswald;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 36px;
      color: #303744;
      margin-bottom: 40px;
    }
    
    @keyframes xSlide { from { opacity: 0; transform: translate(-100px,0); } }
    animation: xSlide 600ms;
  }

  .image-bit {
    width: 463px;
    height: 100%;
    min-height: 678px;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: contain;
    background-image: linear-gradient(131.16deg, #2941D4 -3.02%, #7458FD 98.61%);
    background-image: url(${ props => props.image }), linear-gradient(131.16deg, #2941D4 -3.02%, #7458FD 98.61%);

    @keyframes bgSlide { from { opacity: 0; background-position-x: 100px; } }
    animation: bgSlide 600ms;
  }
  
    
  @media (min-width: ${ props => props.mobileBreak }px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 24px;
    
    &.normal .pad {
      .text-bit {
        height: 100%;
        overflow: auto;
      }
      
      @media (max-height: 975px) {
        .content {
          overflow: hidden;
        }
        min-height: auto;
        height: 100%;
      }
    }
  }
    
  @media (max-width: ${ props => props.mobileBreak }px) {
    overflow-y: auto;

    &.normal {
    }

    &.normal .pad {
      width: 90%;
      max-width: 463px;
      margin: 5% auto;

      .header {
        height: 94px;
        width: 100%;
        padding-left: 30px;
        font-size: 20px;
        .title {
          @keyframes xxSlide { from { opacity: 0; transform: translate(100px,0); } }
          animation: xxSlide 600ms !important;
        }
      }
      .content {
        flex-direction: column;
        width: 100%;

        .text-bit {
          width: 100%;
          padding: 30px;
          @keyframes xSlide { from { opacity: 0; transform: translate(-100px,0); } }
          animation: xSlide 600ms;
        }
        .image-bit {
          @keyframes bgSlide { from { opacity: 0; background-position-y: 100px; } }
          animation: bgSlide 600ms;
        }
      }
    }
  }
`













