import React, { PropsWithChildren, useEffect, useState } from 'react';

import styled from '@emotion/styled';

//@ts-ignore
import wiggle from '../assets/wiggle.svg';
import { GreenButton } from './atoms/GreenButton';
import type { GameStateController } from '@ven/shared/core/gameplay/GameStateController';
import type { GameSessionData } from '@ven/shared/core/gameplay/GameSessionData';
import { use } from 'chai';

export const InactiveTabView:React.FC<PropsWithChildren<any>> = ({game}) => 
{
  const isInGame = !!game;

  if(isInGame) {
    game.destroy();
  }

  return (
    <Wrapper>
      <div className="pad dropping-the-shadow">
        <img src={wiggle}/>
        {/* <h1> You seem to have opened this game in a different tab. </h1> */}
        {/* <p> Please use the other tab, or reftesh this page to continue. </p> */}
        <h1> You opened VEN Games in another tab. </h1>
        <p> Please play there. </p>

        {
          !isInGame ? null : <GreenButton onClick={() => {
            // setForceSpectator(true);
            const gameUrl = window.location.href.replace('/play/', '/watch/');
            window.location.replace(gameUrl);
          }}>
            Watch as a spectator
        </GreenButton>
        }
        
        {/* <GreenButton onClick={() => window.location.reload()}>
            Continue here
        </GreenButton> */}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pad {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    background: white;
    border-radius: 12px;
    width: 90vw;
    max-width: 720px;
    min-height: 30%;
    padding: 80px 40px;
    
    font-family: Bitter;
    color: #303744;

    h1 {
      font-weight: bold;
      font-size: 40px;
      line-height: 1.4;
      text-align: center;
    }
    
    p {
      text-align: center;
      font-weight: normal;
      font-size: 25px;
      line-height: 40px;
    }
  }
`
