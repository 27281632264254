import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Modal, Nav, NavItem, NavLink, Tab, Table } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { HostEventsService } from '@ven/shared/core/services/HostEvents';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router';
import { GetCurrentUserData } from '@ven/platform/main/services/user/UserDataService';
import styled from '@emotion/styled';
import GameInfo from './GameInfo';
import { GAMES } from '@ven/platform/main/games';
import { HostEventContext } from '.';
import { ClipboardCopy } from '@ven/shared/components/common/atoms/ClipboardCopy';

interface SessionInfoProps {
  data?: any;
}

const SessionInfo: React.FC<SessionInfoProps> = (props) => {

  const { sessionId: sessionIdParam } = (useParams() as any);

  const [sessionIdParamFirstTime, setSessionIdParamFirstTime] = useState(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [sessions, setSessions] = useState(new Array);
  const [games, setGames] = useState(Object);
  const [hosts, setHosts] = useState(Object);

  const [currentGame, setCurrentGame] = useState(Object);
  const [selectedHost, setSelectedHost] = useState(Object);


  const { session, setSession } = useContext(HostEventContext);

  const allGameTypes = Object.keys(GAMES);
  React.useEffect(() => {
    getHostEventSession(props.data?.id);
  }, []);

  const [userData, setUserData] = useState(Object);

  useEffect(() => {
    GetCurrentUserData().then(userData => {
      setUserData({
        ...userData
      })
    });
  }, []);

  useEffect(() => {
    if (!session?.id)
      return

    setCurrentGame(session.game.toLowerCase());
    setSelectedHost(session.creator);
  }, [session]);

  useEffect(() => {

    if(!sessions.length) {
      setSession(null)
      setCurrentGame(null)
      setSelectedHost(null)
    }
    const groupGames = _.groupBy(sessions, 'game');
    const groupGamesKey = Object.keys(groupGames)
    setGames(groupGames)

    if (!groupGamesKey.length) {
      setSession(null)
      return
    }

    let sessionParam;
    //sessionIdParam is not updating accordilly changes for some reason, which is messing up with delete
    //so I had to add a sessionIdParamFirstTime to handle delete.
    if (sessionIdParam && sessionIdParamFirstTime) {
      sessionParam = sessions.find(s => s.id == sessionIdParam);
      setSessionIdParamFirstTime(false);
    }

    if (!sessionParam) {
      let gameKey = groupGamesKey[0];
      let host = 0; 
      
      if(session && groupGamesKey.includes(session.game)) { //handle delete
        gameKey = session.game;
        host = session.creator;
        const sessionFound = sessions.find(s => s.id == session.id);
        if(!sessionFound) { //game deleted
          setSession(groupGames[gameKey][0]) //set the firstGame
        }
      }
      setCurrentGame(gameKey);
      const creatorGroup = _.groupBy(groupGames[gameKey], 'creator');
      setHosts(creatorGroup);

      const hostsKey = host ? host : Object.keys(creatorGroup)[0];
      setHostTab(hostsKey);
      return;
    }

    const gameKey = sessionParam.game;
    setCurrentGame(gameKey);
    const creatorGroup = _.groupBy(groupGames[gameKey], 'creator');
    setHosts(creatorGroup);
    const hostsKey = sessionParam.creator;
    setHostTab(hostsKey ? hostsKey : Object.keys(creatorGroup)[0]);
    setSessionTab(sessionParam.id);
  }, [sessions]);

  // useEffect(() => {
  //    = ;
  // }, [session]);

  useEffect(() => {
    const hostGames = _.groupBy(games[currentGame], 'creator');
    if (!_.isEmpty(hostGames) && session && !!session.id &&
      hostGames[selectedHost] && !hostGames[selectedHost].find(s => !!s.id && session.id == s.id)) {
      setSession(hostGames[selectedHost][0]);
    }
  }, [selectedHost]);

  const setGameTab = async (key) => {
    setCurrentGame(key.toLowerCase());
  }

  useEffect(() => {
    const hostGames = _.groupBy(games[currentGame], 'creator');
    if(_.isEmpty(hostGames))
      return;

    setHosts(hostGames)
    if (!hostGames[selectedHost]) {
      setSession(hostGames[Object.keys(hostGames)[0]][0]);
      return;
    }

    if (session && session.id && !hostGames[selectedHost].find(s => session.id == s.id)) {
      setSession(hostGames[selectedHost][0]);
    }

    // window.location.pathname = key;
    console.log(currentGame)
    }, [currentGame]);

  const setHostTab = async (key) => {
    setSelectedHost(key)
    // history.replace(key); //pushState( {} , '', '?fare=standar' );
    // window.location.pathname = key;
    console.log(key)
  }

  const setSessionTab = async (key) => {
    setSession(sessions.find(s => s.id == key));
    // history.replace(props.data.id+'\\'+key);
    // history.replace(key); //pushState( {} , '', '?fare=standar' );
    // window.location.pathname = key;
  }

  useEffect(() => {
    if (session && session.id)
      window.history.pushState({}, '', '/eventHost/' + props.data.id + '/' + session.id);
  }, [session]);

  async function deleteHostEventSession() {
    await HostEventsService.deleteHostEventSession(props.data.id, session.id);
  }

  async function getHostEventSession(id) {
    await HostEventsService.getHostEventSessions(id, data => {
      setSessions(data);
    });

    // console.log(session)
  }

  return (
    !sessions.length ? null :
    <Wrapper>
      <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
    <Modal.Title>Modal heading</Modal.Title>
  </Modal.Header> */}
        <Modal.Body>You are about to delete {session?.name} session. Are you sure?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => {
            deleteHostEventSession();
            handleClose();
          }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Tab.Container activeKey={currentGame ? currentGame : null} onSelect={(k) => setGameTab(k)}>
        <Col className={currentGame} sm={12}>
          <Row>
            {
              games != null &&
              allGameTypes.map(e =>
                <Nav className="game-tabs" variant="pills" key={e}>
                  <NavItem title={e}>
                    <NavLink disabled={!Object.keys(games).includes(e.toLowerCase())} eventKey={e}>{_.capitalize(e)}</NavLink>
                  </NavItem>
                </Nav>
              )
            }
          </Row>
        </Col>
      </Tab.Container>

      <Card className={`game-card ${currentGame}`}>
        <Card.Body>
          <Tab.Container activeKey={selectedHost ? selectedHost : null} onSelect={(k) => setHostTab(k)}>
            <Col sm={12}>
              <Row>
                {
                  hosts != null && Object.keys(hosts).map(e =>
                    <Nav variant="pills" key={e}>
                      <NavItem title={e == userData.uid ? 'me' : e}>
                        <NavLink eventKey={e}>{e == userData.uid ? 'me' : hosts[e][0].creatorName}</NavLink>
                      </NavItem>
                    </Nav>
                  )
                }
                <span className="nav-label">Hosts</span>
              </Row>
            </Col>
          </Tab.Container>

          <Card className={`session-card`} >
            <Card.Body>
              <Tab.Container activeKey={session ? session.id : null} onSelect={(k) => setSessionTab(k)} >
                <Row>
                  <Col sm={3} className="nav-col" >
                    <Nav className={`session-card-pills flex-column`} variant="pills">
                      {
                        hosts[selectedHost] != null && hosts[selectedHost].map(e =>
                          <NavItem title={e.name} key={e.id}>
                            <NavLink eventKey={e.id}>{e.name}</NavLink>
                          </NavItem>)
                      }
                    </Nav>
                  </Col>
                  {
                    session && session.id != null &&
                      <Col sm={9}>
                        <Button disabled={selectedHost != userData.uid} variant="danger" onClick={handleShow}>
                          Delete
                        </Button>
                      
                        <p>{session.name} ({session.creatorName} - {session.game})</p>
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Game</th>
                              <th>Players</th>
                              <th>Status</th>
                              <th>Breakout Room</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              session.games.map((g, i) => (
                                <GameInfo data={{ ...g, index: i + 1, session, eventId: props.data.id }} key={g.code} />
                              ))
                            }
                          </tbody>
                        </Table>
                        <ClipboardCopy copyText={session.games.map((g, i) => `Group ${i + 1}:\n${window.location.origin}/play/${g.code}\n\n`)} />
                      </Col>
                  }
                </Row>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>

    </Wrapper>
  )
}

const Wrapper = styled.div`
margin-top: 25px;
.nav-item {
  outline: 1px solid rgba(0,0,0,0.1);
  background: #fff;
}
.nav-col {
  /* background: #ffffff83; */
}
.nav-label {
  display: flex;
  align-self: center;
  position: absolute;
  right: 0;
  color: white;
}

.table thead th, .table tbody td{
    border: 1px solid #0000006b;
}

.table thead th {
  background-color: rgba(0,0,0, 20%);
  color: rgba(0,0,0, 80%);
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: rgb(255 255 255 / 45%);
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(255 255 255 / 67%);
}


.card-header-pills{
  margin: 0;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link, .brokenmsg.card {
  border-radius: 0;
}


.brokenmsg {
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link, &.card, .session-card-pills .nav-link.active {
    background-color: #0a7e3e;
  }
  &.game-card {
    .card-body, .card-body .nav-pills .nav-link.active {
      background-color: rgb(10, 126, 62, 35%);
      color: rgb(0 0 0 / 90%);
    }
  }
  .session-card a.nav-link.active {
    background-color:  rgb(10, 126, 62) !important;
    color: white !important;
  }
}

.scribbler {
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link, &.card, .session-card-pills .nav-link.active {
    background-color: #756ef2;
  }
  &.game-card {
    .card-body, .card-body .nav-pills .nav-link.active {
      background-color: rgb(117, 110, 242, 35%);
      color: rgb(0 0 0 / 90%);
    }
  }
  .session-card a.nav-link.active {
    background-color: rgb(117, 110, 242) !important;
    color: white !important;
  }
}

.outlaw {
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link, &.card, .session-card-pills .nav-link.active {
    background-color: #040794;
  }
  &.game-card {
    .card-body, .card-body .nav-pills .nav-link.active {
      background-color: rgb(14, 7, 148, 35%);
      color: rgb(0 0 0 / 90%);
    }

    .session-card a.nav-link.active {
      background-color: rgb(14, 7, 148) !important;
      color: white !important;
    }
  }
}

.card {
  border-radius: 0;
}
.game-card, .game-card .card-body {
  border-top: 0px;
}
.game-card .card {
  border: 0px;
}
`

export default SessionInfo;
