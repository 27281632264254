import React, { useState } from 'react';

import { GameStateContext } from '../../gameplay/GameStateContext';

import { useWindowSize } from '@ven/shared/components/hooks/useWindowSize';
import { delay } from '@ven/core/utils/promise';
import { Dimensions } from '@ven/games/wordplay/other/dimensions';
import { MOBILE_BREAK_PX } from '@ven/shared/components/styling/breakpoints';

import { desktopViews } from './desktop/InnerContents';
import { mobileViews, InnerContent_TurnNotStarted_FirstTurn } from './mobile/InnerContents';
import { SelfScalingContent } from '@ven/shared/components/game/wrappers/SelfScalingContent';
import { FoulBit } from '../organizms/FoulBit';
import { BottomRibbon } from '@ven/shared/components/common/atoms/BottomRibbon';
import { HowToPlay_Outlaw } from './HowToPlay_Outlaw';

import { LastSecondsBuzzer } from '@ven/shared/components/common/misc/LastSecondsBuzzer';

//@ts-ignore
import ExImage from "@ven/shared/components/assets/wordplay/ex.png"
//@ts-ignore
import BuzzerSound from "@ven/shared/components/assets/sounds/Wrong Buzzer.mp3"

import styled from '@emotion/styled';
import { LogService } from '@ven/platform/main/services/log/LogService';
import { audioService } from '@ven/core/services/SoundService';
import { MainHeader } from '@ven/platform/main/components/organisms/MainHeader';
import PlayersModal, { PlayersModalContext } from '@ven/shared/components/common/PlayersModal';

export const InGameContent: React.FC = () => {
  const { width, height } = useWindowSize();
  const USE_MOBILE_LAYOUT = width <= MOBILE_BREAK_PX;
  const designDimensions = { ...USE_MOBILE_LAYOUT ? Dimensions.mobile : Dimensions.desktop }
  const views = USE_MOBILE_LAYOUT ? mobileViews : desktopViews

  const HIDE_TOP_AND_BOTTOM_BAR = height < MOBILE_BREAK_PX;
  if (HIDE_TOP_AND_BOTTOM_BAR) {
    designDimensions.insetY = 0;
  } else {
  }

  const { game } = React.useContext(GameStateContext)

  const data = game.data!
  const myUserId = game.myUserId!
  const myState = game.data.players[myUserId]

  const [foul, setFoul] = React.useState<{ caller: string, word: string }>()
  const [show, setShow] = useState(false);
  
  React.useEffect(() => {
    const ref = game.ref.turn().child('fouls')
    const onNewFoul = async (data: firebase.database.DataSnapshot) => {
      setFoul(data.val())

      audioService.play(BuzzerSound, .1);

      await delay(2)
      try {
        await ref.child(data.key!).remove()
      } catch (err) {
        await LogService.log(err, 'Error: Remove GameSession fouls.')
      }

      setFoul(undefined)
    }
    ref.on("child_added", onNewFoul)
    return () => ref.off("child_added", onNewFoul)
  }, [])

  const [showHowToPlay, setShowHowToPlay] = React.useState(false)

  if (!data.state?.turn?.player) {
    return null
  }

  const turnStarted = data.state.turn.started
  const turnPlayerId = data.state.turn.player
  const turnTeam = data.players[turnPlayerId].team
  const myTurn = turnPlayerId === myUserId && !game.amSpectator();
  const myTeamTurn = turnTeam === myState?.team ?? 0

  const firstTurn = game.data.state.turn.number <= 1

  const InnerContentContainer = styled.div`
    height: 100%;
    justify-content: center;
    user-select: none;
    position: relative;
    width: ${designDimensions.width}px;
  `

  const {
    InnerContent_GameOver,
    InnerContent_TurnNotStarted,
    InnerContent_TurnPlayer,
    InnerContent_Ally,
    InnerContent_Opponent,
  } = views

  const memoWatch = [
    USE_MOBILE_LAYOUT,
    !!data.state.over,
    turnStarted,
    firstTurn,
    myTurn,
    myTeamTurn,
  ]

  const playerModal = Object
  .entries(game?.data?.players || {})

  return (
    <>
      <MainHeader inGame={true} />
      <PlayersModalContext.Provider value={{show, setShow}}>
        <SelfScalingContent className="inner-content outlaw" designDimensions={designDimensions} fixed={true}>
          <PlayersModal players={playerModal} game={game}></PlayersModal>
          {
            React.useMemo(() =>
              <InnerContentContainer>
                {
                  !!data.state.over ?
                    <InnerContent_GameOver />
                    :
                    !turnStarted && (!firstTurn || !USE_MOBILE_LAYOUT) ?
                      <InnerContent_TurnNotStarted />
                      :
                      game.amSpectator() ?
                        <InnerContent_Ally />
                        :
                        myTurn ?
                          <InnerContent_TurnPlayer key={0} />
                          :
                          myTeamTurn ?
                            <InnerContent_Ally />
                            :
                            <InnerContent_Opponent />
                }
                {
                  USE_MOBILE_LAYOUT && !turnStarted && firstTurn &&
                  <InnerContent_TurnNotStarted_FirstTurn />
                }
              </InnerContentContainer>
              , memoWatch)
          }

        </SelfScalingContent>

        {
          !USE_MOBILE_LAYOUT &&
          <BottomRibbon game={game} gameName="Outlaw" callbacks={{ showHowToPlay: () => setShowHowToPlay(true) }} />
        }

        {
          foul &&
          //foul.caller !== myUserId &&
          <FoulBit foul={foul} />
        }

        {
          showHowToPlay &&
          !USE_MOBILE_LAYOUT &&
          <HowToPlay_Outlaw handleClose={() => setShowHowToPlay(false)} absolute={false} mobileBreak={MOBILE_BREAK_PX} />
        }
      </PlayersModalContext.Provider>
      <LastSecondsBuzzer game={game} />
    </>
  )
}
