import React, { useEffect, useState } from 'react';
import {
    SignInWithGoogle,
    SignInWithFacebook,
    SignUpWithEmailAndPassword,
} from '../../../services/AuthenticationService'

import peopleImg from '../../../assets/images/choose_bundle_img.svg';
import corner1Img from '../../../assets/images/choose_corner_1.svg';
import corner2Img from '../../../assets/images/choose_corner_3.svg';
import closeBlack from '../../../assets/images/close_black.svg';
import closeWhite from '../../../assets/images/close_white.svg';
import usernameIcon from '../../../assets/images/user_icon.svg';
import emailIcon from '../../../assets/images/email_icon.svg';
import passwordIcon from '../../../assets/images/password_icon.svg';
import confirmationImg from '../../../assets/images/confirmation_image.svg';
import facebookButton from '../../../assets/images/facebook_button.png';
import googleButton from '../../../assets/images/google_button.png';
import line from '../../../assets/images/line.svg';

import './styles.scss'
import { GetCurrentUserData, UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { useHistory } from 'react-router-dom';
import { CouponService } from '@ven/shared/core/services/CouponService';
import { Helmet } from 'react-helmet';

const FriendRegistration = () => {
    const query = new URLSearchParams(window.location.search);
    const history = useHistory();
    const [code, setCode] = useState('');
    const [type, setType] = useState(query.get('type'));

    useEffect(() => {
        const urlCode = query.get('friendcode') || '';
        // setType( || '');

        setCode(urlCode);

        if (!(urlCode && urlCode !== '')) {
             // No code on URL, go to home
             setStep(2);
             return;
        }
        
        // There is a code on the URL
        CouponService.IsUnclaimedCode(urlCode).then(unclaimed => {
            if (unclaimed) {
                // Code is good, use it
                GetCurrentUserData().then((userData) => {
                    if (!userData || UserDataService.get('isAnon') == 'true') {
                        // Not logged in, proceed with registration
                        setStep(1);
                    } else {
                        // Logged in, add coupon to account
                        putGnbCodeOnAccount(urlCode);
                    }
                })
            } else {
                // Code does not exists or was already used, show error message
                setStep(2);
            }
        });
    }, []);

    const [step, setStep] = useState(1);
    const [disableButtons, setDisableButtons] = useState(false);
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [userPassword, setPassword] = useState('');
    const [keepInformed, setKeepInformed] = useState(false);

    const [emailError, setEmailError] = useState(false);
    const [emailTextError, setEmailTextError] = useState('This is not a valid email.');
    const [passwordError, setPasswordError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);

    const goToHome = () => {
        history.push('/');
    }

    const goToAccount = () => {
        history.push('/account');
    }

    const registerNewUser = async (e) => {
        e.preventDefault();

        checkEmailError(email);
        checkPasswordError(userPassword);
        checkUsernameError(userName);

        if (!usernameError && !emailError && !passwordError) {
            setDisableButtons(true);

            const authenticated = await SignUpWithEmailAndPassword(userName, email, userPassword, keepInformed);

            if (authenticated) {
                // User resgitered, setup GNB code on account
                putGnbCodeOnAccount(code);
                return;
            }
            // Error on authenticating user, show error message
            setEmailTextError('Email already registered.');
            setEmailError(true);
        }
    }

    const putGnbCodeOnAccount = async (urlCode: string) => {
        const unclaimed = await CouponService.IsUnclaimedCode(urlCode);
        if (unclaimed) {
            const packageName = type === 'gnb' ? 'Game Night Bundle' : 'Team Bundle';
            const done = await CouponService.PutCodeOnUserInfo(packageName, urlCode, 'FREE', 'UNUSED');
            if (done) await CouponService.SetCodeAsClaimed(urlCode);
        }
        setStep(4);
    }


    const googleInBundle = async () => {
        setDisableButtons(true);

        const authenticated = await SignInWithGoogle(keepInformed);

        if (authenticated) {
            // User resgitered, setup GNB code on account
            putGnbCodeOnAccount(code);
            return;
        }
        // Error on authenticating user, show error message
        setEmailTextError('Account already registered.');
        setEmailError(true);
    }

    const facebookInBundle = async () => {
        setDisableButtons(true);

        const authenticated = await SignInWithFacebook(keepInformed);

        if (authenticated) {
            // User resgitered, setup GNB code on account
            putGnbCodeOnAccount(code);
            return;
        }
        // Error on authenticating user, show error message
        setEmailTextError('Account already registered.');
        setEmailError(true);
    }

    const checkUsernameError = (value: string) => {
        setUserName(value);
        if (value.length === 0 || value === '') {
            setUsernameError(true);
        } else {
            setUsernameError(false);
        }
    }

    const checkEmailError = (emailValue: string) => {
        setEmail(emailValue);
        if (!email) {
            setEmailTextError('This is not a valid email.');
            setEmailError(true);
        }

        if (typeof email !== 'undefined') {
            let lastAtPos = email.lastIndexOf('@');
            let lastDotPos = email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') == -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
                setEmailTextError('This is not a valid email.');
                setEmailError(true);
            } else {
                setEmailError(false);
            }
        }
    }

    const checkPasswordError = (passwd: string) => {
        setPassword(passwd);
        const passwordArray = passwd.split('');
        let haveUpperCaseLetter = false;
        let haveLowerCaseLetter = false;
        let haveOneNumber = false;
        let minSixChars = passwd.length > 5;
        
        for (const password of passwordArray) {
            haveUpperCaseLetter = ((password === password.toUpperCase()) &&
                (isNaN(parseInt(password, 10) * 1)));

            haveLowerCaseLetter = ((password === password.toLowerCase()) &&
                (isNaN(parseInt(password, 10) * 1)));

            haveOneNumber = !isNaN(parseInt(password, 10) * 1);
        }

        setPasswordError(!haveUpperCaseLetter || !haveLowerCaseLetter || !haveOneNumber || !minSixChars);
    }

    return (
        <div className="friends-registration game-night-steps-modal-landing">
            <Helmet>
                <title> Friend Redeem | VEN Games </title>
            </Helmet>
      
            <div className="game-night-steps-modal-content">
                <div className="close-button" onClick={() => history.goBack()}>
                    <img className="close-desktop" src={closeBlack} alt="close" />
                    <img className="close-mobile" src={closeWhite} alt="close" />
                </div>
                <div className="game-night-steps-modal-content2">
                    <div className={`title-banner ${step === 4 ? 'hide-titles' : ''}`}>
                        <header>
                            <img src={corner1Img} alt="" />
                            <div className="banner">
                                <p>{type === 'gnb' ? 'GAME NIGHT' : 'TEAM'}</p>
                            </div>
                            <img src={corner2Img} alt="Close" />
                        </header>
                        <div className={`${step > 0 ? 'hide-benefits' : ''} `}>
                            <div className="title">
                                <p className="main-title">
                                {type === 'gnb' ? <>     GAME <br />
                                NIGHT <br />
                                </> : <> TEAM  <br /> </> }
                                BUNDLE
                            </p>
                                <img src={peopleImg} alt="Yay!" />
                            </div>
                        </div>
                    </div>
                    <div className={`step-container ${step === 4 ? 'step-container-four' : ''}`}>
                        <div className={`step-one ${step === 1 ? '' : 'hide-step'}`}>
                            <div className="step-one-content">
                                <div className="description">
                                    <p></p>
                                    <p>Just a few steps to go! Let's get you set up.</p>
                                    <p>You will be able to start your bundle from your account page. Once you activate it, you’ll have 48 hours to play!</p>
                                </div>
                                <form>
                                    <div className={`input-box ${usernameError ? 'error-input' : ''}`}>
                                        <img className="input-icon" src={usernameIcon} alt="" />
                                        <input
                                            type="text"
                                            name="username"
                                            maxLength={30}
                                            placeholder="Username"
                                            id="username"
                                            autoComplete="off"
                                            onBlur={(e) => checkUsernameError(e.target.value)}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                        <div></div>
                                    </div>
                                    {usernameError &&
                                        <p className="error">You must have a username.</p>
                                    }
                                    <div className={`input-box ${emailError ? 'error-input' : ''}`}>
                                        <img className="input-icon" src={emailIcon} alt="" />
                                        <input
                                            type="email"
                                            name="userEmail"
                                            placeholder="E-mail"
                                            id="userEmail"
                                            autoComplete="off"
                                            onBlur={(e) => checkEmailError(e.target.value)}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <div></div>
                                    </div>
                                    {emailError &&
                                        <p className="error">{emailTextError}</p>
                                    }
                                    <div className={`input-box ${passwordError ? 'error-input' : ''}`}>
                                        <img className="input-icon" src={passwordIcon} alt="" />
                                        <input
                                            type="password"
                                            name="userPassword"
                                            placeholder="Password"
                                            id="userPassword"
                                            autoComplete="off"
                                            onBlur={(e) => checkPasswordError(e.target.value)}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div></div>
                                    </div>
                                    {passwordError &&
                                        <p className="error">Password needs to be at least 6 characters in length, must include at least one lower case letter, one upper case letter and one number.</p>
                                    }
                                    <div className="check-box">
                                        <div>
                                            <input
                                                type="checkbox"
                                                name="sendUpdates"
                                                id="sendUpdates"
                                                onChange={(e) => setKeepInformed(Boolean(e.target.value))}
                                            />
                                            <div className="one-space">{' '}</div>
                                        </div>
                                        <p>Send me updates when you have new games and content!</p>
                                    </div>
                                </form>
                                <footer>
                                    <button
                                        onClick={(e) => registerNewUser(e)}
                                        disabled={disableButtons || emailError || passwordError || usernameError}
                                    >
                                        Continue
                                    </button>
                                </footer>
                                <div className="or-container">
                                    <div>
                                        <img className="line" src={line} alt="" />
                                    </div>
                                    <div>
                                        <p className="or-text">OR</p>
                                    </div>
                                    <div>
                                        <img className="line" src={line} alt="" />
                                    </div>
                                </div>
                                <div className="button-container">
                                    {/* <button onClick={facebookInBundle}><img src={facebookButton} alt="Continue with Facebook" /></button> */}
                                    <button onClick={googleInBundle}><img src={googleButton} alt="Sign in with Google" /></button>
                                </div>
                            </div>
                        </div>
                        <div className={`step-two ${step === 2 ? '' : 'hide-step'}`}>
                            <div className="step-two-content">
                                <div className="description">
                                    <p>This code is invalid or this link has already been claimed. </p>
                                    <p>Please login to the account that you claimed this code with.</p>
                                    <p>If you did not claim this code, please reach out to us at <a href="mailto:contact@ven.games">contact@ven.games</a>.</p>
                                </div>
                                <footer>
                                    <button onClick={goToHome}>
                                        Go to homepage
                                    </button>
                                </footer>
                            </div>
                        </div>
                        <div className={`step-four ${step === 4 ? '' : 'hide-step'}`}>
                            <div className="step-four-content">
                                <div className="description">
                                    <img src={confirmationImg} alt="" />
                                    <p>{' '}</p>
                                    <p>Congratulations on getting the {type === 'gnb' ? 'Game Night' : 'Team'} Bundle. Enjoy!</p>
                                    <p className="text-one">Thank you for supporting VEN. Have fun and enjoy!</p>
                                </div>
                                <footer>
                                    <button onClick={goToAccount}>
                                        Go to Account page
                                    </button>
                                </footer>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FriendRegistration;
