import { auth, database } from '@ven/core/data/firebase';
import type { UserProperties } from './UserProperties';

import { DEVELOPMENT_MODE, IN_STAGING } from '@ven/core/debug/consts';
import { LogService } from '../log/LogService';
import { DEFAULT_CONFIG as BrokenMsgDefaultConfig } from '@ven/games/brokenmsg/gameplay/GameStateController';
import { DEFAULT_CONFIG as OutlawDefaultConfig } from '@ven/games/wordplay/gameplay/GameStateController';
import { CouponService } from '@ven/shared/core/services/CouponService';

export type UserLocalStorageData = {
  uid: string;
  isAnon?: string;
  userEmail?: string;
  provider?: string;
  photoUrl?: string;
  username?: string;
  password?: string;
  choosenGame?: string;
  vip?: string;
  holiday?: string;
  howToPlayBrokenMsgShown?: boolean,
  howToPlayOutlawShown?: boolean,
  previousUid?: string;
}

export const UserDataService =
{
  currentUser: null,
  getCurrentUser() {
    return this.currentUser;
  },
  get(key: keyof UserLocalStorageData) {
    //// During development, this makes life easier when testing from the point
    //// of view of several users (sessions) at once, by adding "?user=..." to the url.
    //// Especially useful for testing multiplayer games.
    if ((DEVELOPMENT_MODE || IN_STAGING) && key === 'uid') {
      const hackUidOverride = getUserIdFromUrl();
      if (hackUidOverride) {
        return hackUidOverride
      }
    }

    if(key == 'isAnon') {
      return auth.currentUser?.isAnonymous.toString();
    }

    if(key == 'uid') {
      return auth.currentUser?.uid;
    }

    return localStorage.getItem(key);
  },
  set(key: keyof UserLocalStorageData, value: string) {
    localStorage.setItem(key, value);
  },
  clear(key: keyof UserLocalStorageData) {
    localStorage.removeItem(key);
  }
}

export const getReferrerIdFromUrl = () => new URLSearchParams(window.location.search).get("referrer")

export const getUserIdFromUrl = () => new URLSearchParams(window.location.search).get("user")

export const UpdateUsernameAvatar = async (username?: string, avatar?: string) => {
  const uid = UserDataService.get('uid') || '';

  if ((!username && !avatar) || (username === '' && avatar === '') || (!uid || uid === '')) {
    return false;
  }

  try {
    // Update user in Firebase USERS collection
    const dbRef = database.ref('/users');
    const newUserRef = dbRef.child(uid);
    const data = await newUserRef.once('value');

    const newData = {
      ...data.val(),
    };

    if (username) newData.username = username;
    if (avatar) newData.avatar = avatar;

    await newUserRef.set(newData);

    return true;
  } catch (err) {
    await LogService.log(err, 'Error: Could not update user info.')
    console.log(err);
    return false;
  };
};

export const GetCurrentUserData = async () => {
  const uid = UserDataService.get('uid') || '';

  if (!uid || uid === '') {
    return false;
  }

  try {
    const dbRef = database.ref('/users');
    const newUserRef = dbRef.child(uid);
    const data = await newUserRef.once('value');

    return data.val();
  } catch (err) {
    await LogService.log(err, 'Error: Could not get current user info.')
    console.log(err);
    return false;
  }
}


export const GetCurrentUserRef = async () => {
  const uid = UserDataService.get('uid') || '';

  if (!uid || uid === '') {
    return false;
  }

  try {
    return database.ref('/users/'+uid);
  } catch (err) {
    await LogService.log(err, 'Error: Could not get current user ref.')
    console.log(err);
    return false;
  }
}


export const GetUserByUid = async (uid: string) => {
  if (!uid || uid === '') {
    return false;
  }

  try {
    const dbRef = database.ref('/users');
    const newUserRef = dbRef.child(uid);
    const data = await newUserRef.once('value');

    // In case we have some private user info, this is where the info should be trimmed.

    return data.val() as UserProperties;
  } catch (err) {
    await LogService.log(err, 'Error: Could not get user info.')
    console.log(err);
    return false;
  }
}

export const IsUserAccountType = async (userData: UserProperties, type: "freebie" | "bundle" | 'vip') => {

  const handlePack = async () => {
    if(userData.eventHost) {
      return true;
    }
    if(!userData.activeCoupon) {
      return false;
    }
    const uid = UserDataService.get('uid');
    const isValid = await CouponService.checkAndExpireActiveCode(uid, userData.activeCoupon);
    return !!isValid;
  }

  const isBundle =  await handlePack();

  return !!{
    "vip": () => UserDataService.get('vip'), //!!userData.packages?.VIP2022,
    "freebie": () => !!userData.providerId && userData.providerId?.toLowerCase() != "anonymous",
    "bundle": () => isBundle,
    "gamenight": handlePack,
    "teams": handlePack,
    "team": handlePack,
  }[type]?.()
}

export const GetMaximumUserAccountAllowedPlayer = async (hostUserData: UserProperties) => {
  if ( hostUserData ) {
    const isVip = await IsUserAccountType(hostUserData, 'vip');
    const hasBundle = await IsUserAccountType(hostUserData, "bundle");
    // const isLogged = await IsUserAccountType(hostUserData, "freebie");
    const isEventHost = hostUserData.eventHost;

    if (isVip || hasBundle || isEventHost)
      return 20;
    // if (isLogged)
      // return 4;
  }
  return 4;
}

export const GetMaximumGameAllowedPlayer = (gameId: string) => {
  if ( gameId == 'brokenmsg' ) {
    return BrokenMsgDefaultConfig.maxPlayersCount;
  }
  if ( gameId == 'outlaw' ) {
    return OutlawDefaultConfig.maxPlayersCount;
  }
  return 16;
}

// export const IsUserAccountTypeAsync = async (user: string|UserProperties, type:"freebie"|"gamenight"|"vip") => {
//   const userData = typeof user === "string" 
//     ? (await database.ref('/users/'+user).once('value')).val() 
//     : user;
//   return {
//     "vip" : () => !! userData.packages?.vip,
//     "freebie" : () => !! userData.providerId != undefined && userData.providerId?.toLowerCase() != "anonymous",
//     "gamenight" : () => !! userData.packages?.GameNightBundle?.started,
//   }[ type ]()
// }

// export const GetMaximumUserAccountAllowedPlayerAsync = async (user: string|UserProperties) => {
//   const hostData = typeof user === "string" 
//     ? (await database.ref('/users/'+user).once('value')).val() 
//     : user;
//   if ( hostData ) {
//     if ( await IsUserAccountType(hostData, "vip") || await IsUserAccountType(hostData, "gamenight") )
//       return 16;
//     if ( await IsUserAccountType(hostData, "freebie" ) )
//       return 6;
//   }
//   return 4;
// }
