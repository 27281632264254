import React, { useContext, useEffect, useState } from 'react';
import { JSONSchema7 } from "json-schema";
import Form from "@rjsf/core";
import { GetCurrentUserData } from '@ven/platform/main/services/user/UserDataService';
import { VipCodeService } from '@ven/shared/core/services/VipCodeService';
import { Card, Spinner } from 'react-bootstrap';
import styled from '@emotion/styled';
import { VipCodeContext } from '.';

const expirationDates = {
  "1 minute" : '1m',
  "30 minutes": '30m',
  "1 hour": '1h',
  "5 hours": '5h',
  "12 hours": '12h',
  "1 day": '1d',
  "2 days": '2d',
  "3 days": '3d',
  "5 days": '3d',
  "1 week": '1w',
  "2 week": '2w',
  "1 month": '4w',
  "1 year": '1y',
}
const CreateVip: React.FC = () => {
  const schemaVip: JSONSchema7 = {
    title: "Create Vip",
    type: "object",
    required: ['code', 'expiresIn'],
    properties: {
      code: { type: "string", title: "Code", maxLength:20, pattern: '^[a-zA-Z0-9_-]*$'},
      expiresIn: { type: "string", title: "Expires In",
      enum: Object.keys(expirationDates),
      default: Object.keys(expirationDates)[5] },
      oneTimeUse: { type: "boolean", title: "One Time Use", default: false },
      // description: { type: "string", title: "Description" },
    }
  };

  const [formData, setFormData] = useState(Object);
  const [userData, setUserData] = useState(Object);
  
  const [spinner, setSpinner] = useState(false);

  const {setVipData} = useContext(VipCodeContext);

  useEffect(() => {
    GetCurrentUserData().then(userData => {
      setUserData({
        ...userData
      })
    });
  }, []);

  const submit = async (schemeData) => {
    setSpinner(true);
    const { formData: submittedFormData } = schemeData;
    submittedFormData.creator = userData.uid;
    const result = await VipCodeService.create(submittedFormData);
    setVipData({...submittedFormData, id: result});
    setSpinner(false);
  }

  const log = (type) => console.log.bind(console, type);

  return (
    <Wrapper>
      <Card className={spinner ? "spinner" : ''}>
        <Card.Header>
          Create Vip
        </Card.Header>
        <Card.Body>
          <Form schema={schemaVip}
            formData={formData}
            liveValidate={true}
            // uiSchema={uiSchema}
            onChange={e => { console.log(e); return setFormData(e.formData) }}
            onSubmit={submit}
            onError={log("errors")}
            noHtml5Validate />
        </Card.Body>
        <div className="loading">
          <Spinner animation="border" variant="light"/>
        </div>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.spinner {
  button[type='submit']{
    background-color: gray;
    pointer-events: none;
  }

  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(128, 128, 128, 0.5);
  }
}
legend {
  display: none;
}
fieldset {
  border: 0;
}
`

export default CreateVip;
