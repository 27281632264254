import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import wiggle from '../../../../assets/images/homepage/wiggle.svg';
import computer from '../../../../assets/images/homepage/computer.svg';
import people from '../../../../assets/images/homepage/people.svg';

import './styles.scss'
import Testimonal from '@ven/shared/components/common/atoms/Testimonal/Testimonal';
import GameNightCard from './GameNightCard';

import testimonalSuneet from '../../../../assets/images/homepage/test_suneet.jpg';
import testimonalNicole from '../../../../assets/images/homepage/test_nicole.jpg';
import testimonalEric from '../../../../assets/images/homepage/test_eric.jpg';
import { UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { MainFooter } from '../../../organisms/MainFooter';

const testimonalProps = [
    {
        stars: 5,
        description: 'This was such a fun game for the whole family! We played it virtually and felt like we were all together again!',
        image: testimonalSuneet,
        name: 'Suneet'
    }, {
        stars: 5,
        description: 'After we put the kids down to bed, we just want some stay-at-home fun to play with our friends.  During the pandemic, VEN has been great because we can stay connected and safe.',
        image: testimonalNicole,
        name: 'Nicole'
    }, {
        stars: 5,
        description: 'We played Scribbler as a family and had a fantastic time - it was a great way to connect for some quality together time, even with people across the country.',
        image: testimonalEric,
        name: 'Eric'
    },
];


const testimonals: Array<any> = [];



for (const [i, test] of testimonalProps.entries()) {
    testimonals.push(
        <Testimonal key={i} props={test}></Testimonal>
    );
}


interface BottomProps {
    clickPlayGames: Function,
    openFreebieFlow: Function
};

const Bottom: React.FC<BottomProps> = ({
    clickPlayGames,
    openFreebieFlow,
}) => {

    const history = useHistory();
    const [testMobileContent, setTestMobileContent] = useState(<Testimonal props={testimonalProps[0]}></Testimonal>);
    const [viewTest, setViewTest] = useState(0);

    let isAnon = UserDataService.get('isAnon');

    const goToGameNightBundle = () => {
        history.push('/gamenight');
    };

    const nextTest = () => {
        let nxtTest = viewTest === 2 ? 0 : viewTest + 1;
        forceChangeTest(nxtTest);
    }

    const previousTest = () => {
        let nxtTest = viewTest === 0 ? 2 : viewTest - 1;
        forceChangeTest(nxtTest);
    }

    const forceChangeTest = (newTest: number) => {
        setViewTest(newTest);
        setTestMobileContent(<Testimonal props={testimonalProps[newTest]}></Testimonal>);
    }

    return (
        <div className="bottom">
            <div className="anytime">
                <img src={wiggle} alt="" />
                <h1>Play awesome games with friends. Anytime, anywhere.</h1>
                <p>Play your favorite party games online, now beautifully designed and fun for all ages.</p>
                <button className="play-games" onClick={() => clickPlayGames()}>
                    Get Started
                            </button>
            </div>
            <div className="phone-tablet">
                <img src={wiggle} alt="" />
                <p>At home or on the go.</p>
                <p>All our games are desktop and mobile friendly.
                                Just send a join link to your friends and they can join from any device!</p>
            </div>
            <div className="families">
                <img src={wiggle} alt="" />
                <p>VEN is for friends, families, and work teams.</p>
            </div>
            <div className="testimonals">
                {testimonals}
            </div>
            <div className="testimonals-mobile" id="mobileTestimonals">
                {testMobileContent}
                <div className="steppers">
                    <div className="back-arrow" onClick={previousTest}><p>{'<'}</p></div>
                    <div className={`step ${viewTest === 0 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(0)}>{' '}</div>
                    <div className={`step ${viewTest === 1 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(1)}>{' '}</div>
                    <div className={`step ${viewTest > 1 ? 'dot-selected' : ''}`} onClick={() => forceChangeTest(2)}>{' '}</div>
                    <div className="forward-arrow" onClick={nextTest}><p>{'>'}</p></div>
                </div>
            </div>
            <div className="game-night-section">
                <p className="text">VEN will always be FREE for up to 4 players. Want to play with more?
                            Grab the Game Night bundle and host the perfect games night!</p>
                <GameNightCard
                    bannerText="SPECIAL OFFERS"
                    title="2 for 1 GAME NIGHT BUNDLE"
                    subtitle="Enjoy 48 hours of unlimited gameplay and players, with all premium VEN content included."
                    text1="Play Games with an unlimited number of friends!"
                    text2="Unlock all premium extensions and word packs."
                    text3="2 for 1 — Give a Games Night as a gift for FREE!"
                    text4="Have a say in what games we create!"
                    text5=" "
                    // text5="Get early, exclusive access to new games. Play them weeks before anyone else."
                    buttonText="Buy the Bundle"
                    buttonAction={goToGameNightBundle}
                />
                <img src={people} className="people" alt="" />
            </div>
            <div className="freebie">
                <img src={wiggle} alt="" />
                <p>Free Forever</p>
                <p>Just create an account to play with up to 4 people</p>
                <button className="signup" onClick={() => !isAnon || isAnon === 'true' ? openFreebieFlow(1) : clickPlayGames()}> {!isAnon || isAnon === 'true' ? 'Sign up and redeem offer!' : 'Play Games'}</button>
            </div>
            <div className="subscribe">
                <img src={computer} alt="" />
                <div className="text">
                    <p>Want to play more games before anyone else?</p>
                    <p>Get on our list to receive updates about new games.<br />
                                We’ll never spam you. Promise!</p>
                    <div className="form-container">
                        <form action="https://games.us2.list-manage.com/subscribe/post?u=9d53575796b4adedfa2ab72b0&amp;id=b9346c517f" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="form" target="_blank">
                            <input type="email" name="EMAIL" id="mce-EMAIL" autoComplete="off" placeholder="Your Email" className="email-field" required />
                            <input className="sb-button" id="mc-embedded" type="submit" value="SUBSCRIBE"></input>
                        </form>
                    </div>
                </div>
            </div>
            <MainFooter />
        </div>

    );
};

export default Bottom;
