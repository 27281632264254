import React from 'react';

import type { GameStateController } from '@ven/core/gameplay/GameStateController';
import type { GameSessionData } from '@ven/core/gameplay/GameSessionData';

import { LoadingView } from '@ven/shared/components/common/organizms/Loading';
import { useSynchronizePlayerData } from '@ven/shared/components/hooks/useSynchronizePlayerData';

let instances: Array<any> = [];
export const useGameStateAndData = <TData extends GameSessionData, TGame extends GameStateController<TData>>(
  GameStateControllerConstructor: new (gameId: string, roomId: string, userId: string) => TGame,
  gameId: string, roomId: string, userId: string, roomCode: string
) => {
  const game = React.useMemo<GameStateController<TData>>(
    () => {
      if (instances.length > 0) {
        console.warn("More than one game instance: it should not happen. trying to delete the old one")
        for (let index = 0; index < instances.length; index++) {
          instances[index].destroy();
          instances[index] = null;
        }
      }
      const instance = new GameStateControllerConstructor(gameId, roomId, userId);
      instances.push(instance);
      // console.log(instances)

      return instance;
    },
    [gameId, roomId, userId]
  ) as TGame

  const [data, setData] = React.useState<TGame['data']>()

  React.useEffect(() => {
    if (!!roomId) {
      const onDataChange = (a: any) => setData(Object.freeze({
        players: {},
        state: { round: {} as any, started: false, turn: {} as any },
        ...a.val()
      }))
      const ref = game.ref.room()
      ref.on("value", onDataChange)
      return () => ref.off("value", onDataChange)
    }
    return
  }, [game])

  useSynchronizePlayerData(game as GameStateController<any>)

  game.data = data!;
  game.code = roomCode || roomId;

  return ({ game, data })
}
