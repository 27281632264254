export const getRandomFrom = <T>( array:T[] ) => array[ ~~( Math.random() * array.length ) ]

export const range = ( max:number ) => new Array( max ).fill( null ).map( (_,i) => i )

export const rangeFromTo = ( from:number, to:number ) => new Array( to - from ).fill( null ).map( (_,i) => from + i )

export const shuffle = <T>( a:T[] ) => {
  let j:number, x:T, i:number
  for ( i = a.length - 1; i > 0; i-- ) {
    j = Math.floor( Math.random() * (i + 1) )
    x = a[ i ]
    a[ i ] = a[ j ]
    a[ j ] = x
  }
  return a;
}

export const rotate = <T>(arr:T[], count:number) => {
  count -= arr.length * Math.floor(count / arr.length);
  arr.push.apply(arr, arr.splice(0, count));
  return arr;
};

export const removeDuplicates = <T>(array:T[]):T[] => [...new Set(array)]
