import React from 'react';

import styled from "@emotion/styled";

export const ShareableLink:React.FC<any> = ({ gameId, roomCode, refererUserId }) => 
{
  const urlBase = window.location.origin.toString()
  
  ////TODO Bring back the referer
  // const link = `${ urlBase }/${ gameId }/${ roomCode }?referer=${ refererUserId }`
  // const link = `${ urlBase }/${ gameId }/${ roomCode }`
  const link = `${ urlBase }/play/${ roomCode }`

  return (
    <ShareableLinkField tabIndex={0} >{ link } </ShareableLinkField>
  )
}

const ShareableLinkField = styled.div`
  background: #FFFFFF;
  border: 2px solid #303744;
  border-radius: 7px;
  padding: 0px 24px;
  
  display: inline-block;

  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 66px;
  height: 66px;

  color: #A19AC6;

  user-select: all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 385px;
  width: 100%;

  &:focus, &:active {
    outline: none;
    border: 2px solid #2FB586;
  }
`
