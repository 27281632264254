import React from 'react';

import type { GameStateController, WithTeams } from '@ven/core/gameplay/GameStateController';

import styled from "@emotion/styled";

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

type Props = {
  game: GameStateController<any> & WithTeams
  // defaultTeam: number
  usedTeamState: [ number, (team:number)=>any ]
}
export const SelectTeamDropdown:React.FC<Props> = ({ game, usedTeamState: useTeamState }) =>
{
  const myUserId = game.myUserId!
  const myPlayerState = game.data.players[ myUserId ]

  // const [ myTeam, setMyTeam ] = React.useState( myPlayerState?.team || defaultTeam )
  const [ myTeam, setMyTeam ] = useTeamState;

  const setMyTeamAndUpdateGameState = team => {
    setMyTeam( team )
    !! game.haveJoined() && game.update.player( myUserId, { team } )
  }

  const teams = [ 1, 2 ]

  const options = [
    { value: '1', label: game.getTeamName(1) },
    { value: '2', label: game.getTeamName(2) },
  ]

  return (
    <TeamDropdown 
      className="team-selector"
      options={ options } 
      onChange={ v => setMyTeamAndUpdateGameState( +v.value ) } 
      value={ '' + myTeam } 
    />
  )
}

const TeamDropdown = styled(Dropdown)`
padding-top: 10px;
display: inline-block;
width: 100%;

.Dropdown-control {
  transition: none;
  height: 67px;
  border-radius: 7px;
  padding: 0;
  
  max-width: 385px;
  width: 100%;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 67px;
  text-align: center;

  overflow: visible;

  color: #303744;
}

.Dropdown-menu {
  overflow: hidden;
  border-radius: 7px;
}

.Dropdown-option {
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 50px;
}

.Dropdown-arrow {
  right: 20px;
  top: 30px;
}
  `
