/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { COLOR_TOOL_ACTIVE } from '@ven/shared/components/styling/colors';

import React, { useState } from 'react';
import { colorIntegerToHashTag } from '@ven/shared/core/utils/colors';
import { PORTRAIT_BREAK_PX } from "@ven/shared/components/styling/breakpoints";

import styled from "@emotion/styled";
import { DrawingSettingsContext, DrawingTool } from "./DrawingSettings";
import { Button, Modal } from "react-bootstrap";
import { ToolBar, ToolBarButton } from "@ven/shared/components/game/drawing/ToolBar";
import { BrushSizeIcon, ColorPalleteIcon } from "@ven/shared/components/assets/icons";

interface Props 
{
  colors: number[],
  sizes: number[],
}
export const DrawingPalette:React.FC<Props> = ({ colors, sizes }) => 
{
  const { settings, updateSettings } = React.useContext( DrawingSettingsContext )

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  //// ORGANISM

  return (
    <div>
    <Row className="palette">
      {/* <div className="slider">
        { colors.map( color => 
          <BrushColorButton 
            key={ color } color={ color } selected={ settings.brushColor === color && settings.tool === DrawingTool.Draw }
            onClick={ () => updateSettings({ brushColor : color, tool : DrawingTool.Draw }) }
          /> ) }
      </div> */}
       <ToolBarButton 
          Icon={ColorPalleteIcon}
          onClick={() => setShow(true)}
        />
    </Row>
    <Modal show={show} onHide={handleClose}
    size="sm"
    aria-labelledby="contained-modal-title-vcenter"
    centered>
      {/* <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
      { colors.map( color => 
          <BrushColorButton 
            key={ color } color={ color } selected={ settings.brushColor === color && settings.tool === DrawingTool.Draw }
            onClick={ () => { updateSettings({ brushColor : color, tool : DrawingTool.Draw }); setShow(false); } }
          /> )
      }
      
      <ToolBar buttons={
        sizes.map( (size,i) => ({
            Icon : BrushSizeIcon,
            iconProps: { thickness: [1,3,5,8][ i ] },
            onClick: () => { updateSettings({ brushSize : size }); setShow(false); },
            selected: size === settings.brushSize,
        }) )
      }/>
          
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer> */}
    </Modal>
    </div>
  )
}

//// Molecule 

const Row = styled.div`
  position: absolute;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  z-index: 9;
  padding: 5px;
  /* box-sizing: content-box;
  min-height: 64px;
  margin-bottom: -64px;
  max-width: 100%; */
/* 
  overflow-x: auto;
  overflow-y: hidden; */
/* 
  .slider {
    min-width: 650px;
  } */
`

//// Atoms

const brushButtonStyle = css`
  flex: 1 1 auto;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  position: relative;
  background: none;

  width: 63px;
  height: 63px;

  /* The Icon */
  & .icon {
    transition: transform 250ms;
  }
  &:active .icon {
    transition: transform 100ms;
    transform: scale(.85);
  }

  /* The Circle */
  &:after {
    content: '';
    display: block;
    width: 51px;
    height: 51px;
    
    transform: translate(50% 50%);
    transform-origin: center middle;

    position: absolute;
    transition: border 400ms;
    border: 12px solid #0000;
    border-radius: 50%;
    
    z-index: 1;
  }
  &[current]:after {
    border: 2px solid ${ COLOR_TOOL_ACTIVE };
  }
`
const brushButtonIconStyle = css`
  height: 100%;
  width: 100%;
  margin: 12px;
`
const BrushColorButton = ({color, selected, onClick}) => 
{
  return (
    <button 
    {...{ current : selected ? '' : null }}
    onPointerDown={ e => e.preventDefault() } 
    onClick={ onClick }
    css={ brushButtonStyle }>
      <svg 
      className="icon"
      viewBox="0 0 52 52" 
      preserveAspectRatio="xMidYMid meet" 
      css={ brushButtonIconStyle }>
        <circle cx="26" cy="26" r="25" stroke="#A19AC6" strokeWidth="1" fill={ colorIntegerToHashTag( color ) } />
      </svg>
    </button>
  )
}
