import React from 'react';

import type { LogEntry, WithLogging } from '@ven/shared/core/gameplay/plugins/Logging';

import { GameFunctionsService } from '@ven/core/services/GameFunctionsService';
import { database } from '@ven/shared/core/data/firebase';
import styled from '@emotion/styled';

export const GameRoomLogsPage:React.FC<any> = ({ code }) => {
  const [data,setData] = React.useState<WithLogging['debug']>()

  React.useEffect(() => {
    GameFunctionsService.getRoomData( code )
      .then( async data => {
        if ( data ) {
          database
            .ref(`game-sessions/${ data.gameId }/${ data.roomId }/debug`)
            .on('value', o => setData( o.val() ) )
        }
      });
  }, [])

  if ( ! data ) {
    return null;
  }

  const onClick = ( o:LogEntry ) => {
    alert( o.msg + ( ! o.stack ? '' : ( '\n\n' + o.stack ) ) )
  }

  return <Wrapper>
    {
      Object.entries(data).map(
        ([uid,{logs}]) => 
        <div key={ uid }>
          <hr style={{ height: `1em` }} />
          <table>
            <tbody>
            {
              Object.values(logs).map(
                o => (
                  <tr key={''+o.time} className={o.severity} onClick={ () => onClick(o) }>
                    <th>♦ {o.author} ({ uid })</th>
                    <th>{ new Date( +o.time ).toLocaleTimeString() }</th>
                    <th><b>{ o.msg }</b></th>
                  </tr>
                )
              )
            }
            </tbody>
          </table>
          <p></p>
          <hr style={{ height: `1em` }} />
        </div>
      )
    }
  </Wrapper>
}

const Wrapper = styled.div`
  width: 100%;

  table {
    width:100%;
  } 

  tr {
    color: skyblue;
    text-align: left;
    font-family: "Lucida Console", monospace;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.5;

    &.error {
      color: maroon;
      /* color: white; */
    }
    
    &:hover {
      color: white;
    }
  }

`
