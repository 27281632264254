import React, { useState } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import ListGroup from 'react-bootstrap/esm/ListGroup';

type Props = {
  game: any,
  // selectedPlayer:any,
  show:boolean,
  setShow:Function,
  kick?:boolean
  changeTeams?:boolean
}

export const EventHostPowers:React.FC<Props> = ({ game, show, setShow }) => {
  
  const [modelLoading, setModalLoading] = useState(false);

  const handleClose = () => setShow(false);

  const skipTurn = async () => {
    setModalLoading(true);
    await game.update.turn({
      time : 0,
      timeout: 0,
    }),
    
    setModalLoading(false);
    setShow(false);
    window.scrollTo({ top : 0 });
    // await game.events.dispatch( 'hostChanged', data.uid, previousHost );
  }

  const addTime = async (plusTime) => {
    setModalLoading(true);
    const timeout = game?.data?.state?.turn?.timeout
    timeout 
      ? await game.update.turn({ timeout : ( game.data?.state?.turn?.timeout || 0 ) + plusTime })
      : await game.update.turn({ time : ( game.data?.state?.turn?.time || 0 ) + plusTime })
    setModalLoading(false);
    setShow(false);
    window.scrollTo({ top : 0 });
    // await game.events.dispatch( 'hostChanged', data.uid, previousHost );
  }

  const resetGame = async () => {
    setModalLoading(true);
    await game.ref.room().remove()
    setModalLoading(false);
    setShow(false);
    window.scrollTo({ top : 0 });
    // await game.events.dispatch( 'hostChanged', data.uid, previousHost );
  }

  return <Modal show={show} onHide={handleClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>Event Host Powers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modelLoading ? "loading" : 
        <ListGroup variant="flush">
          <ListGroup.Item action onClick={() => { skipTurn() }}>Skip Current Turn</ListGroup.Item>
          <ListGroup.Item action onClick={() => { addTime(30) }}>Add 30 seconds</ListGroup.Item>
          <ListGroup.Item action onClick={() => { addTime(60) }}>Add 60 seconds</ListGroup.Item>
          <ListGroup.Item action onClick={() => { resetGame() }}>Reset Game</ListGroup.Item>
          {/* { !kick ? null : <ListGroup.Item action onClick={() => { kickPlayer(selectedPlayer); }}>Kick <b>{selectedPlayer.username}</b></ListGroup.Item>} */}
          {/* { !changeTeams ? null : <ListGroup.Item action onClick={() => { toggleTeam(selectedPlayer); }}>Toggle <b>{selectedPlayer.username}</b> Team</ListGroup.Item>} */}
        </ListGroup>
        }
        </Modal.Body>
      </Modal>

}
