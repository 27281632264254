import { database } from '@ven/core/data/firebase';
import { GetCurrentUserData, UserDataService } from '@ven/platform/main/services/user/UserDataService';
import { CouponService } from '@ven/shared/core/services/CouponService';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { MainHeader } from '../../organisms/MainHeader';
import ActivationModal from './ActivationModal';
import CouponItem from './CouponItem';
import ProfileTab from './ProfileTab';
import PurchasesPage from './PurchasesPage';

import './styles.scss';

const Account: React.FC = () => {
    const history = useHistory();
    let types: any[] = [];
    const [username, setUsername] = useState('');
    const [activeCount, setActiveCount] = useState(0);
    const [expiredCount, setExpiredCount] = useState(0);

    const [view, setView] = useState(1);
    const [elements, setElements] = useState(new Array);
    const [activeCoupons, setActiveCoupons] = useState(new Array);
    const [expiredCoupons, setExpiredCoupons] = useState(new Array);
    const [parts, setParts] = useState(0);

    // useEffect(() => {
    //     console.log(auth.currentUser?.uid)
    // }, [auth.currentUser?.uid])
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (parts === 1) {
                GetCurrentUserData().then((userData) => {
                    if (userData) {
                        setParts(0);
                    }
                });
            }
            if (parts === 0) {
                GetCurrentUserData().then((userData) => {
                    if (!userData || UserDataService.get('isAnon') == 'true') {
                        setParts(1);
                        return;
                    }
                    setUsername(userData.username);
                    setParts(2);
                });
            }
        }, 2000);
        return () => clearInterval(interval);
    });

    const openLoginModal = () => {
        const loginButton = document.getElementsByClassName('login')[0];
        (loginButton as any).click();
    }

    return (
        <div className="main-account">
            <Helmet>
                <title> Account | VEN Games </title>
            </Helmet>
            <div className="main-header-account">
                <MainHeader darkTheme={true}/>
            </div>
            { parts === 0 &&
                <div className="spinner-wrapper">
                    <MoonLoader
                        size={50}
                        color={"#303744"}
                        loading={true}
                    />
                </div>
            }
            { parts === 1 &&
                <div className="not-logged">
                    <p>Login to see your Account.</p>
                    <button onClick={openLoginModal}>Log In</button>
                </div>
            }
            { parts === 2 &&
                <div className="account">
                    <p className="welcome">{username && username}'s Account</p>
                    <Tabs defaultActiveKey="purchases" id="uncontrolled-tab-example" className="mb-12">
                        {/* <Tab eventKey="profile" title="Profile">
                            <ProfileTab/>
                        </Tab> */}
                        <Tab eventKey="purchases" title="Purchases">
                          <PurchasesPage/>
                        </Tab>
                    </Tabs>
                </div>
            }

            <footer className="footer-account">
                <div>Virtual Engagement - VEN Games</div>
                <div><a href="mailto:contact@ven.games">Contact@VEN.games</a></div>
                <div><Link to="/termsandconditions">Terms {'&'} Conditions</Link></div>
                <div>Leave Feedback</div>
            </footer>
        </div>
    )
}

export default Account;
