import React from 'react';

import type { CanvasAction, CanvasAction_BrushStart, CanvasActoin_BrushMove } from '@ven/games/scribbler/drawing/CanvasAction';
import { colorIntegerToHashTag } from '@ven/shared/core/utils/colors';

import styled from '@emotion/styled';

type Coordinates = {
  x: number;
  y: number;
};

interface Props 
{
  config : {
    width: number,
    height: number,
  }
  data : CanvasAction[],
  fillSpeed? : number,
  backgroundColor?: string,
}
export const ReadonlyCanvas:React.FC<Props> = ({ config, data, fillSpeed = 2, children, backgroundColor }) => 
{
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [context, setContext] = React.useState<CanvasRenderingContext2D | null>(null);

  const { width, height } = config

  const prevPoint = {x: 0, y: 0};
  const points:Coordinates[] = [];

  const handleAction = ( action:CanvasAction ) =>
  {
    if ( context )
    {
      const [ actionType ] = action
      const onPaint = () => {
        points.push({ ...prevPoint });
        
        if (points.length < 3) {
          const b = points[0];
          context.beginPath();
          context.arc(b.x, b.y, context.lineWidth / 2, 0, Math.PI * 2, !0);
          context.fill();
          context.closePath();
          return;
        }
        
        // clearCanvasContext( context )
        context.beginPath();
        context.moveTo(points[0].x, points[0].y);
        
        for (var i = 1; i < points.length - 2; i++) {
          const c = (points[i].x + points[i + 1].x) / 2;
          const d = (points[i].y + points[i + 1].y) / 2;
          context!.quadraticCurveTo(points[i].x, points[i].y, c, d);
        }
        
        context!.quadraticCurveTo(
          points[i].x,
          points[i].y,
          points[i + 1].x,
          points[i + 1].y
        );

        context.stroke();
      };

      if ( actionType === 0 )
      {
        const [ , x, y, color, size ] = action as CanvasAction_BrushStart
        context.strokeStyle = colorIntegerToHashTag( color < 0 ? 0xFFFFFF : color )
        context.fillStyle = colorIntegerToHashTag( color < 0 ? 0xFFFFFF : color )
        context.lineWidth = size;
        context.lineCap = "round";
      
        prevPoint.x = x
        prevPoint.y = y
        onPaint()
        
        context.globalCompositeOperation = color < 0 ? "destination-out" : "source-over"
      }
      if ( actionType === 1 )
      {
        const [ , x, y ] = action as CanvasActoin_BrushMove

        prevPoint.x = x
        prevPoint.y = y
        onPaint()
      }
      if ( actionType === 2 )
      {
        // context.drawImage( canvasRef.current!, 0, 0, width, height );
        // context.drawImage( canvasRef.current!, 0, 0 );
        // clearCanvasContext( contextTemp )
        points.length = 0;
      }
    }
  }
  
  const drawData = () => {
    if ( canvasRef.current?.['__done_flag'] === true ) {
      console.log( `Warning: [ReadonlyCanvas] Canvas drawData() was called multiple times!!` );
      return;
    } 
    canvasRef.current!['__done_flag'] = true;

    if ( context ) {
      context.clearRect( 0, 0, width, height );
      if ( backgroundColor ) {
        context.fillStyle = backgroundColor;
        context.fillRect(0, 0, width, height);
      }
    }

    const speed = fillSpeed;
    const actions = [...data].reverse()
    const drawNextAction = () => {
      for ( let i = 0 ; i < speed ; i++ ) {
        if ( ! actions.length ) return;
        handleAction( actions.pop()! )
      }
      requestAnimationFrame( drawNextAction )
    }
    drawNextAction();
  }
  
  context && drawData();

  React.useEffect(() => 
  {
    if ( ! canvasRef.current ) {
      return
    }
    if ( ! context ) {
      const ctx = canvasRef.current.getContext('2d');
      ctx && setContext(ctx);
    }
  }, [ !! canvasRef.current ]);

  return (
    <Wrapper
    className="drawing-canvas readonly"
    width={ width }
    style={{ 
      width: width + "px",
      height: height + "px",
    }}>
      <RealCanvas
        id="canvas"
        ref={canvasRef}
        width={width}
        height={height}
      />
      { children }
    </Wrapper>
  );
}

const Wrapper = styled.div<{ width:number }>`
  position: relative;
  overflow: hidden;
  @media (min-width: ${ props => props.width }px) {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`

const RealCanvas = styled.canvas<{ width:number }>`
  width: 100%;
  height: 100%;
`
