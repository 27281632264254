import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MainHeader } from '../../../organisms/MainHeader';
import _ from "lodash";
import { VipCodeService } from '@ven/shared/core/services/VipCodeService';

import 'react-datasheet/lib/react-datasheet.css';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Col, Nav, NavItem, NavLink, Row, Spinner, Tab } from 'react-bootstrap';
import CreateVip from './CreateVip';
import VipInfo from './VipInfo';
import { BeatLoader, MoonLoader } from 'react-spinners';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import { RequestRolePanel } from '@ven/shared/components/common/templates/RequestRolePanel';

type vipCodeContext = {
  vipData: any
  setVipData: Function,
}
export const VipCodeContext = createContext<vipCodeContext>({
  vipData: {},
  setVipData: () => { }
});

const VipCodePage: React.FC = () => {

  const { vipId } = (useParams() as any);

  const [vipsRequested, setVipsRequested] = useState(false);
  const [vips, setVips] = useState(new Array);

  const [vipData, setVipData] = useState(Object);

  const setTab = async (key) => {
    const isCreateVipTab = key == 'createVip';
    setVipData(isCreateVipTab ? null : vips.find(e => e.code == key))
  }

  useEffect(() => {
    const page = vipData && vipData.code ? vipData.code : 'createVip'
    window.history.pushState({}, '', '/vipPanel/' + page);
  }, [vipData])

  const userData = React.useContext( UserDataContext );

  React.useEffect(() => {
    getVipCode();
  }, []);

  async function getVipCode() {
    await VipCodeService.getAll(vips => {
      setVips(vips);
      setVipsRequested(true)

      if(vipData && vipData.code) {
        setVipData(vips.find(e => e.code == vipData.code));
        return;
      }
     
      if(vipId && vipId != 'createVip')
        setVipData(vips.find(e => e.code == vipId));

      console.log(vips);
    });
  }

  const role = "eventHost";
  return (
    // <HostVipContext.Provider value={{ session, setSession, vipData, setVipData }}>
      <Wrapper>
        <Helmet>
          <title> Vip | VEN Games </title>
        </Helmet>
        <div className="main-account">
          <div className="main-header-account">
            <MainHeader darkTheme={true} />
          </div>
          <div className="main-account">
            {
               !userData[role] ? <RequestRolePanel role={role} /> :
               <Tab.Container 
                  onSelect={(k) => setTab(k)}
                  activeKey={vipData && vipData.code ? vipData.code : "createVip"} >
                  <Row>
                    <Col sm={3} className="nav-menu">
                      <Nav variant="pills" className="flex-column">
                        <NavItem>
                          <NavLink eventKey="createVip">Create Vip Code</NavLink>
                        </NavItem>
                        {
                          !vipsRequested ?
                            <NavItem>
                              <BeatLoader />
                            </NavItem>
                            : vips.map(e =>
                              <NavItem title={e.code} key={e.code}>
                                <NavLink eventKey={e.code}>{e.code}</NavLink>
                              </NavItem>)
                        }
                      </Nav>
                    </Col>
                    <Col sm={9} style={{paddingLeft: 0}}>
                      <Tab.Content>
                        <Tab.Pane eventKey="createVip">
                          <CreateVip />
                        </Tab.Pane>
                        {
                          vips != null && vips.map(e =>
                            <Tab.Pane eventKey={e.code} key={e.code}>
                              {vipData && vipData.code == e.code &&
                                <VipInfo data={e}></VipInfo>
                              }
                            </Tab.Pane>)
                        }
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
            }

            {/* <footer>
        <div>Virtual Engagement - VEN Games</div>
        <div><a href="mailto:contact@ven.games">Contact@VEN.games</a></div>
        <div><Link to="/termsandconditions">Terms {'&'} Conditions</Link></div>
        <div>Leave Feedback</div>
      </footer> */}
          </div>
        </div>
      </Wrapper>
    // </HostVipContext.Provider>
  )
}

const Wrapper = styled.div`
  /* max-width: 1200px; */
  min-height: 100vh;
  min-width: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
  .form-control {
    font-size: 1.8rem;
  }
  .btn-info {
    color: #fff;
    background-color: #001892;
    border-color: #001892;
  }
 
  .nav-item a {
    color: #001892;
    &.disabled {
      color: gray;
    }
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #001892;

  }

  .card-header .btn-link {
    font-size: 15px;
    font-weight: bold;
    text-decoration-line: underline;
  }

  .nav-menu {
    background: rgba(0,0,0,0.1);
    padding-right: 0;
  }

  .nav-item {
    outline: 1px solid rgba(0,0,0,0.1);
    background: #fff;
  }

  .main-account{
    background: #eeeeee;
    height: 100vh;
    /* margin: auto; */
    &>.row{
      height: 100vh;
    }
  }

 form {
   button[type=submit]{
     width: 100%;
     font-size: 1.5rem;
   }
 }
`;

export default VipCodePage;
