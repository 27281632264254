import React, { PropsWithChildren, useEffect, useState } from 'react';

import styled from '@emotion/styled';

//@ts-ignore
import wiggle from '../assets/wiggle.svg';
import { GreenButton } from './atoms/GreenButton';
import { Spacer } from './atoms/Separator';

export const SwitchViewMode:React.FC<PropsWithChildren<any>> = ({game, isSpectator}) => 
{
  const isInGame = !!game

  const header = isSpectator 
    ? 'Wait for the game to start' 
    : 'This game started already'

    const action = isSpectator 
    ? 'play' 
    : 'watch'

    const reverseAction = !isSpectator 
    ? 'play' 
    : 'watch'


  return (
    <Wrapper>
      <div className="pad dropping-the-shadow">
        <img src={wiggle}/>
        <Spacer size={20}/>
        <h1> {header} </h1>
        <Spacer size={20}/>
        <p> You can { action } if you want </p>
        <Spacer size={20}/>
        {
          !isInGame ? null : <GreenButton onClick={() => {
            const gameUrl = window.location.href.replace(`/${reverseAction}/`, `/${action}/`);
            window.location.replace(gameUrl);
          }}>
            Yes! I would like to {action}!
        </GreenButton>
        }
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .pad {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    background: white;
    border-radius: 12px;
    width: 90vw;
    max-width: 720px;
    min-height: 30%;
    padding: 80px 40px;
    
    font-family: Bitter;
    color: #303744;

    h1 {
      font-weight: bold;
      font-size: 40px;
      line-height: 1.4;
      text-align: center;
    }
    
    p {
      text-align: center;
      font-weight: normal;
      font-size: 25px;
      line-height: 40px;
    }
  }
`
