import React, { useState } from 'react';
import * as ReactRouter from 'react-router';

import type { GameStateController } from '@ven/core/gameplay/GameStateController';
import { GreenButton } from '../atoms/GreenButton';
import { PlayerAvatar } from '../../game/molecules/PlayerAvatar';
import { Spacer } from '../atoms/Separator';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import styled from "@emotion/styled";
import { IsUserAccountType } from '@ven/platform/main/services/user/UserDataService';
import { UserDataContext } from '@ven/platform/main/services/user/UserDataContext';
import DirtyModalService from '@ven/core/utils/dirtyModalService';
import { analyticsService } from '@ven/platform/main/services/AnalyticsService';

type Props = {
  height?:number,
  headerColor:string,
  headerTextColor?:string,
  overflowHidden?:boolean,
}

// interface IGame<T=any> {
//   data:any;
//   roomId:string;
//   gameId:string;
//   canStartGame():boolean;
//   start():void;
// }

export const CustomGamePad = styled.div<Props>`
width: 475px;
max-width: 90vw;

color: #303744;
background: #FfFfFf;
box-shadow: -6px 7px 28px rgba(0, 0, 0, 0.0819766);
border-radius: 12px;
position: relative;
box-sizing: border-box;

text-align: center;

user-select: none;

${ props => props.overflowHidden !== false ? 'overflow: hidden;' : ''}

.title {
  position: relative;
  height: 80px;
  background: ${ props => props.headerColor };
  border-radius: 12px 12px 0 0;
  padding: 0 24px;

  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 80px;
  text-align: right;

  color: ${ props => props.headerTextColor || `white`};

  .game-title {
    position: absolute;
  }

  .how-to {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);

    font-family: Bitter;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.inner-content {
  position: relative;
  box-sizing: border-box;
  padding: 30px 48px 48px 48px;
}

.label {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  text-align: left;
  padding-left: 4px;
  color: inherit;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name-input {
  border: 2px solid #303744;
  border-radius: 7px;
}

.avatar-catalog {
  /* border: 2px solid #303744; */
  /* border-radius: 7px; */
  button {
    stroke: #303744;
  }

  .avatar-pad.selected {
    /* background: #DDD; */
    img {
      border-color: ${ props => props.headerColor };
    }
  }
}

.team-selector {
  width: 100%;
  padding-top: 10px;
  display: inline-block;

  .Dropdown-control {
    width: 100%;
    max-width: 80vw;
    transition: none;

    height: 67px;
    border: 2px solid #303744;
    border-radius: 7px;
    padding: 0;

    font-family: Bitter;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 67px;
    text-align: center;

    color: #303744;

    overflow: visible;
  }

  .Dropdown-menu {
    overflow: hidden;
    border-radius: 7px;
  }

  .Dropdown-option {
    font-family: Bitter;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 50px;
  }

  .Dropdown-arrow {
    right: 20px;
    top: 30px;
  }
}

.top-link {
  position: absolute;
  left: 50%;
  bottom: 26px;
  transform: translate(-50%, 0%);
  width: 100%;

  font-family: Bitter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 100px;
  text-align: center;

  color: inherit;

  a {
    color: #2FB586;
  }
}

.title-teams {
  display: flex;
  flex-direction: row;

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1;

    .bigHeading {
      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
    }

    .smallHeading {
      font-family: Bitter;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
    }
  }
}

.playersList {
  display: flex;
  flex-direction: row;
  position: relative;
  .separator {
    height: 100%; 
    width: 2px;
    background: #303744;
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
  }
  .playersColumn {
    display: flex;
    flex-direction: row;

    /* display: grid;
    grid-template-columns: 1fr 1fr ; */

    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex: 1 1;
    margin: 0 8%;
    padding-bottom: 30px;
    .playerListItem {
      padding-top: 30px;
      flex-basis: 50%;
      .name {
        padding-top: 8px;
        font-family: Oswald;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #303744;
      }
    }
  }
  .playersColumn.double {
    flex-direction: column;
    justify-content: flex-start;
    .playerListItem {
      flex-basis: unset;
    }
  }
}

.hint {
  text-align: center;
  font-style: italic;
  padding: 0 10px;
}

.upsell {
  width: 100%;
  height: 111px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #F47C63;
  color: white;
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;

  padding: 0 20%;

  cursor: pointer;
}
`

export const CustomGamePlayButton:React.FC<any> = ({ game, config, otherError }) => {
  const [busy,setBusy] = React.useState( false )

  const Button = styled(GreenButton)`
  height: 75px;
  width: 475px;
  max-width: 90vw;
  margin-bottom: 100px;
  `
  const name = game.data?.players[game.myUserId]?.username?.trim() || (game.data?.eventHost ? "EventHost" : '');

  const isFullRoom = (game.getPlayersCount() - game.getMaxPlayers()) > 0;
  const validateGame = () => {
    if(otherError) return otherError;
    if(!name) return `Please enter a username above`;
    if(isFullRoom) return `Oh no! Full room!`;

    return false;
  }
  const hasError = validateGame();
  return (
    <>
    <Button 
      className="play-button"
      disabled={ busy || hasError || !game.canStartGame() } 
      onClick={ () => {
        if ( name ) {
          setBusy( true )
          analyticsService.GameStarted(game.gameId, game.roomId, game.getPlayersCount())
          game.start(config)
        } 
      } } > 
      { !hasError ? 'Start the Game!' : hasError }
    </Button>
     {isFullRoom ? <UpdateAccount>Upgrade your account so you and your friends can play!
       <p>(or promote to boss somebody with a upgraded account)</p>
       <p>(You also can remove {game.getPlayersCount() - game.getMaxPlayers()} player(s) to play)</p>
       </UpdateAccount> : null }
    </>
  )
}

export const UpdateAccount = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 255px;
  text-align: center;
  margin: -80px auto 40px;
`

export const SelectTeamJoined:React.FC<any> = ({ game, defaultTeam }) =>
{
  const [ myTeam, setMyTeam ] = React.useState( defaultTeam )

  const setMyTeamAndUpdateGameState = team => {
    setMyTeam( team )
    game.update.player( game.myUserId!, { team } )
  }

  // const options = game.getTeams().map( v => ({ value : v,  }))
  const options = [
    { value: '1', label: game.getTeamName(1) },
    { value: '2', label: game.getTeamName(2) },
  ]

  return (
    <Dropdown 
      className="team-selector"
      options={ options } 
      onChange={ v => setMyTeamAndUpdateGameState( +v.value ) } 
      value={ '' + myTeam } 
    />
  )
}

export const PlayersList_Basic:React.FC<any> = ({ game, onClickPlayer = null, cursorPointer = null }) => {
  const players = Object.keys( game.data.players || {} )
  const PlayerListItem = ({ playerId }) => {
    const player = game?.data?.players[ playerId ];
    const playerName =player?.username
    const playerAvatar = +( player?.avatar || 0 )
    const playerOnline = game?.data?.presences?.[player.uid]?.online;

    return (
      <div className="playerListItem" style={{cursor: cursorPointer && cursorPointer?.(player) ? 'pointer' : 'default'}}
        onClick={() => { onClickPlayer?.(player) }} >
        <PlayerAvatar size={ 72 } index={ playerAvatar } transparent={!playerOnline} label={game?.data?.host == playerId ? 'Boss' : ''}/>
        <div className="name">{ playerName }</div> 
      </div>
    )
  }

  return (
    <div className="playersList single">
      <div className="playersColumn single">
        { players.map( p => <PlayerListItem playerId={ p } key={ p } /> ) }
        <Spacer size={ 28 } />
      </div>
    </div>
  )
}

export const PlayersList_TeamColumns:React.FC<any> = ({ game, teams=[1,2], onClickPlayer = null, cursorPointer = null }) => {
  const players = Object.keys( game.data.players || {} )

  const teamRed = players.filter( uid => game.data.players[ uid ].team == teams[0] )
  const teamBlue = players.filter( uid => game.data.players[ uid ].team == teams[1] )

  const PlayerListItem = ({ playerId }) => {
    const player = game?.data?.players[ playerId ];
    const playerName = player?.username;
    const playerAvatar = player?.avatar;
    const playerOnline = game?.data?.presences?.[player.uid]?.online;

    return (
      <div className="playerListItem" key={ playerId } 
        style={{cursor: cursorPointer && cursorPointer?.(player) ? 'pointer' : 'default'}}
        onClick={() => { onClickPlayer?.(player) }} >
        <PlayerAvatar size={ 72 } index={ playerAvatar } transparent={!playerOnline} label={game?.data?.host == playerId ? 'Boss' : ''}/>
        <div className="name">{ playerName || "?" /** ? • ◦ */ }</div> 
      </div>
    )
  }

  return (
    <div className="playersList double">
      <div className="playersColumn double">
        { teamRed.map( p => <PlayerListItem key={ p } playerId={ p } /> ) }
        <Spacer size={ 28 } />
      </div>
      <div className="separator" />
      <div className="playersColumn double">
        { teamBlue.map( p => <PlayerListItem key={ p } playerId={ p }/> ) }
        <Spacer size={ 28 } />
      </div>
    </div>
  )
}

export const UpsellBit:React.FC<{ game:GameStateController<any> }> = ({ game }) => {

  if ( ! game?.data ) {
    return null;
  }

  const [ upsell, setUpsell ] = React.useState( true )
  const myUserData = React.useContext<any>( UserDataContext );
  // const myUserData = game.data.players[ game.myUserId! ];

  const players = Object.keys( game.data.players || {} )

  async function CheckUpsell() {
    const isVip = await IsUserAccountType(myUserData, 'vip');
    const isBundle = await IsUserAccountType(myUserData, "bundle");
    setUpsell(players.length >= 4 && !isVip && !isBundle);
  }

  React.useEffect(() => {
    CheckUpsell();
  }, [players.length]);

 
  const history = ReactRouter.useHistory();
  const gotoGameNight = () => history.push( '/gamenight' );

  const openChoosePlay = () => {
    gotoGameNight()
    // DirtyModalService.openChooseHowToPlayModal();
  }

  return (
    upsell  
    ?
    <div className="upsell" onClick={ () => openChoosePlay() }>
      Your room is full.  Want more people to join?  Upgrade to a Game Night Bundle.
    </div>
    :
    null
  )
}
